var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';$CLJS.$J=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.aK=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.bK=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var cK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Js,$CLJS.Cs],null)),dK=null,eK=0,fK=0;;)if(fK<eK){var Vga=dK.X(null,fK);$CLJS.LF(Vga,$CLJS.H([$CLJS.vt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)],null)],null)]));fK+=1}else{var gK=$CLJS.y(cK);if(gK){var hK=gK;if($CLJS.Ad(hK)){var iK=$CLJS.lc(hK),Wga=$CLJS.mc(hK),
Xga=iK,Yga=$CLJS.D(iK);cK=Wga;dK=Xga;eK=Yga}else{var Zga=$CLJS.A(hK);$CLJS.LF(Zga,$CLJS.H([$CLJS.vt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)],null)],null)]));cK=$CLJS.B(hK);dK=null;eK=0}fK=0}else break}$CLJS.JF($CLJS.Es,$CLJS.H([$CLJS.vt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)]));
for(var jK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.LG],null)),kK=null,lK=0,mK=0;;)if(mK<lK){var $ga=kK.X(null,mK);$CLJS.LF($ga,$CLJS.H([$CLJS.vt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)],null)],null)]));mK+=1}else{var nK=$CLJS.y(jK);if(nK){var oK=nK;if($CLJS.Ad(oK)){var pK=$CLJS.lc(oK),aha=$CLJS.mc(oK),
bha=pK,cha=$CLJS.D(pK);jK=aha;kK=bha;lK=cha}else{var dha=$CLJS.A(oK);$CLJS.LF(dha,$CLJS.H([$CLJS.vt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)],null)],null)]));jK=$CLJS.B(oK);kK=null;lK=0}mK=0}else break}
for(var qK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.bs,$CLJS.ds,$CLJS.Yr,$CLJS.$r],null)),rK=null,sK=0,tK=0;;)if(tK<sK){var eha=rK.X(null,tK);$CLJS.JF(eha,$CLJS.H([$CLJS.vt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)]));tK+=1}else{var uK=$CLJS.y(qK);if(uK){var vK=uK;if($CLJS.Ad(vK)){var wK=$CLJS.lc(vK),fha=$CLJS.mc(vK),gha=wK,hha=$CLJS.D(wK);qK=fha;rK=gha;sK=hha}else{var iha=$CLJS.A(vK);$CLJS.JF(iha,$CLJS.H([$CLJS.vt,$CLJS.Lj,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)]));qK=$CLJS.B(vK);rK=null;sK=0}tK=0}else break}$CLJS.JF($CLJS.XF,$CLJS.H([$CLJS.vt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)]));
$CLJS.JF($CLJS.cG,$CLJS.H([$CLJS.vt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)]));
for(var xK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WF,$CLJS.gG],null)),yK=null,zK=0,AK=0;;)if(AK<zK){var jha=yK.X(null,AK);$CLJS.JF(jha,$CLJS.H([$CLJS.vt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)]));AK+=1}else{var BK=$CLJS.y(xK);if(BK){var CK=BK;if($CLJS.Ad(CK)){var DK=$CLJS.lc(CK),kha=$CLJS.mc(CK),lha=DK,mha=$CLJS.D(DK);xK=kha;yK=lha;zK=mha}else{var nha=$CLJS.A(CK);$CLJS.JF(nha,$CLJS.H([$CLJS.vt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)]));xK=$CLJS.B(CK);
yK=null;zK=0}AK=0}else break}
for(var EK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,$CLJS.eG],null)),FK=null,GK=0,HK=0;;)if(HK<GK){var oha=FK.X(null,HK);$CLJS.JF(oha,$CLJS.H([$CLJS.vt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)]));HK+=1}else{var IK=$CLJS.y(EK);if(IK){var JK=IK;if($CLJS.Ad(JK)){var KK=$CLJS.lc(JK),pha=$CLJS.mc(JK),qha=KK,rha=$CLJS.D(KK);EK=pha;FK=qha;GK=rha}else{var sha=$CLJS.A(JK);$CLJS.JF(sha,$CLJS.H([$CLJS.vt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)]));EK=$CLJS.B(JK);
FK=null;GK=0}HK=0}else break}
for(var LK=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CG,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.Ss],null)],null),MK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lG,$CLJS.FG,$CLJS.tG,$CLJS.mG],null)),NK=null,OK=0,PK=0;;)if(PK<OK){var QK=NK.X(null,PK);$CLJS.UG.v(QK,$CLJS.vt,$CLJS.Lj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,QK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bt,$CLJS.JE,LK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.sF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sF],null)],null));PK+=1}else{var RK=$CLJS.y(MK);if(RK){var SK=RK;if($CLJS.Ad(SK)){var TK=$CLJS.lc(SK),tha=$CLJS.mc(SK),uha=TK,vha=$CLJS.D(TK);MK=tha;NK=uha;OK=vha}else{var UK=$CLJS.A(SK);$CLJS.UG.v(UK,$CLJS.vt,$CLJS.Lj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,UK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bt,$CLJS.JE,LK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sF],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.sF],null)],null));MK=$CLJS.B(SK);NK=null;OK=0}PK=0}else break}
$CLJS.UG.v($CLJS.GG,$CLJS.vt,$CLJS.Lj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.GG],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bt,$CLJS.JE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.Ss],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,$CLJS.dw,$CLJS.fy,$CLJS.bG],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rE],null)],null));$CLJS.UG.v($CLJS.TF,$CLJS.vt,$CLJS.Lj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.TF],null),$CLJS.JE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.wE,$CLJS.EE],null)],null));
$CLJS.X($CLJS.$J,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.aK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.zs,$CLJS.es,$CLJS.LG,$CLJS.cG,$CLJS.XF,$CLJS.bs,$CLJS.Yr,$CLJS.ds,$CLJS.$r,$CLJS.WF,$CLJS.gG,$CLJS.YF,$CLJS.eG,$CLJS.tG,$CLJS.mG,$CLJS.lG,$CLJS.FG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bK,$CLJS.Xi],null)],null));