var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var fX,Zka,hX,jX,kX,$ka,mX,nX,eX,oX,ala,bla,cla,dla,rX,sX,uX,vX,wX,xX,yX,zX,gX,fla,gla,CX,hla,DX,ila,FX,jla,kla,lla,mla,nla,bX;$CLJS.cX=function(a,b){if("string"===typeof b)return bX(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.dX=function(a,b,c){var d=$CLJS.fm(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Fb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
fX=function(a){var b=eX;return $CLJS.QA(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};Zka=function(a){var b=gX;return $CLJS.QA(function(c){return function(d,e,f){f=b.h?b.h(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};hX=function(a){if($CLJS.md(a))return a;throw $CLJS.ii("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Hi,a],null));};
$CLJS.iX=function(a,b,c){return $CLJS.xd(c)?$CLJS.eg.g(c,function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,V=G;G=$CLJS.be.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[V,G],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var z=$CLJS.be.g(b,v),C=x;return a.g?a.g(z,C):a.call(null,z,C)}()],null),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):$CLJS.wd(c)?$CLJS.Rk.g($CLJS.Ve(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.be.g(b,$CLJS.A(c)):b),c):c};jX=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.ie(a):b;return $CLJS.n(b)?[$CLJS.ie(a),"/",$CLJS.gh(a)].join(""):$CLJS.gh(a)};
kX=function(a,b){var c=$CLJS.wd(b)&&!$CLJS.zf(b)&&$CLJS.A(b)instanceof $CLJS.M;return c?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c};
$ka=function(a,b){return $CLJS.jf(function(c){var d=$CLJS.lf;a:try{if($CLJS.zd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.Wk.j(c,0,1);if($CLJS.zd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Ve($CLJS.E,a)(f))){var k=$CLJS.Wk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.A($CLJS.y(d($CLJS.Wa,l)))},$CLJS.H([b]))};
mX=function(a,b){for(;;)switch(b=$CLJS.il.h($CLJS.lf($CLJS.Wa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.A(b),lX.h?lX.h(a):lX.call(null,a);default:if($CLJS.n($CLJS.Re($CLJS.Ve(kX,a),b))){var c=a;b=$ka(a,b);a=c}else{c=$CLJS.hf.g(lX,b);if($CLJS.E.g(c,b))return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};nX=function(a){var b=$CLJS.wd(a);return b?(b=!$CLJS.zf(a))?$CLJS.KA($CLJS.ge,$CLJS.Za)($CLJS.A(a)):b:b};
eX=function(a){return $CLJS.n($CLJS.KA($CLJS.ge,$CLJS.Za)(a))?$CLJS.zh.h($CLJS.OA(jX(a).toLowerCase(),/_/,"-")):a};oX=function(a,b){var c=nX(b);return $CLJS.n(c)?(b=eX($CLJS.A(b)),$CLJS.ud(a)?(a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};ala=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.di,$CLJS.Td,$CLJS.mj,eX,$CLJS.$N,eX],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ee([a,b]);b=pX.g?pX.g(b,qX):pX.call(null,b,qX);return d.call(c,b,a)})};
bla=function(a){a=$CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=eX(c);var d=ala(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.mj.h(a),$CLJS.OF)&&$CLJS.Va($CLJS.$N.h(a))?$CLJS.R.j(a,$CLJS.$N,$CLJS.NG):a};cla=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=jX(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(bla(b),$CLJS.T,c)],null)}),a)};
dla=function(a){a=pX.h?pX.h(a):pX.call(null,a);return gX.h?gX.h(a):gX.call(null,a)};rX=function(a){return $CLJS.dX($CLJS.dX($CLJS.fb(function(b,c){return $CLJS.dX(b,c,$CLJS.zh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.qD,$CLJS.Aea,$CLJS.NO,ela,$CLJS.kA,$CLJS.si],null)),$CLJS.qC,dla),$CLJS.bO,$CLJS.FV)};sX=function(a){return $CLJS.Fd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,a,null],null):a};uX=function(a){return tX.h(sX(a))};
vX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return lX($CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.g(tX,b)))};wX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,uX(b)],null),$CLJS.hf.g(tX,c))};xX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,uX(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
yX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,uX(a)],null)};zX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,tX.h(a)],null)};
gX=function(a){return AX(function(b){if($CLJS.xd(b))return Zka(b);if($CLJS.Va(nX(b)))return b;try{return tX.h(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.UA($CLJS.Ny);if($CLJS.n($CLJS.TA("metabase.mbql.normalize",d))){var e=$CLJS.WE("Invalid clause:");e instanceof Error?$CLJS.SA("metabase.mbql.normalize",d,$CLJS.Lw.l($CLJS.H([b])),e):$CLJS.SA("metabase.mbql.normalize",d,$CLJS.Lw.l($CLJS.H([e,b])),null)}throw $CLJS.hi($CLJS.uE("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.CV(c)])),new $CLJS.h(null,
1,[$CLJS.jS,b],null),c);}throw f;}},a)};fla=function(a){return $CLJS.Df($CLJS.cm(gX,BX(a)))};gla=function(a){for(;;)if($CLJS.n(nX(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Fe($CLJS.Rk.g(sX,a))};CX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.OF);return $CLJS.n(b)?$CLJS.Sk.j(a,$CLJS.OF,tX):a};
hla=function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,CX(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,
2,5,$CLJS.Q,[l,CX(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};DX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.QQ);return $CLJS.n(b)?$CLJS.Sk.j(a,$CLJS.QQ,hla):a};ila=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.tQ);b=$CLJS.Va(a)?EX.h?EX.h(b):EX.call(null,b):b;return $CLJS.n(a)?DX(b):b};FX=function(a){return $CLJS.ud(a)?$CLJS.y(a):null!=a};
jla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.RF);var c=$CLJS.J.g(b,$CLJS.EN),d=$CLJS.J.g(b,$CLJS.aP),e=$CLJS.J.g(b,$CLJS.tQ);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.aP);var l=$CLJS.fM($CLJS.Hk.g(f,$CLJS.aP),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RF,$CLJS.aP],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Sk.j(l,$CLJS.RF,EX):l,t=$CLJS.n(c)?$CLJS.Sk.j(m,$CLJS.EN,$CLJS.Ve($CLJS.Rk,gX)):m,u=$CLJS.n(e)?$CLJS.Sk.j(t,$CLJS.tQ,DX):t;return gX(u)}catch(v){if(v instanceof Error)throw m=v,$CLJS.hi($CLJS.uE("Error canonicalizing query: {0}",
$CLJS.H([$CLJS.CV(m)])),new $CLJS.h(null,1,[$CLJS.RF,a],null),m);throw v;}};
kla=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.RF),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.iG);c=$CLJS.J.g(c,$CLJS.dF);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.eg.j($CLJS.bh,$CLJS.CC,$CLJS.y($CLJS.lf($CLJS.Wa,function k(e,f){try{if($CLJS.zd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.he(l,$CLJS.uG)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,m,$CLJS.Hk.g(t,$CLJS.AG)],null)],null)],null)}throw $CLJS.Y;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.KV(k,e,f);throw l;}throw u;}}($CLJS.Cf,b)))),$CLJS.GX.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RF,$CLJS.dF],null),$CLJS.Pk.g($CLJS.Df,$CLJS.Ve($CLJS.Qk,b)))):a};
lla=function(a){try{return kla(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.hi($CLJS.WE("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.RF,a],null),b);}throw c;}};
mla=function(a,b){var c=$CLJS.eg.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.ne(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.be.g(b,G);C=HX.g?HX.g(C,K):HX.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var z=x,C=$CLJS.be.g(b,v);return HX.g?HX.g(z,C):HX.call(null,z,C)}();if(null!=u)return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};nla=function(a,b){a=$CLJS.Rk.g(function(c){var d=$CLJS.be.g(b,IX);return HX.g?HX.g(c,d):HX.call(null,c,d)},a);return $CLJS.n($CLJS.Re($CLJS.Wa,a))?a:null};
bX=function bX(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Df(d);return $CLJS.ee(f,new $CLJS.ne(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),bX.g?bX.g(a,k):bX.call(null,a,k)):null},null,null))};
$CLJS.GX=function GX(a){switch(arguments.length){case 3:return GX.j(arguments[0],arguments[1],arguments[2]);case 4:return GX.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return GX.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return GX.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return GX.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.GX.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.A(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.GX.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.GX.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.A(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.GX.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.GX.N=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.A(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.GX.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.GX.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.A(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.GX.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.GX.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.A(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Se.l($CLJS.GX,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Se.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.GX.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);var e=$CLJS.B(d);d=$CLJS.A(e);var f=$CLJS.B(e);e=$CLJS.A(f);var k=$CLJS.B(f);f=$CLJS.A(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.GX.A=6;
var AX=function AX(a,b){return $CLJS.DV($CLJS.Ve(AX,a),$CLJS.Td,a.h?a.h(b):a.call(null,b))},lX=function lX(a){for(;;){if($CLJS.xd(a))return $CLJS.Mt(a,lX);if($CLJS.Dd(a))a=$CLJS.Df(a);else if($CLJS.zd(a)){if($CLJS.Va($CLJS.Re($CLJS.Wa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.A(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.T:null;switch(v){case "not":if($CLJS.Ml(m)){var x=$CLJS.A(m),z=x instanceof $CLJS.M?x.T:null;switch(z){case "not":a=$CLJS.hd(m);continue;case "and":return mX($CLJS.Cs,
$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));case "or":return mX($CLJS.Js,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));default:return a}}else return a;case "and":return mX($CLJS.Js,t);case "or":return mX($CLJS.Cs,t);default:return $CLJS.Rk.g(lX,a)}}else return a}},ola=new $CLJS.M(null,"value_field","value_field",
-980977878),JX=new $CLJS.M(null,"ascending","ascending",-988350486),KX=new $CLJS.M(null,"named","named",-422393479),LX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),MX=new $CLJS.M(null,"descending","descending",-24766135),NX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),pla=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),OX=new $CLJS.M(null,"field-literal","field-literal",-1295883554),PX=new $CLJS.M(null,"viz-settings","viz-settings",
256055379),qla=new $CLJS.M(null,"rows","rows",850049680),rla=new $CLJS.M(null,"special-fn","special-fn",1290649344),IX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),qX=new $CLJS.M(null,"ignore-path","ignore-path",944069061),sla=new $CLJS.M(null,"label_field","label_field",-1573182765),tla=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),ula=new $CLJS.M(null,"joined-field","joined-field",-2048778268),ela=new $CLJS.M(null,"visibility_type","visibility_type",
-508434247);var QX,vla=$CLJS.Xe($CLJS.N),wla=$CLJS.Xe($CLJS.N),xla=$CLJS.Xe($CLJS.N),yla=$CLJS.Xe($CLJS.N),zla=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));QX=new $CLJS.ei($CLJS.Fh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Pk.g(eX,$CLJS.A),zla,vla,wla,xla,yla);QX.m(null,$CLJS.jG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jG,b],null);return null!=a?$CLJS.be.g(b,a):b});
QX.m(null,$CLJS.zB,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zB,a instanceof $CLJS.M?jX(a):a],null)});QX.m(null,$CLJS.MV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.be.g(QX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MV,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MV,pX.g?pX.g(b,qX):pX.call(null,b,qX),eX(c)],null)});
QX.m(null,$CLJS.uG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=pX.g?pX.g(a,qX):pX.call(null,a,qX);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,b,function(){var d=$CLJS.n($CLJS.CB.h(c))?$CLJS.Sk.j(c,$CLJS.CB,$CLJS.zh):c;d=$CLJS.n($CLJS.AG.h(c))?$CLJS.Sk.j(d,$CLJS.AG,$CLJS.zh):d;return $CLJS.n($CLJS.ER.h(c))?$CLJS.Sk.j(d,$CLJS.ER,function(e){return $CLJS.n($CLJS.PE.h(e))?$CLJS.Sk.j(e,$CLJS.PE,$CLJS.zh):e}):d}()],null)});
QX.m(null,OX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[OX,b instanceof $CLJS.M?jX(b):b,$CLJS.zh.h(a)],null)});QX.m(null,NX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[NX,pX.g?pX.g(b,qX):pX.call(null,b,qX),$CLJS.tt,eX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[NX,pX.g?pX.g(b,qX):pX.call(null,b,qX),eX(c)],null)});
QX.m(null,$CLJS.GG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.be.g(QX.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.GG,b,c,d],null)),pX.g?pX.g(a,qX):pX.call(null,a,qX)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.GG,pX.g?pX.g(b,qX):pX.call(null,b,qX),$CLJS.Fd(c)?c:eX(c),eX(d)],null)});
QX.m(null,$CLJS.jJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jJ,b,eX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jJ,$CLJS.dw],null)});QX.m(null,$CLJS.hJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hJ,b,eX(a)],null)});
QX.m(null,$CLJS.rJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rJ,pX.g?pX.g(b,qX):pX.call(null,b,qX),c,eX(a)],null)});QX.m(null,$CLJS.xJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xJ,pX.g?pX.g(b,qX):pX.call(null,b,qX),c,eX(a)],null)});
QX.m(null,$CLJS.pJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pJ,pX.g?pX.g(b,qX):pX.call(null,b,qX),eX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pJ,pX.g?pX.g(b,qX):pX.call(null,b,qX)],null)});
QX.m(null,$CLJS.vJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vJ,pX.g?pX.g(b,qX):pX.call(null,b,qX),eX(c),eX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vJ,pX.g?pX.g(b,qX):pX.call(null,b,qX),eX(c)],null)});
QX.m(null,$CLJS.gJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gJ,pX.g?pX.g(b,qX):pX.call(null,b,qX),pX.g?pX.g(c,qX):pX.call(null,c,qX),eX(a)],null)});QX.m(null,$CLJS.Dj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,b,a],null)});
QX.m(null,$CLJS.di,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[eX(a)],null),$CLJS.hf.h(function(c){return pX.g?pX.g(c,qX):pX.call(null,c,qX)}),b)});
var RX=function RX(a){if($CLJS.n($CLJS.KA($CLJS.ge,$CLJS.Za)(a))){var c=eX(a);var d=new $CLJS.ah(null,new $CLJS.h(null,18,[$CLJS.Zn,null,$CLJS.hs,null,$CLJS.JG,null,$CLJS.DG,null,$CLJS.vt,null,$CLJS.vG,null,$CLJS.OG,null,$CLJS.PG,null,$CLJS.$F,null,$CLJS.fG,null,$CLJS.gs,null,$CLJS.aG,null,$CLJS.IG,null,$CLJS.BG,null,$CLJS.jk,null,$CLJS.by,null,$CLJS.oG,null,$CLJS.HG,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(nX(a))?(a=$CLJS.A(a),RX.h?RX.h(a):RX.call(null,
a)):null},Ala=new $CLJS.h(null,8,[$CLJS.mj,eX,$CLJS.tQ,function(a){a=fX(a);return $CLJS.y($CLJS.QQ.h(a))?$CLJS.Sk.j(a,$CLJS.QQ,cla):a},$CLJS.RF,new $CLJS.h(null,6,[$CLJS.jG,function SX(a){if($CLJS.n($CLJS.KA($CLJS.ge,$CLJS.Za)(a)))return eX(a);if($CLJS.n(oX(KX,a))){a=$CLJS.y(a);$CLJS.A(a);var c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[KX,SX.h?SX.h(a):SX.call(null,a)],null),c)}return $CLJS.n($CLJS.n(nX(a))?RX($CLJS.hd(a)):null)?$CLJS.Rk.g(SX,a):pX.g?pX.g(a,
qX):pX.call(null,a,qX)},$CLJS.jQ,function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[jX(u),pX.g?pX.g(t,qX):pX.call(null,t,qX)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[jX(l),pX.g?pX.g(f,qX):pX.call(null,f,qX)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.ZR,function(a){return $CLJS.Df(function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(nX(t))?QX.h(t):$CLJS.ce(QX.h($CLJS.ce(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee($CLJS.n(nX(l))?QX.h(l):$CLJS.ce(QX.h($CLJS.ce(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.SQ,function(a){a=fX(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.tQ);if($CLJS.n(b))return a=$CLJS.JV(a,new $CLJS.h(null,1,[$CLJS.tQ,$CLJS.RF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.tQ],null),a=pX.g?pX.g(a,b):pX.call(null,a,b),$CLJS.JV(a,new $CLJS.h(null,1,[$CLJS.RF,$CLJS.tQ],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.RF],null);return pX.g?pX.g(a,b):pX.call(null,a,b)},$CLJS.aP,new $CLJS.h(null,1,[IX,rX],null),$CLJS.NN,new $CLJS.h(null,1,[IX,function(a){a=pX.g?pX.g(a,$CLJS.RF):pX.call(null,a,$CLJS.RF);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.PE),d=$CLJS.J.g(b,$CLJS.dF);a=$CLJS.J.g(b,$CLJS.hF);b=$CLJS.n(c)?$CLJS.Sk.j(b,$CLJS.PE,eX):b;d=$CLJS.n($CLJS.KA($CLJS.ge,$CLJS.Za)(d))?$CLJS.Sk.j(b,$CLJS.dF,eX):b;return $CLJS.n(a)?$CLJS.Sk.j(d,$CLJS.hF,jX):d}],null)],null),$CLJS.Ry,
new $CLJS.h(null,1,[$CLJS.yO,$CLJS.Td],null),$CLJS.EN,new $CLJS.h(null,1,[IX,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.mj),d=$CLJS.J.g(b,$CLJS.CJ),e=$CLJS.J.g(b,$CLJS.Zi);a=$CLJS.J.g(b,LX);b=$CLJS.n(e)?$CLJS.Sk.j(b,$CLJS.Zi,jX):b;c=$CLJS.n(c)?$CLJS.Sk.j(b,$CLJS.mj,eX):b;d=$CLJS.n(d)?$CLJS.Sk.j(c,$CLJS.CJ,function(f){return pX.g?pX.g(f,qX):pX.call(null,f,qX)}):c;d=$CLJS.n(a)?$CLJS.GX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[LX,sla],null),function(f){return pX.g?pX.g(f,qX):pX.call(null,f,qX)}):d;return $CLJS.n(a)?
$CLJS.GX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[LX,ola],null),function(f){return pX.g?pX.g(f,qX):pX.call(null,f,qX)}):d}],null),$CLJS.zN,function(a){return null==a?null:eX(a)},$CLJS.aP,new $CLJS.h(null,1,[IX,rX],null),PX,eX],null),pX=function pX(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return pX.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
pX.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Df(b);b=$CLJS.y(c)?$CLJS.LA(Ala,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?$CLJS.eg.g($CLJS.N,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);z=eX(z);z=new $CLJS.P(null,
2,5,$CLJS.Q,[z,pX.l(x,$CLJS.H([$CLJS.be.g($CLJS.Df(c),z)]))],null);u.add(z);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=eX(u);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,pX.l(m,$CLJS.H([$CLJS.be.g($CLJS.Df(c),u)]))],null),k($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(nX(a))?QX.h(a):$CLJS.wd(a)?$CLJS.Rk.g(function(e){return pX.l(e,$CLJS.H([$CLJS.be.g($CLJS.Df(c),IX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.hi($CLJS.uE("Error normalizing form: {0}",$CLJS.H([$CLJS.CV(d)])),new $CLJS.h(null,3,[$CLJS.iA,a,$CLJS.pl,c,rla,b],null),d);}throw e;}};pX.A=1;pX.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var tX=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(nX(f))?$CLJS.A(f):null},e,a,b,c,d)}();tX.m(null,$CLJS.di,function(a){return a});
tX.m(null,$CLJS.uG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(oX($CLJS.uG,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return tX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,c,$CLJS.Fe($CLJS.gl.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,b,$CLJS.Fe(a)],null)});
tX.m(null,$CLJS.jG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.td(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jG,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,b,a],null)});tX.m(null,$CLJS.fL,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(nX(a))?tX.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,a,null],null)});
tX.m(null,OX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,b,new $CLJS.h(null,1,[$CLJS.CB,a],null)],null)});tX.m(null,tla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=uX(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=uX(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,a,$CLJS.R.j(c,$CLJS.ON,b)],null)});
tX.m(null,ula,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=uX(a);return $CLJS.Se.v($CLJS.RV,a,$CLJS.R,$CLJS.H([$CLJS.DP,b]))});
tX.m(null,NX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=uX(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.CB);$CLJS.Va(d)||$CLJS.VS.g(d,b)?a=$CLJS.Se.v($CLJS.RV,a,$CLJS.R,$CLJS.H([$CLJS.AG,b])):(c=$CLJS.UA($CLJS.Py),$CLJS.n($CLJS.TA("metabase.mbql.util",c))&&(b=$CLJS.uE("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.Mh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.SA("metabase.mbql.util",c,$CLJS.Lw(),b):$CLJS.SA("metabase.mbql.util",c,$CLJS.Lw.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),tX.h(new $CLJS.P(null,3,5,$CLJS.Q,[NX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
tX.m(null,$CLJS.MV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=uX(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,b,$CLJS.R.j(e,$CLJS.ER,$CLJS.gl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.PE,c],null),$CLJS.n(d)?$CLJS.Ee([c,d]):null,a])))],null)});
for(var TX=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,$CLJS.Cs,$CLJS.Es],null)),UX=null,VX=0,WX=0;;)if(WX<VX){var XX=UX.X(null,WX);tX.m(null,XX,function(){return function(a){return vX(a)}}(TX,UX,VX,WX,XX));WX+=1}else{var YX=$CLJS.y(TX);if(YX){var ZX=YX;if($CLJS.Ad(ZX)){var $X=$CLJS.lc(ZX),Bla=$CLJS.mc(ZX),Cla=$X,Dla=$CLJS.D($X);TX=Bla;UX=Cla;VX=Dla}else{var aY=$CLJS.A(ZX);tX.m(null,aY,function(){return function(a){return vX(a)}}(TX,UX,VX,WX,aY,ZX,YX));TX=$CLJS.B(ZX);UX=null;VX=0}WX=0}else break}
tX.m(null,$CLJS.cG,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,uX(a),uX(b)],null),c)});tX.m(null,$CLJS.GG,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=uX(a);a=$CLJS.n(kX($CLJS.uG,a))?$CLJS.RV.l(c,$CLJS.Hk,$CLJS.H([$CLJS.AG])):c;return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GG,a],null),b)});
for(var bY=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.lG,$CLJS.FG,$CLJS.tG,$CLJS.mG,$CLJS.es,$CLJS.LG,$CLJS.bs,$CLJS.ds,$CLJS.Yr,$CLJS.$r,$CLJS.YF,$CLJS.eG,$CLJS.WF,$CLJS.gG,$CLJS.XF],null)),cY=null,dY=0,eY=0;;)if(eY<dY){var fY=cY.X(null,eY);tX.m(null,fY,function(){return function(a){return wX(a)}}(bY,cY,dY,eY,fY));eY+=1}else{var gY=$CLJS.y(bY);if(gY){var hY=gY;if($CLJS.Ad(hY)){var iY=$CLJS.lc(hY),Ela=$CLJS.mc(hY),Fla=iY,Gla=$CLJS.D(iY);bY=Ela;cY=Fla;dY=Gla}else{var jY=$CLJS.A(hY);tX.m(null,jY,
function(){return function(a){return wX(a)}}(bY,cY,dY,eY,jY,hY,gY));bY=$CLJS.B(hY);cY=null;dY=0}eY=0}else break}tX.m(null,qla,function(){return null});tX.m(null,$CLJS.EM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EM,tX.h(b),a],null)});
tX.m(null,KX,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=tX.h;var e=$CLJS.Q;b=tX.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,pla)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.zE,a],null);return c.call(tX,new $CLJS.P(null,3,5,e,[$CLJS.EM,b,a],null))});
for(var kY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.by,$CLJS.vG],null)),lY=null,mY=0,nY=0;;)if(nY<mY){var oY=lY.X(null,nY);tX.m(null,oY,function(){return function(a){return xX(a)}}(kY,lY,mY,nY,oY));nY+=1}else{var pY=$CLJS.y(kY);if(pY){var qY=pY;if($CLJS.Ad(qY)){var rY=$CLJS.lc(qY),Hla=$CLJS.mc(qY),Ila=rY,Jla=$CLJS.D(rY);kY=Hla;lY=Ila;mY=Jla}else{var sY=$CLJS.A(qY);tX.m(null,sY,function(){return function(a){return xX(a)}}(kY,lY,mY,nY,sY,qY,pY));kY=$CLJS.B(qY);lY=null;mY=0}nY=0}else break}
for(var tY=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.oG,$CLJS.QG,$CLJS.$F,$CLJS.JG,$CLJS.HG,$CLJS.Zn,$CLJS.jk,$CLJS.IG,$CLJS.aG],null)),uY=null,vY=0,wY=0;;)if(wY<vY){var xY=uY.X(null,wY);tX.m(null,xY,function(){return function(a){return yX(a)}}(tY,uY,vY,wY,xY));wY+=1}else{var yY=$CLJS.y(tY);if(yY){var zY=yY;if($CLJS.Ad(zY)){var AY=$CLJS.lc(zY),Kla=$CLJS.mc(zY),Lla=AY,Mla=$CLJS.D(AY);tY=Kla;uY=Lla;vY=Mla}else{var BY=$CLJS.A(zY);tX.m(null,BY,function(){return function(a){return yX(a)}}(tY,uY,vY,wY,
BY,zY,yY));tY=$CLJS.B(zY);uY=null;vY=0}wY=0}else break}tX.m(null,$CLJS.fG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fG,uX(b),a],null)});
for(var CY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BG,$CLJS.DG],null)),DY=null,EY=0,FY=0;;)if(FY<EY){var GY=DY.X(null,FY);tX.m(null,GY,function(){return function(a){return zX(a)}}(CY,DY,EY,FY,GY));FY+=1}else{var HY=$CLJS.y(CY);if(HY){var IY=HY;if($CLJS.Ad(IY)){var JY=$CLJS.lc(IY),Nla=$CLJS.mc(IY),Ola=JY,Pla=$CLJS.D(JY);CY=Nla;DY=Ola;EY=Pla}else{var KY=$CLJS.A(IY);tX.m(null,KY,function(){return function(a){return zX(a)}}(CY,DY,EY,FY,KY,IY,HY));CY=$CLJS.B(IY);DY=null;EY=0}FY=0}else break}
tX.m(null,$CLJS.OG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OG,tX.h(b),tX.h(a)],null)});
tX.m(null,$CLJS.dG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.be.g(tX.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dG,b],null)),pX.l(a,$CLJS.H([qX]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dG,$CLJS.Df(function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[tX.h(v),tX.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[tX.h(m),tX.h(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
tX.m(null,$CLJS.NF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NF,tX.h(a),$CLJS.E.g(0,b)?1:tX.h(b)],null),$CLJS.hf.g(tX,c))});
var BX=function BX(a){return function f(d,e){try{if($CLJS.n(function(){var x=hX($CLJS.Dd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var z=hX($CLJS.ge);return z.h?z.h(e):z.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(z){if(z instanceof Error)if(d=z,d===$CLJS.Y)try{if($CLJS.zd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.Wk.j(e,
0,2);if($CLJS.zd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.sE($CLJS.ge,$CLJS.Te(new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.hs,null,$CLJS.vt,null,$CLJS.PG,null,$CLJS.gs,null,KX,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(RX(m)))return $CLJS.eg.j($CLJS.Cf,$CLJS.tE(BX),a);throw $CLJS.Y;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;
}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Y)try{if($CLJS.zd(e)&&1<=$CLJS.D(e))try{var u=$CLJS.Wk.j(e,0,1);if($CLJS.zd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=
G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw z;}else throw d;else throw x;}}($CLJS.Cf,a)},LY=function LY(a){return function f(d,e){try{var k=hX($CLJS.Dd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Y)try{if($CLJS.zd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.he(m,
$CLJS.rG)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rG,t],null))}throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,$CLJS.kG))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kG,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,JX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rG,t],null));throw $CLJS.Y;}catch(S){if(S instanceof Error)if(l=
S,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,MX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kG,t],null));throw $CLJS.Y;}catch(V){if(V instanceof Error)if(m=V,m===$CLJS.Y)try{var v=$CLJS.F(e,0);if($CLJS.he(v,JX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rG,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,MX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kG,t],null));throw $CLJS.Y;}catch(ha){if(ha instanceof
Error)if(m=ha,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.rG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rG,uX(t)],null);throw $CLJS.Y;}catch(ra){if(ra instanceof Error)if(m=ra,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.kG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kG,uX(t)],null);throw $CLJS.Y;}catch(Ma){if(Ma instanceof Error){t=Ma;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw Ma;}else throw m;else throw ra;}else throw m;else throw ha;}else throw m;else throw Z;
}else throw m;else throw V;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{if($CLJS.zd(e)&&0<=$CLJS.D(e))try{var x=$CLJS.Wk.j(e,0,0);if($CLJS.zd(x)&&0===$CLJS.D(x))try{var z=$CLJS.Wk.g(e,0);if($CLJS.E.g(z,a))return $CLJS.Df($CLJS.il.h($CLJS.hf.g(LY,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof
Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.iX(f,d,e);throw u;}throw K;}else throw l;else throw G;}else throw l;else throw C;}}($CLJS.Cf,a)},EX=$CLJS.Pk.g(gX,function(a){var b=FX($CLJS.jG.h(a))?$CLJS.Sk.j(a,$CLJS.jG,fla):a;b=FX($CLJS.iG.h(a))?$CLJS.Sk.j(b,$CLJS.iG,gla):b;b=FX($CLJS.dF.h(a))?$CLJS.Sk.j(b,$CLJS.dF,$CLJS.Ve($CLJS.Rk,sX)):b;b=FX($CLJS.ZR.h(a))?$CLJS.Sk.j(b,$CLJS.ZR,LY):b;return FX($CLJS.SQ.h(a))?
$CLJS.Sk.j(b,$CLJS.SQ,ila):b}),Qla=new $CLJS.h(null,3,[$CLJS.tQ,$CLJS.Td,$CLJS.RF,new $CLJS.h(null,2,[$CLJS.SQ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.tQ);if($CLJS.n(b))return a=$CLJS.JV(a,new $CLJS.h(null,1,[$CLJS.tQ,$CLJS.RF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.tQ],null),a=HX.g?HX.g(a,b):HX.call(null,a,b),$CLJS.JV(a,new $CLJS.h(null,1,[$CLJS.RF,$CLJS.tQ],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.RF],null);return HX.g?HX.g(a,b):HX.call(null,a,b)},$CLJS.NN,new $CLJS.h(null,1,
[IX,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.RF],null);return HX.g?HX.g(a,b):HX.call(null,a,b)}],null)],null),PX,$CLJS.Td],null),HX=function HX(a){switch(arguments.length){case 1:return HX.h(arguments[0]);case 2:return HX.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};HX.h=function(a){return HX.g(a,$CLJS.Cf)};
HX.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.LA(Qla,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?mla(a,b):$CLJS.wd(a)?nla(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.hi("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.iA,a,$CLJS.pl,b],null),d);throw d;}};HX.A=2;
$CLJS.MY=function(){var a=$CLJS.Pk.l(HX,lla,jla,$CLJS.H([pX]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.hi($CLJS.uE("Error normalizing query: {0}",$CLJS.H([$CLJS.CV(c)])),new $CLJS.h(null,1,[$CLJS.RF,b],null),c);}throw d;}}}();$CLJS.NY=function NY(a,b){if($CLJS.Va($CLJS.y(a)))a=(0,$CLJS.MY)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.Kt(a);b=$CLJS.Ee([$CLJS.id(a),b]);f=NY.g?NY.g(f,b):NY.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};