var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.schema.common.js");require("./metabase.mbql.predicates.js");require("./metabase.mbql.schema.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var HV;$CLJS.CV=function(a){return a instanceof Error?a.message:null};
$CLJS.DV=function(a,b,c){if($CLJS.$d(c)){var d=$CLJS.Se.g($CLJS.U,$CLJS.hf.g(a,c));return b.h?b.h(d):b.call(null,d)}return $CLJS.zf(c)?(d=new $CLJS.Yf(function(){var e=$CLJS.Eb(c);return a.h?a.h(e):a.call(null,e)}(),function(){var e=$CLJS.Fb(c);return a.h?a.h(e):a.call(null,e)}()),b.h?b.h(d):b.call(null,d)):$CLJS.Dd(c)?(d=$CLJS.mh($CLJS.hf.g(a,c)),b.h?b.h(d):b.call(null,d)):$CLJS.yd(c)?(d=$CLJS.fb(function(e,f){return $CLJS.be.g(e,a.h?a.h(f):a.call(null,f))},c,c),b.h?b.h(d):b.call(null,d)):$CLJS.ud(c)?
(d=$CLJS.eg.g($CLJS.jd(c),$CLJS.hf.g(a,c)),b.h?b.h(d):b.call(null,d)):b.h?b.h(c):b.call(null,c)};$CLJS.FV=function(a){function b(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return"string"===typeof d?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zh.h(d),c],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null)}return $CLJS.EV(function(c){return $CLJS.xd(c)?$CLJS.eg.g($CLJS.N,$CLJS.hf.g(b,c)):c},a)};
$CLJS.GV=function(a){var b=new $CLJS.Ea;for(a=$CLJS.y(a);;)if(null!=a)b=b.append($CLJS.p.h($CLJS.A(a))),a=$CLJS.B(a);else return b.toString()};$CLJS.IV=function(a,b,c){a=$CLJS.OA(a,/''/,"'");var d=$CLJS.bu(a,HV);d=$CLJS.E.g($CLJS.D(d),1)?new $CLJS.P(null,2,5,$CLJS.Q,[a,""],null):d;a=$CLJS.oh(/.*\{0\}.*/,a);return $CLJS.Ht.ngettext($CLJS.Ht.msgid($CLJS.jl(d),$CLJS.n(a)?c:""),$CLJS.OA($CLJS.OA(b,/''/,"'"),HV,$CLJS.p.h(c)),c)};
$CLJS.JV=function(a,b){return $CLJS.fb(function(c,d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.Hd(a,e)?$CLJS.R.j(c,d,$CLJS.J.g(a,e)):c},$CLJS.Se.j($CLJS.Hk,a,$CLJS.Xg(b)),b)};$CLJS.KV=function(a,b,c){return $CLJS.xd(c)?$CLJS.jf(function(d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);e=$CLJS.be.g(b,e);return a.g?a.g(e,d):a.call(null,e,d)},$CLJS.H([c])):$CLJS.wd(c)?$CLJS.jf($CLJS.Ve(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.be.g(b,$CLJS.A(c)):b),$CLJS.H([c])):null};
$CLJS.EV=function EV(a,b){return $CLJS.DV($CLJS.Ve(EV,a),a,b)};HV=/\{0\}/;$CLJS.LV=new $CLJS.M(null,"unique-alias-fn","unique-alias-fn",32555420);$CLJS.MV=new $CLJS.M(null,"binning-strategy","binning-strategy",2063329158);$CLJS.NV=new $CLJS.M("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);$CLJS.OV=new $CLJS.M(null,"name-key-fn","name-key-fn",-1634839199);var zka=$CLJS.Ig([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pJ,$CLJS.iJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tJ,null],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.pJ,$CLJS.$I],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pJ,$CLJS.nJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bJ,null],null)],[$CLJS.Ai,$CLJS.kH,$CLJS.AJ,$CLJS.fJ,$CLJS.wi,$CLJS.Gj,$CLJS.ij,$CLJS.mk,$CLJS.Gi,$CLJS.fJ,$CLJS.BJ,$CLJS.hH]),QV;$CLJS.fh($CLJS.hf.g($CLJS.A,$CLJS.Xg(zka)));var PV,Aka=$CLJS.Xe($CLJS.N),Bka=$CLJS.Xe($CLJS.N),Cka=$CLJS.Xe($CLJS.N),Dka=$CLJS.Xe($CLJS.N),Eka=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
PV=new $CLJS.ei($CLJS.Fh.g("metabase.mbql.util","negate*"),$CLJS.A,Eka,Aka,Bka,Cka,Dka);PV.m(null,$CLJS.Es,function(a){$CLJS.I(a,0,null);return $CLJS.I(a,1,null)});PV.m(null,$CLJS.Js,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Cs],null),$CLJS.hf.g(PV,a))});PV.m(null,$CLJS.Cs,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Js],null),$CLJS.hf.g(PV,a))});
PV.m(null,$CLJS.es,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LG,b,a],null)});PV.m(null,$CLJS.LG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.es,b,a],null)});PV.m(null,$CLJS.Yr,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,b,a],null)});
PV.m(null,$CLJS.bs,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$r,b,a],null)});PV.m(null,$CLJS.$r,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bs,b,a],null)});PV.m(null,$CLJS.ds,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yr,b,a],null)});
PV.m(null,$CLJS.XF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bs,b,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yr,b,a],null)],null)});PV.m(null,$CLJS.tG,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,a],null)});PV.m(null,$CLJS.lG,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,a],null)});
PV.m(null,$CLJS.FG,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,a],null)});
QV=function QV(a){return $CLJS.xd(a)?$CLJS.Fe($CLJS.eg.g($CLJS.N,function(){return function e(d){return new $CLJS.ne(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=QV.h?QV.h(u):QV.call(null,u);null!=u&&m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null));t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);
m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=QV.h?QV.h(k):QV.call(null,k);if(null!=k)return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,k],null),e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(a)}())):$CLJS.wd(a)?$CLJS.Fe($CLJS.eg.g($CLJS.jd(a),$CLJS.lf($CLJS.Wa,$CLJS.hf.g(QV,a)))):a};
$CLJS.RV=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.w(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){var f=$CLJS.I(c,0,null),k=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);d=$CLJS.Fe(QV($CLJS.Se.j(d,c,e)));return $CLJS.n(d)?new $CLJS.P(null,3,5,$CLJS.Q,[f,k,d],null):$CLJS.E.g(f,$CLJS.uG)?new $CLJS.P(null,3,5,$CLJS.Q,[f,k,null],null):new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}a.A=2;a.B=function(c){var d=
$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.Lc(c);return b(d,e,c)};a.l=b;return a}();