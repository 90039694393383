var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var XE,bF,Tea,Uea,Vea,Wea,Xea,Yea;
$CLJS.VE=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.rA([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.WE=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.uE(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)};XE=function(a){return $CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M};
$CLJS.ZE=function(a){var b=function(){var c=XE(a);return c?(c=$CLJS.xd($CLJS.hd(a)))?(c=$CLJS.Fi.h($CLJS.hd(a)),$CLJS.n(c)?c:$CLJS.CB.h($CLJS.hd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.YE.h(a)};
bF=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,2,[$CLJS.Ct,"valid MBQL clause",$CLJS.Et,function(c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.Dj);return["invalid MBQL clause: ",$CLJS.Mh.l($CLJS.H([c]))].join("")}],null),$CLJS.Te(XE)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,b],null),function(c){c=$CLJS.ZE(c);
return aF(c,a)}],null)],null)};$CLJS.cF=new $CLJS.M(null,"right-join","right-join",-56349359);Tea=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);Uea=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);Vea=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.dF=new $CLJS.M(null,"fields","fields",-1932066230);
$CLJS.eF=new $CLJS.M(null,"expr","expr",745722291);$CLJS.fF=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.gF=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.hF=new $CLJS.M(null,"alias","alias",-2039751630);$CLJS.iF=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.jF=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.kF=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.lF=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);Wea=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);$CLJS.mF=new $CLJS.M(null,"left-join","left-join",-672831855);
$CLJS.nF=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.oF=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.pF=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);Xea=new $CLJS.r("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);
Yea=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.qF=new $CLJS.M(null,"inner-join","inner-join",659431740);$CLJS.rF=new $CLJS.M("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);$CLJS.$E=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);
$CLJS.sF=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.tF=new $CLJS.M(null,"full-join","full-join",1305476385);$CLJS.uF=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.vF=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.YE=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.rC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.pC(f);return $CLJS.E.g(k,$CLJS.BB)?$CLJS.ab(f):k},e,a,b,c,d)}();
$CLJS.X(Uea,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.kF],null),$CLJS.NE],null));$CLJS.YE.m(null,$CLJS.di,function(a){throw $CLJS.ii($CLJS.uE("{0}: Don''t know how to determine the type of {1}",$CLJS.H([Xea,$CLJS.Mh.l($CLJS.H([a]))])),new $CLJS.h(null,1,[$CLJS.eF,a],null));});$CLJS.YE.m(null,$CLJS.oF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.ZE(a)});
var aF=function aF(a,b){return $CLJS.vd(a)?$CLJS.Re(function(d){return aF.g?aF.g(d,b):aF.call(null,d,b)},a):$CLJS.vd(b)?$CLJS.Re(function(d){return aF.g?aF.g(a,d):aF.call(null,a,d)},b):$CLJS.E.g(a,$CLJS.kF)?!0:$CLJS.DC(a,b)};$CLJS.X($CLJS.iF,bF($CLJS.Lj,"expression returning a boolean"));$CLJS.X($CLJS.sF,bF($CLJS.dk,"expression returning a string"));$CLJS.X($CLJS.vF,bF($CLJS.wj,"expression returning an integer"));$CLJS.X(Vea,bF($CLJS.ZD,"expression returning a non-integer real number"));
$CLJS.X($CLJS.uF,bF($CLJS.Pj,"expression returning a number"));$CLJS.X(Wea,bF($CLJS.dD,"expression returning a date"));$CLJS.X(Yea,bF($CLJS.bE,"expression returning a time"));$CLJS.X(Tea,bF($CLJS.CD,"expression returning a date time"));$CLJS.X($CLJS.gF,bF($CLJS.Wj,"expression returning a date, time, or date time"));$CLJS.wF=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.Pj,null,$CLJS.dk,null,$CLJS.Wj,null,$CLJS.Lj,null],null),null);$CLJS.X($CLJS.pF,bF($CLJS.wF,"an expression that can be compared with :\x3e or :\x3c"));
var Zea=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.NC,null,$CLJS.Pj,null,$CLJS.dk,null,$CLJS.tD,null,$CLJS.Wj,null,$CLJS.Lj,null,$CLJS.XD,null],null),null);$CLJS.X($CLJS.rF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sF],null),bF($CLJS.tD,"expression returning a BSONID")],null));$CLJS.X($CLJS.nF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,bF(Zea,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.X($CLJS.jF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,bF($CLJS.Aj,"any type of expression")],null));
$CLJS.X($CLJS.fF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,$CLJS.Ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DE,$CLJS.Zj],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,$CLJS.Ts],null)],null)],null)],null));