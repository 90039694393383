var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var GH,HH,Efa,JH,Ffa,Gfa,Hfa,KH,IH;$CLJS.EH=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.FH=function(a,b){return $CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
GH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.DC(b,$CLJS.dD)?$CLJS.pH:$CLJS.DC(b,$CLJS.bE)?$CLJS.qE:$CLJS.DC(b,$CLJS.CD)?$CLJS.rE:null;return $CLJS.n(b)?$CLJS.BF(b,a):!0};
HH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.EH(function(d){return $CLJS.DC($CLJS.ZE(d),$CLJS.oD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Nk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.ZE($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(GH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
Efa=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,1,[$CLJS.Ct,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Et,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Dj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(HH(b))].join("")}],null),$CLJS.Te(HH)],null)],null)};
JH=function(a){var b=$CLJS.Q,c=Efa(a);a=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,1,[$CLJS.Ct,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null)],null)],null);
return new $CLJS.P(null,3,5,b,[$CLJS.Cs,c,a],null)};Ffa=function(a){return $CLJS.Qd($CLJS.EF,$CLJS.hf.g(function(b){var c=$CLJS.ZE(b),d=$CLJS.DC(c,$CLJS.kF);b=d?$CLJS.BF($CLJS.yG,b):d;return $CLJS.n(b)?$CLJS.Pj:c},a))};Gfa=function(a){a=$CLJS.FH(function(b){return!$CLJS.DC(b,$CLJS.oD)},$CLJS.hf.g($CLJS.ZE,a));return $CLJS.DC(a,$CLJS.kF)?$CLJS.Wj:a};Hfa=function(a){return $CLJS.n($CLJS.Re(function(b){return $CLJS.DC($CLJS.ZE(b),$CLJS.oD)},a))?Gfa(a):Ffa(a)};
KH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.LH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);IH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,IH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.ZE(a);return $CLJS.Qe(function(d){return GH(d,c)},b)}],null)],null));
$CLJS.X(KH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null)],null));$CLJS.UG.g($CLJS.gs,JH($CLJS.gs));$CLJS.UG.g($CLJS.vt,JH($CLJS.vt));$CLJS.LF($CLJS.hs,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,KH],null)]));$CLJS.LF($CLJS.PG,$CLJS.H([$CLJS.vt,$CLJS.ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,KH],null)]));
for(var MH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gs,$CLJS.vt,$CLJS.hs],null)),NH=null,OH=0,PH=0;;)if(PH<OH){var Ifa=NH.X(null,PH);$CLJS.CF(Ifa,$CLJS.LH);PH+=1}else{var QH=$CLJS.y(MH);if(QH){var RH=QH;if($CLJS.Ad(RH)){var SH=$CLJS.lc(RH),Jfa=$CLJS.mc(RH),Kfa=SH,Lfa=$CLJS.D(SH);MH=Jfa;NH=Kfa;OH=Lfa}else{var Mfa=$CLJS.A(RH);$CLJS.CF(Mfa,$CLJS.LH);MH=$CLJS.B(RH);NH=null;OH=0}PH=0}else break}$CLJS.YE.m(null,$CLJS.LH,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);return Hfa(a)});
$CLJS.JF($CLJS.VF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null)]));$CLJS.CF($CLJS.VF,$CLJS.oF);
for(var TH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RG,$CLJS.MF,$CLJS.hG],null)),UH=null,VH=0,WH=0;;)if(WH<VH){var Nfa=UH.X(null,WH);$CLJS.JF(Nfa,$CLJS.H([$CLJS.vt,$CLJS.ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null)]));WH+=1}else{var XH=$CLJS.y(TH);if(XH){var YH=XH;if($CLJS.Ad(YH)){var ZH=$CLJS.lc(YH),Ofa=$CLJS.mc(YH),Pfa=ZH,Qfa=$CLJS.D(ZH);TH=Ofa;UH=Pfa;VH=Qfa}else{var Rfa=$CLJS.A(YH);$CLJS.JF(Rfa,$CLJS.H([$CLJS.vt,$CLJS.ZD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null)]));TH=$CLJS.B(YH);UH=null;VH=0}WH=0}else break}
for(var $H=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QF,$CLJS.sG,$CLJS.zG],null)),aI=null,bI=0,cI=0;;)if(cI<bI){var Sfa=aI.X(null,cI);$CLJS.JF(Sfa,$CLJS.H([$CLJS.vt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null)]));cI+=1}else{var dI=$CLJS.y($H);if(dI){var eI=dI;if($CLJS.Ad(eI)){var fI=$CLJS.lc(eI),Tfa=$CLJS.mc(eI),Ufa=fI,Vfa=$CLJS.D(fI);$H=Tfa;aI=Ufa;bI=Vfa}else{var Wfa=$CLJS.A(eI);$CLJS.JF(Wfa,$CLJS.H([$CLJS.vt,$CLJS.wj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null)]));$H=$CLJS.B(eI);aI=null;bI=0}cI=0}else break}$CLJS.JF($CLJS.SF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null)]));
$CLJS.YE.m(null,$CLJS.SF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.DC($CLJS.ZE(b),$CLJS.wj)&&$CLJS.DC($CLJS.ZE(a),$CLJS.wj)?$CLJS.wj:$CLJS.ZD});