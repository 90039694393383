var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var Oz=function(){},Pz=function(a){return $CLJS.J.g($CLJS.Dy,a).value},vda=function(a){var b=Pz(a);return $CLJS.n(function(){var c=Pz($CLJS.Uy);return $CLJS.Kk.g?$CLJS.Kk.g(c,b):$CLJS.Kk.call(null,c,b)}())?$CLJS.zz:$CLJS.n(function(){var c=Pz($CLJS.Ty);return $CLJS.Kk.g?$CLJS.Kk.g(c,b):$CLJS.Kk.call(null,c,b)}())?$CLJS.Jz:$CLJS.n(function(){var c=Pz($CLJS.Ry);return $CLJS.Kk.g?$CLJS.Kk.g(c,b):$CLJS.Kk.call(null,c,b)}())?$CLJS.Cz:$CLJS.n(function(){var c=Pz($CLJS.Ly);return $CLJS.Kk.g?
$CLJS.Kk.g(c,b):$CLJS.Kk.call(null,c,b)}())?$CLJS.Iz:$CLJS.n(function(){var c=Pz($CLJS.Sy);return $CLJS.Kk.g?$CLJS.Kk.g(c,b):$CLJS.Kk.call(null,c,b)}())?$CLJS.Kz:$CLJS.n(function(){var c=Pz($CLJS.Oy);return $CLJS.Kk.g?$CLJS.Kk.g(c,b):$CLJS.Kk.call(null,c,b)}())?$CLJS.Fz:$CLJS.n(function(){var c=Pz($CLJS.Iy);return $CLJS.Kk.g?$CLJS.Kk.g(c,b):$CLJS.Kk.call(null,c,b)}())?$CLJS.Gz:$CLJS.Hz},Qz=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},Rz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.be.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.Nz,c))].join(""),$CLJS.H(["color:black"]))],null)},Sz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.be.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.Nz,$CLJS.Lz)),";background-color:",$CLJS.p.h($CLJS.J.g($CLJS.Nz,
c))].join(""),$CLJS.H(["color:black;background-color:inherit"]))],null)},wda=function(a){function b(c,d){return d>=c}a=Pz(a);if(b(Pz($CLJS.Uy),a))return"error";if(b(Pz($CLJS.Ty),a))return"warn";if(b(Pz($CLJS.Ry),a))return"info";b(Pz($CLJS.Ly),a);return"log"},Tz=function(a){return function(b){var c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.Jy),e=$CLJS.J.g(c,$CLJS.Ut);b=$CLJS.J.g(c,$CLJS.Vy);e=wda(e);e=$CLJS.Ca(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.Se.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=
["[",$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},Uz=function Uz(a,b){for(;;){if($CLJS.E.g($CLJS.yz,b))return Rz(a,", ",$CLJS.Hz);if($CLJS.E.g($CLJS.Az,b))return Qz(a);if(b instanceof $CLJS.M)return Rz(a,b,$CLJS.Cz);if(b instanceof $CLJS.r)return Rz(a,b,$CLJS.Iz);if("string"===typeof b)return Rz(a,$CLJS.Mh.l($CLJS.H([b])),$CLJS.Bz);if($CLJS.zf(b)){var d=Qz(function(){var f=a,k=$CLJS.Eb(b);return Uz.g?Uz.g(f,k):Uz.call(null,f,k)}()),e=$CLJS.Fb(b);
return Uz.g?Uz.g(d,e):Uz.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.Hg)return d=a,d=Rz(d,"{",$CLJS.Mz),d=$CLJS.fb(Uz,d,$CLJS.ff($CLJS.yz,b)),Rz(d,"}",$CLJS.Mz);if($CLJS.xd(b))return d=a,d=Rz(d,["#",$CLJS.p.h(function(){var f=$CLJS.ab(b),k=f.name;return $CLJS.td(k)?$CLJS.Mh.l($CLJS.H([f])):k}())," "].join(""),$CLJS.Dz),d=Rz(d,"{",$CLJS.Mz),d=$CLJS.fb(Uz,d,$CLJS.ff($CLJS.yz,b)),Rz(d,"}",$CLJS.Mz);if($CLJS.vd(b))return d=a,d=Rz(d,"#{",$CLJS.Mz),d=$CLJS.fb(Uz,d,$CLJS.ff($CLJS.Az,b)),Rz(d,
"}",$CLJS.Mz);if($CLJS.zd(b))return d=a,d=Rz(d,"[",$CLJS.Mz),d=$CLJS.fb(Uz,d,$CLJS.ff($CLJS.Az,b)),Rz(d,"]",$CLJS.Mz);if(b instanceof $CLJS.Rf)d=Rz(a,"#queue ",$CLJS.Dz),e=$CLJS.eg.g($CLJS.Cf,b),a=d,b=e;else{if($CLJS.Dd(b))return d=a,d=Rz(d,"(",$CLJS.Dz),d=$CLJS.fb(Uz,d,$CLJS.ff($CLJS.Az,b)),Rz(d,")",$CLJS.Dz);if(null!=b?b.I&16384||$CLJS.Bc===b.$i||(b.I?0:$CLJS.$a(Oz,b)):$CLJS.$a(Oz,b))d=Rz(a,"#atom ",$CLJS.Dz),e=$CLJS.q(b),a=d,b=e;else if($CLJS.fi(b))d=Rz(a,"#uuid ",$CLJS.Dz),e=$CLJS.p.h(b),a=d,
b=e;else if($CLJS.Xa(b))d=Rz(a,"#js ",$CLJS.Dz),e=$CLJS.fb(function(f,k){return function(l,m){return $CLJS.R.j(l,$CLJS.zh.h(m),$CLJS.Ca(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Ua(b))d=Rz(a,"#js ",$CLJS.Dz),e=$CLJS.eg.g($CLJS.Cf,b),a=d,b=e;else return Rz(a,$CLJS.Mh.l($CLJS.H([b])),$CLJS.Ez)}}};$CLJS.xda=Tz(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Ut);var b=$CLJS.J.g(a,$CLJS.Jy),c=$CLJS.J.g(a,$CLJS.Qi);$CLJS.J.g(a,$CLJS.Vy);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.yda=Tz(function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Ut);a=$CLJS.J.g(b,$CLJS.Jy);var d=$CLJS.J.g(b,$CLJS.Qi);$CLJS.J.g(b,$CLJS.Vy);b=vda(c);d=Uz(Qz(Sz(Sz(Sz(new $CLJS.P(null,2,5,$CLJS.Q,["",$CLJS.Cf],null),"[",b),a,b),"]",b)),d);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.ee(a,d)});
$CLJS.zda=Tz(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Ut);var b=$CLJS.J.g(a,$CLJS.Jy),c=$CLJS.J.g(a,$CLJS.Qi);$CLJS.J.g(a,$CLJS.Vy);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.Mh.l($CLJS.H([c]))],null)});