var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Yoa,Zoa,$oa,apa,L3,M3,N3,O3,bpa,cpa,dpa,epa,fpa,gpa,hpa,ipa,jpa,Y3,Z3,kpa,lpa,mpa,X3,W3,npa,$3;$CLJS.G3=function(a,b){$CLJS.fb(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
Yoa=function(a){return function(b){var c=$CLJS.$e(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.Gl(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};Zoa=function(a,b){return function f(d,e){return new $CLJS.ne(null,function(){var k;a:{var l=d;for(k=e;;){var m=l;l=$CLJS.I(m,0,null);if(m=$CLJS.y(m)){var t=a.h?a.h(l):a.call(null,l);if($CLJS.Hd(k,t))l=$CLJS.Lc(m);else{k=$CLJS.ee(l,f($CLJS.Lc(m),$CLJS.be.g(k,t)));break a}}else{k=null;break a}}}return k},null,null)}(b,$CLJS.bh)};
$oa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.w(b.slice(3),0,null):null;return $CLJS.Va($CLJS.y($CLJS.LA(c,d)))?c:$CLJS.Se.N($CLJS.GX,c,d,e,b)};$CLJS.H3=function(a,b){return $CLJS.c1.g(a,b instanceof $CLJS.M?b:$CLJS.si.h(b))};
$CLJS.I3=function(a,b,c,d){if($CLJS.y(c)){d=$CLJS.Rk.g($CLJS.AW,d);var e=$CLJS.eg.j($CLJS.bh,$CLJS.hf.h(function(f){return $CLJS.u2.v(a,b,f,c)}),d);return $CLJS.Rk.g(function(f){return $CLJS.R.j(f,$CLJS.VZ,$CLJS.Hd(e,f))},c)}return null};$CLJS.J3=function(a){return $CLJS.E.g($CLJS.pC(a),$CLJS.uL)};apa=function(a,b){return $CLJS.n($CLJS.dF.h(a))?$CLJS.Sk.j(a,$CLJS.dF,function(c){return $CLJS.wd(c)?$CLJS.Rk.g(function(d){return $CLJS.K3.g?$CLJS.K3.g(d,b):$CLJS.K3.call(null,d,b)},c):c}):a};
L3=function(a){if($CLJS.n(a)){var b=$CLJS.lf;a:try{if($CLJS.zd(a)&&4===$CLJS.D(a))try{if($CLJS.F(a,0)instanceof $CLJS.M)try{var c=$CLJS.F(a,2);if($CLJS.zd(c)&&3===$CLJS.D(c))try{var d=$CLJS.F(c,0);if($CLJS.he(d,$CLJS.uG))try{var e=$CLJS.F(a,3);if($CLJS.zd(e)&&3===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.he(f,$CLJS.uG)){$CLJS.F(e,1);$CLJS.F(e,2);$CLJS.F(c,1);$CLJS.F(c,2);$CLJS.F(a,0);$CLJS.F(a,1);var k=new $CLJS.P(null,1,5,$CLJS.Q,[!0],null)}else throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=
m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Y){k=new $CLJS.P(null,1,5,$CLJS.Q,[!1],null);break a}throw l;}throw m;}a=$CLJS.A($CLJS.y(b($CLJS.Wa,k)))}else a=null;return a};M3=function(a){if($CLJS.n(L3(a))){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);$CLJS.I(a,3,null);return b}return null};N3=function(a){return $CLJS.n(L3(a))?($CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),$CLJS.I(a,3,null)):null};
O3=function(a,b){var c=$CLJS.K3;if($CLJS.Va(L3(a)))return a;var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null),f=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[d,e,f,$CLJS.Se.j(c,a,b)],null)};
bpa=function(a,b,c){return $CLJS.td($CLJS.CL.h(a))?a:$CLJS.n(b)?$oa(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.CL],null),function(d){return function l(f,k){try{if($CLJS.zd(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.he(m,$CLJS.uG))try{var t=$CLJS.F(k,1);if(null!=t?t.C&256||$CLJS.Bc===t.mf||(t.C?0:$CLJS.$a($CLJS.wb,t)):$CLJS.$a($CLJS.wb,t))try{var u=$CLJS.J.j(t,$CLJS.DP,$CLJS.NV);if($CLJS.E.g(u,b))return $CLJS.F(k,2),$CLJS.K3.g?$CLJS.K3.g(k,c):$CLJS.K3.call(null,k,c);throw $CLJS.Y;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)return $CLJS.iX(l,f,k);throw v;}throw x;}}($CLJS.Cf,d)}):$CLJS.Sk.j(a,$CLJS.CL,function(d){return $CLJS.Rk.g(function(e){return O3(e,$CLJS.H([c]))},d)})};
$CLJS.K3=function(a,b){var c=$CLJS.pC(a);switch(c instanceof $CLJS.M?c.T:null){case "field":return $CLJS.TE(a,$CLJS.RE,$CLJS.H([$CLJS.DP,b]));case "metadata/column":return $CLJS.RE(a,$CLJS.h_,b);case "mbql/join":return c=$CLJS.M1(a),bpa(apa($CLJS.RE(a,$CLJS.hF,b),b),c,b);default:return b=$CLJS.UA($CLJS.Ny),$CLJS.n($CLJS.TA("metabase.lib.join",b))&&$CLJS.SA("metabase.lib.join",b,$CLJS.Lw.l($CLJS.H(["with-join-value should not be called with",$CLJS.Mh.l($CLJS.H([a]))])),null),a}};
$CLJS.P3=function(a,b,c){var d=$CLJS.R.l,e=$CLJS.KA($CLJS.HZ,$CLJS.T)(c);a=$CLJS.N1($CLJS.hF.h(a),$CLJS.KA($CLJS.HZ,$CLJS.T)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.R,c,$CLJS.HZ,e,$CLJS.H([$CLJS.UZ,b]))};cpa=function(a,b){return $CLJS.Va(b)?a:$CLJS.Rk.g(function(c){var d=N3(c);d=$CLJS.n(d)?$CLJS.n($CLJS.M1(d))?null:O3(c,$CLJS.H([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.Q3=function(a,b){b=cpa($CLJS.Rk.g($CLJS.BW,b),$CLJS.M1(a));return $CLJS.RE(a,$CLJS.CL,$CLJS.Fe(b))};
$CLJS.R3=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.E.g(b,$CLJS.Cf)?$CLJS.pA:$CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.Pk.g($CLJS.hf.h($CLJS.AW),function(){var c=$CLJS.M1(a);return $CLJS.n(c)?$CLJS.hf.h(function(d){return $CLJS.K3(d,c)}):$CLJS.Td}()),b));return $CLJS.RE(a,$CLJS.dF,b)};
dpa=function(a,b){b=$CLJS.cm(function(d){return $CLJS.u2.g(d,a)},b);var c=$CLJS.FH($CLJS.KA($CLJS.L_,$CLJS.M_),b);if($CLJS.n(c))return c;b=$CLJS.A(b);if($CLJS.n(b))return b;b=$CLJS.FH($CLJS.L_,a);if($CLJS.n(b))return b;b=$CLJS.FH($CLJS.M_,a);return $CLJS.n(b)?b:$CLJS.A(a)};epa=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.OA(a,/ id$/i,"")):null};
fpa=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.Jh(function(){return $CLJS.R2(b)});var d=new $CLJS.Jh(function(){return $CLJS.R2(a)});return $CLJS.E.g(a,b)||$CLJS.E.g(a,$CLJS.q(c))||$CLJS.E.g($CLJS.q(d),b)||$CLJS.E.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
gpa=function(a,b,c){var d=$CLJS.j0.g($CLJS.E.g($CLJS.Ej.h(b),$CLJS.rW)?b:a,b),e=$CLJS.n(c)?epa($CLJS.j0.g(a,c)):null,f=fpa(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Va($CLJS.nh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
hpa=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.RY(l,$CLJS.uG)&&$CLJS.Ed($CLJS.u2.v(a,b,l,e)))return $CLJS.K3(l,d);throw $CLJS.Y;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.Y)return $CLJS.iX(m,k,l);throw t;}throw u;}}($CLJS.Cf,c)};
ipa=function(a,b,c,d,e,f){c=hpa(a,b,c,d,f);return function t(l,m){try{if($CLJS.zd(m)&&4===$CLJS.D(m))try{var u=$CLJS.F(m,2);if($CLJS.RY(u,$CLJS.uG))try{var v=$CLJS.F(m,3);if($CLJS.RY(v,$CLJS.uG)){var x=$CLJS.F(m,3),z=$CLJS.F(m,2),C=$CLJS.F(m,0),G=$CLJS.F(m,1),K=$CLJS.M1(z),S=$CLJS.M1(x);if($CLJS.Va($CLJS.n(K)?K:S))return $CLJS.n($CLJS.u2.v(a,b,x,e))?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,$CLJS.K3(z,d),x],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,z,$CLJS.K3(x,d)],null);if($CLJS.E.g(K,d)&&$CLJS.E.g(S,d)){var V=
$CLJS.TE(z,$CLJS.Hk,$CLJS.H([$CLJS.DP])),Z=$CLJS.TE(x,$CLJS.Hk,$CLJS.H([$CLJS.DP])),ha=null==$CLJS.u2.v(a,b,V,e);var ra=ha?$CLJS.u2.v(a,b,Z,e):ha;return $CLJS.n(ra)?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,z,Z],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,V,x],null)}return m}throw $CLJS.Y;}catch(zb){if(zb instanceof Error){var Ma=zb;if(Ma===$CLJS.Y)throw $CLJS.Y;throw Ma;}throw zb;}else throw $CLJS.Y;}catch(zb){if(zb instanceof Error){Ma=zb;if(Ma===$CLJS.Y)throw $CLJS.Y;throw Ma;}throw zb;}else throw $CLJS.Y;
}catch(zb){if(zb instanceof Error){Ma=zb;if(Ma===$CLJS.Y)return $CLJS.iX(t,l,m);throw Ma;}throw zb;}}($CLJS.Cf,c)};jpa=function(a,b){var c=$CLJS.SW();$CLJS.G3(c,b);return c(a)};
$CLJS.S3=function(a,b,c){if($CLJS.Hd(c,$CLJS.hF))return c;var d=$CLJS.PW(a,b),e=$CLJS.U0.j(a,b,d),f=$CLJS.y($CLJS.lf($CLJS.Wa,function v(t,u){try{if($CLJS.zd(u)&&1<=$CLJS.D(u))try{var x=$CLJS.Wk.j(u,0,1);if($CLJS.zd(x)&&1===$CLJS.D(x))try{var z=$CLJS.F(x,0);if($CLJS.he(z,$CLJS.uG))return new $CLJS.P(null,1,5,$CLJS.Q,[u],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var C=G;if(C===$CLJS.Y)throw $CLJS.Y;throw C;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Y)throw $CLJS.Y;
throw C;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Y)return $CLJS.KV(v,t,u);throw C;}throw G;}}($CLJS.Cf,$CLJS.CL.h(c))));f=dpa(e,f);var k=jpa(gpa(a,c,f),$CLJS.cm($CLJS.hF,$CLJS.NN.h(d))),l=$CLJS.S0.h($CLJS.H1.g(a,$CLJS.zL.h(c)));return $CLJS.K3($CLJS.Sk.j(c,$CLJS.CL,function(t){return $CLJS.Rk.g(function(u){return ipa(a,b,u,k,e,l)},t)}),k)};$CLJS.T3=function(a){return $CLJS.CL.h(a)};
$CLJS.U3=function(a){return $CLJS.gl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Ej,$CLJS.DL,$CLJS.PE,a],null),$CLJS.E.g(a,$CLJS.mF)?new $CLJS.h(null,1,[$CLJS.di,!0],null):null]))};$CLJS.V3=function(a,b){b=$CLJS.A($CLJS.zL.h(b));return $CLJS.n($CLJS.YV.h(b))?$CLJS.f0(a,$CLJS.YV.h(b)):$CLJS.n($CLJS.oP.h(b))?$CLJS.d0(a,$CLJS.oP.h(b)):null};
Y3=function(a){a=$CLJS.EH(function(d){return $CLJS.M_(d)?W3:$CLJS.L_(d)?X3:$CLJS.fu},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,W3);var c=$CLJS.J.g(b,X3);b=$CLJS.J.g(b,$CLJS.fu);return $CLJS.gf.l(a,c,$CLJS.H([b]))};Z3=function(a,b,c,d){return $CLJS.Va(c)?d:$CLJS.Rk.g(function(e){return $CLJS.n($CLJS.VZ.h(e))?$CLJS.H3(e,$CLJS.P2(c)):e},$CLJS.I3(a,b,d,new $CLJS.P(null,1,5,$CLJS.Q,[c],null)))};
kpa=function(a,b,c,d){var e=new $CLJS.Jh(function(){return $CLJS.U0.v(a,b,d,new $CLJS.h(null,2,[$CLJS.y0,!1,$CLJS.G0,!1],null))});return $CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.Xl(function(f){var k=$CLJS.O(f),l=$CLJS.J.g(k,$CLJS.iL);return $CLJS.n(function(){var m=$CLJS.L_(k);return m?l:m}())?(f=$CLJS.FH(function(m){return $CLJS.E.g(l,$CLJS.Zi.h(m))},$CLJS.q(e)),$CLJS.n(f)?$CLJS.R.j(k,$3,f):null):null}),$CLJS.U0.j(a,b,c)))};lpa={};
mpa=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);X3=new $CLJS.M(null,"fk","fk",398442651);W3=new $CLJS.M(null,"pk","pk",-771936732);npa=new $CLJS.r("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);$3=new $CLJS.M("metabase.lib.join","target","metabase.lib.join/target",203858838);$CLJS.J0.m(null,$CLJS.uL,function(a,b,c){b=$CLJS.O(c);b=$CLJS.J.g(b,$CLJS.zL);b=$CLJS.I(b,0,null);b=$CLJS.O(b);c=$CLJS.J.g(b,$CLJS.oP);b=$CLJS.J.g(b,$CLJS.YV);c=$CLJS.n(c)?$CLJS.zE.h($CLJS.d0(a,c)):null;if($CLJS.n(c))return c;$CLJS.n(b)?(c=$CLJS.f0(a,b),a=$CLJS.n(c)?$CLJS.j0.j(a,0,c):$CLJS.R1(b)):a=null;return $CLJS.n(a)?a:$CLJS.WE("Native Query")});$CLJS.Q0.m(null,$CLJS.uL,function(a,b,c){a=$CLJS.j0.j(a,b,c);c=$CLJS.hF.h(c);return new $CLJS.h(null,2,[$CLJS.T,$CLJS.n(c)?c:a,$CLJS.zE,a],null)});
$CLJS.P0.m(null,$CLJS.uL,function(){throw $CLJS.ii("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.J0.m(null,$CLJS.DL,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.PE);a=a instanceof $CLJS.M?a.T:null;switch(a){case "left-join":return $CLJS.WE("Left outer join");case "right-join":return $CLJS.WE("Right outer join");case "inner-join":return $CLJS.WE("Inner join");case "full-join":return $CLJS.WE("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.Q0.m(null,$CLJS.DL,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.PE);c=$CLJS.J.g(d,$CLJS.di);a=new $CLJS.h(null,2,[$CLJS.H0,$CLJS.hB(e),$CLJS.zE,$CLJS.j0.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.di,!0):a});
$CLJS.R0.m(null,$CLJS.uL,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.J.g(e,$CLJS.hF),k=$CLJS.J.j(e,$CLJS.dF,$CLJS.pA),l=$CLJS.J.g(e,$CLJS.zL);c=$CLJS.O(d);var m=$CLJS.J.g(c,$CLJS.E0);if($CLJS.E.g(k,$CLJS.pA))return null;var t="undefined"!==typeof $CLJS.pM&&"undefined"!==typeof lpa&&"undefined"!==typeof $CLJS.opa&&"undefined"!==typeof $CLJS.a4?new $CLJS.Gc(function(){return $CLJS.a4},$CLJS.od(npa,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),null):null,u=function(){var v=$CLJS.R.j(a,$CLJS.zL,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.E.g(k,$CLJS.Hy)?$CLJS.S0.v(u,-1,$CLJS.rd(l),c):function(){return function z(x){return new $CLJS.ne(null,function(){for(;;){var C=$CLJS.y(x);if(C){if($CLJS.Ad(C)){var G=$CLJS.lc(C),K=$CLJS.D(G),S=$CLJS.qe(K);a:for(var V=0;;)if(V<K){var Z=$CLJS.kd(G,V);Z=$CLJS.TE(Z,$CLJS.Hk,$CLJS.H([$CLJS.DP]));Z=$CLJS.i0.j(u,-1,Z);S.add(Z);V+=1}else{G=!0;break a}return G?$CLJS.te($CLJS.ve(S),z($CLJS.mc(C))):$CLJS.te($CLJS.ve(S),null)}S=$CLJS.A(C);S=$CLJS.TE(S,$CLJS.Hk,$CLJS.H([$CLJS.DP]));
return $CLJS.ee($CLJS.i0.j(u,-1,S),z($CLJS.Lc(C)))}return null}},null,null)}(k)}();return $CLJS.Rk.g(function(v){v=$CLJS.R.j(v,$CLJS.f_,f);v=$CLJS.K3($CLJS.R.l(v,$CLJS.zE,$CLJS.j0.j(a,b,v),$CLJS.H([$CLJS.YK,$CLJS.RZ])),f);return $CLJS.P3(e,m,v)},c)});$CLJS.T0.m(null,$CLJS.uL,function(a,b,c,d){return $CLJS.S0.v(a,b,$CLJS.R.j(c,$CLJS.dF,$CLJS.Hy),d)});
var b4,ppa=$CLJS.Xe($CLJS.N),qpa=$CLJS.Xe($CLJS.N),rpa=$CLJS.Xe($CLJS.N),spa=$CLJS.Xe($CLJS.N),tpa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.rC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));b4=new $CLJS.ei($CLJS.Fh.g("metabase.lib.join","join-clause-method"),$CLJS.pC,tpa,ppa,qpa,rpa,spa);b4.m(null,$CLJS.uL,function(a){return a});b4.m(null,$CLJS.rW,function(a){return $CLJS.zW(new $CLJS.h(null,2,[$CLJS.Ej,$CLJS.uL,$CLJS.zL,$CLJS.zL.h($CLJS.NW(a))],null))});
b4.m(null,$CLJS.eW,function(a){return $CLJS.zW(new $CLJS.h(null,2,[$CLJS.Ej,$CLJS.uL,$CLJS.zL,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)],null))});b4.m(null,$CLJS.rL,function(a){return $CLJS.zW(new $CLJS.h(null,2,[$CLJS.Ej,$CLJS.uL,$CLJS.zL,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.YV,$CLJS.Zi.h(a),$CLJS.Ej,$CLJS.eW],null)],null)],null))});
b4.m(null,$CLJS.cL,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.h_);a=$CLJS.J.g(b,mpa);b=b4.h(new $CLJS.h(null,3,[$CLJS.Ej,$CLJS.eW,$CLJS.DB,new $CLJS.h(null,1,[$CLJS.LE,$CLJS.p.h($CLJS.VE())],null),$CLJS.oP,$CLJS.Zi.h(b)],null));c=$CLJS.n(c)?$CLJS.K3(b,c):b;return $CLJS.n(a)?$CLJS.R3.g?$CLJS.R3.g(c,a):$CLJS.R3.call(null,c,a):c});
$CLJS.c4=function(){function a(d,e){return $CLJS.Q3(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.h2(b4.h(d),$CLJS.dF,$CLJS.Hy)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.upa=function(){function a(d,e,f){var k=$CLJS.c4.h(f);f=$CLJS.td($CLJS.T3.h?$CLJS.T3.h(k):$CLJS.T3.call(null,k))?function(){var m=$CLJS.V3.g?$CLJS.V3.g(d,k):$CLJS.V3.call(null,d,k);return $CLJS.d4.j?$CLJS.d4.j(d,e,m):$CLJS.d4.call(null,d,e,m)}():null;f=$CLJS.y(f)?$CLJS.Q3(k,f):k;var l=$CLJS.S3(d,e,f);return $CLJS.$W.l(d,e,$CLJS.Sk,$CLJS.H([$CLJS.NN,function(m){return $CLJS.be.g($CLJS.Df(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.e4=function(){function a(d,e){return $CLJS.Fe($CLJS.J.g($CLJS.PW(d,e),$CLJS.NN))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.vpa=function(){function a(d){d=$CLJS.D1($CLJS.c0(d));d=$CLJS.qA.h(d);return $CLJS.eg.j($CLJS.Cf,$CLJS.Pk.g($CLJS.kf($CLJS.Ve($CLJS.Hd,d)),$CLJS.hf.h($CLJS.U3)),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mF,$CLJS.cF,$CLJS.qF,$CLJS.tF],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.wpa=function(){function a(d,e,f,k){var l=$CLJS.J3(f)?$CLJS.M1(f):null,m=$CLJS.eg.j($CLJS.bh,$CLJS.Pk.g($CLJS.hf.h($CLJS.M1),Yoa(function(t){return $CLJS.Nk.g(t,l)})),$CLJS.e4.g(d,e));f=$CLJS.n(k)?k:$CLJS.J3(f)?M3($CLJS.A($CLJS.T3(f))):null;return Y3(Z3(d,e,f,$CLJS.Qk.g(function(t){t=$CLJS.M1(t);return $CLJS.n(t)?$CLJS.Hd(m,t):null},$CLJS.U0.v(d,e,$CLJS.PW(d,e),new $CLJS.h(null,1,[$CLJS.y0,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();
$CLJS.xpa=function(){function a(d,e,f,k,l){k=$CLJS.J3(f)?$CLJS.V3(d,f):f;var m=$CLJS.J3(f)?$CLJS.M1(f):null;f=$CLJS.n(l)?l:$CLJS.J3(f)?N3($CLJS.A($CLJS.T3(f))):null;f=$CLJS.n(f)?$CLJS.Va(m)?$CLJS.TE(f,$CLJS.Hk,$CLJS.H([$CLJS.DP])):f:null;return Y3(Z3(d,e,f,$CLJS.hf.g(function(t){t=$CLJS.R.j(t,$CLJS.YK,$CLJS.RZ);return $CLJS.n(m)?$CLJS.K3(t,m):t},$CLJS.U0.v(d,e,k,new $CLJS.h(null,1,[$CLJS.y0,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();$CLJS.ypa=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.z2}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.z2};return b}();
$CLJS.d4=function(){function a(d,e,f){function k(u,v){u=$CLJS.Hk.g(u,$CLJS.T1);v=$CLJS.Hk.g(v,$CLJS.T1);return $CLJS.F3.l($CLJS.x2.h($CLJS.es),u,$CLJS.H([v]))}function l(u,v){return $CLJS.Fe(Zoa(function(x){return $CLJS.Zi.h($3.h(x))},kpa(d,e,u,v)))}var m=$CLJS.PW(d,e),t=function(){var u=l(m,f);return $CLJS.n(u)?$CLJS.Rk.g(function(v){return k(v,$3.h(v))},u):null}();if($CLJS.n(t))return t;t=l(f,m);return $CLJS.n(t)?$CLJS.Rk.g(function(u){return k($3.h(u),u)},t):null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.zpa=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.J3(k)?M3($CLJS.A($CLJS.T3(k))):null;$CLJS.n(l)?(l=$CLJS.m0.j(e,f,l),l=$CLJS.LA(l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YW,$CLJS.zE],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.OW(e,f)){l=$CLJS.e4.g(e,f);var m=$CLJS.td(l);k=m?m:$CLJS.J3(k)?$CLJS.E.g($CLJS.hF.h(k),$CLJS.hF.h($CLJS.A(l))):null;k=$CLJS.n(k)?$CLJS.Y_(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.Y_(e),k=$CLJS.d0(e,k),e=$CLJS.j0.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.WE("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.Apa=function(){function a(d,e,f,k){f=$CLJS.BW.h(f);$CLJS.I(f,0,null);$CLJS.I(f,1,null);var l=$CLJS.I(f,2,null),m=$CLJS.I(f,3,null);k=k instanceof $CLJS.M?k:$CLJS.si.h(k);e=$CLJS.OW(d,e);l=$CLJS.g1.j(d,e,l);d=$CLJS.g1.j(d,e,m);m=null==k||$CLJS.Hd($CLJS.fh($CLJS.hf.g($CLJS.si,l)),k);d=null==k||$CLJS.Hd($CLJS.fh($CLJS.hf.g($CLJS.si,d)),k);f=m?$CLJS.Sk.v(f,2,$CLJS.H3,k):f;return d?$CLJS.Sk.v(f,3,$CLJS.H3,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.M0.m(null,$CLJS.NN,function(a,b){var c=$CLJS.Fe($CLJS.e4.g(a,b));c=null==c?null:$CLJS.hf.g(function(d){return $CLJS.j0.j(a,b,d)},c);return null==c?null:$CLJS.Nt(" + ",c)});