var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var afa,bfa,cfa,dfa,efa,ffa,gfa,$G,hfa,ifa,jfa,kfa,lfa,mfa,nfa,ofa,bH,pfa;$CLJS.VG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);afa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.WG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);bfa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
cfa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.XG=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.YG=new $CLJS.M(null,"column-name","column-name",551523580);dfa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.ZG=new $CLJS.M(null,"display-info","display-info",-816930907);efa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
ffa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);gfa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);$G=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);hfa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);ifa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);jfa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
kfa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);lfa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);mfa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);nfa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.aH=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);ofa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
bH=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.cH=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);pfa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.LF($CLJS.by,$CLJS.H([$CLJS.vt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.is,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)],null)],null)],null)]));$CLJS.LF($CLJS.vG,$CLJS.H([$CLJS.vt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.is,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)],null)],null)],null)]));
$CLJS.JF($CLJS.oG,$CLJS.H([$CLJS.vt,$CLJS.ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null)]));$CLJS.JF($CLJS.$F,$CLJS.H([$CLJS.vt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)],null)]));$CLJS.JF($CLJS.DG,$CLJS.H([$CLJS.vt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));
$CLJS.JF($CLJS.jk,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));$CLJS.CF($CLJS.jk,$CLJS.oF);$CLJS.JF($CLJS.IG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null)]));$CLJS.CF($CLJS.IG,$CLJS.oF);$CLJS.JF($CLJS.Zn,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));$CLJS.CF($CLJS.Zn,$CLJS.oF);
$CLJS.X($G,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,1,[$CLJS.Ct,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.JF($CLJS.fG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$G],null)]));$CLJS.CF($CLJS.fG,$CLJS.oF);
$CLJS.JF($CLJS.BG,$CLJS.H([$CLJS.vt,$CLJS.ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));$CLJS.JF($CLJS.JG,$CLJS.H([$CLJS.vt,$CLJS.ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null)]));$CLJS.JF($CLJS.HG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null)]));
$CLJS.JF($CLJS.QG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null)]));$CLJS.CF($CLJS.HG,$CLJS.oF);$CLJS.CF($CLJS.QG,$CLJS.oF);$CLJS.JF($CLJS.OG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));$CLJS.CF($CLJS.OG,$CLJS.oF);
$CLJS.JF($CLJS.aG,$CLJS.H([$CLJS.vt,$CLJS.ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null)]));$CLJS.X(bH,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.Cs,ofa,mfa,ffa,bfa,afa,efa,gfa,hfa,ifa,lfa,jfa,pfa,nfa,cfa,kfa,$CLJS.Dl],null));$CLJS.X($CLJS.WG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bH],null)],null));
$CLJS.dH=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.nG,$CLJS.by,$CLJS.XG,!1,$CLJS.aH,$CLJS.PF,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.WE("Count of rows"),$CLJS.YG,$CLJS.WE("Count"),$CLJS.Wt,$CLJS.WE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.nG,$CLJS.HG,$CLJS.VG,$CLJS.fk,$CLJS.XG,!0,$CLJS.aH,$CLJS.PF,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.WE("Sum of ..."),$CLJS.YG,$CLJS.WE("Sum"),$CLJS.Wt,$CLJS.WE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.nG,$CLJS.oG,$CLJS.VG,$CLJS.fk,$CLJS.XG,!0,$CLJS.aH,$CLJS.PF,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.WE("Average of ..."),$CLJS.YG,$CLJS.WE("Average"),$CLJS.Wt,$CLJS.WE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.nG,$CLJS.IG,$CLJS.VG,$CLJS.fk,$CLJS.XG,!0,$CLJS.aH,$CLJS.qG,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.WE("Median of ..."),$CLJS.YG,$CLJS.WE("Median"),$CLJS.Wt,$CLJS.WE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.nG,$CLJS.$F,$CLJS.VG,$CLJS.Ts,$CLJS.XG,!0,$CLJS.aH,$CLJS.PF,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.WE("Number of distinct values of ..."),$CLJS.YG,$CLJS.WE("Distinct values"),$CLJS.Wt,$CLJS.WE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.nG,$CLJS.QG,$CLJS.VG,$CLJS.fk,$CLJS.XG,!0,$CLJS.aH,$CLJS.PF,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.WE("Cumulative sum of ..."),
$CLJS.YG,$CLJS.WE("Sum"),$CLJS.Wt,$CLJS.WE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.nG,$CLJS.vG,$CLJS.XG,!1,$CLJS.aH,$CLJS.PF,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.WE("Cumulative count of rows"),$CLJS.YG,$CLJS.WE("Count"),$CLJS.Wt,$CLJS.WE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.nG,$CLJS.JG,$CLJS.VG,$CLJS.fk,$CLJS.XG,
!0,$CLJS.aH,$CLJS.EG,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.WE("Standard deviation of ..."),$CLJS.YG,$CLJS.WE("SD"),$CLJS.Wt,$CLJS.WE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.nG,$CLJS.Zn,$CLJS.VG,$CLJS.Yi,$CLJS.XG,!0,$CLJS.aH,$CLJS.PF,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.WE("Minimum of ..."),$CLJS.YG,$CLJS.WE("Min"),$CLJS.Wt,$CLJS.WE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.nG,$CLJS.jk,$CLJS.VG,$CLJS.Yi,$CLJS.XG,!0,$CLJS.aH,$CLJS.PF,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.WE("Maximum of ..."),$CLJS.YG,$CLJS.WE("Max"),$CLJS.Wt,$CLJS.WE("Maximum value of a column")],null)}],null)],null);
$CLJS.X(dfa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.cH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nG,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.zs],null),$CLJS.hf.h($CLJS.nG),$CLJS.dH)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VG,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.Xi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XG,$CLJS.Ss],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.aH,$CLJS.Xi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZG,$CLJS.md],null)],null));