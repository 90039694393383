var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var FZ,Dma,Ema,Fma,Gma,Hma,LZ,Ima,Jma,Kma,NZ,Lma,Mma,Nma,SZ,TZ,Oma,Pma,Qma,a_,Rma,Sma,Tma,d_,Uma,Vma,Wma,Xma;FZ=function(){};$CLJS.GZ=function(a){return null!=a?$CLJS.Bc===a.ki?!0:a.Wc?!1:$CLJS.$a(FZ,a):$CLJS.$a(FZ,a)};Dma=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.HZ=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Ema=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Fma=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.IZ=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.JZ=new $CLJS.M(null,"database-id","database-id",1883826326);Gma=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.KZ=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);Hma=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);LZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Ima=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Jma=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
Kma=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.MZ=new $CLJS.M(null,"dataset","dataset",1159262238);NZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Lma=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.OZ=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.PZ=new $CLJS.M(null,"definition","definition",-1198729982);Mma=new $CLJS.M(null,"state","state",-1988618099);
$CLJS.QZ=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Nma=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.RZ=new $CLJS.M("source","joins","source/joins",1225821486);SZ=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);TZ=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);
$CLJS.UZ=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);Oma=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.VZ=new $CLJS.M(null,"selected?","selected?",-742502788);Pma=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Qma=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.WZ=new $CLJS.M(null,"table-name","table-name",-2117866341);
$CLJS.XZ=new $CLJS.M("source","card","source/card",-139977973);$CLJS.YZ=new $CLJS.M(null,"active","active",1895962068);$CLJS.ZZ=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);$CLJS.$Z=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);a_=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Rma=new $CLJS.M(null,"details","details",1956795411);
$CLJS.b_=new $CLJS.M("source","fields","source/fields",-649667981);Sma=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.c_=new $CLJS.M("source","native","source/native",-1444604147);Tma=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);d_=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);
$CLJS.e_=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Uma=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);Vma=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);Wma=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);Xma=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.f_=new $CLJS.M(null,"source-alias","source-alias",1652088724);
$CLJS.g_=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(SZ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.zs,$CLJS.XZ,$CLJS.c_,$CLJS.IZ,$CLJS.KZ,$CLJS.b_,$CLJS.sL,$CLJS.mL,$CLJS.RZ,$CLJS.OZ,$CLJS.QZ],null));$CLJS.X(a_,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,Xma],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.eL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fL,$CLJS.qL],null)],null));
$CLJS.X(LZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,Uma],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.eL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.Ts],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[Hma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.Ts],null)],null)],null));
$CLJS.X($CLJS.dL,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ct,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.WK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.Zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CB,$CLJS.NE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.qL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.Zj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fi,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.NE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jL,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.qL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iL,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.qL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.f_,
new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.EE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DE,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.EE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YK,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.e_,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.gL],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HZ,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.EE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UZ,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,new $CLJS.h(null,2,[$CLJS.Zn,1,$CLJS.jk,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VZ,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.Ss],null),new $CLJS.P(null,3,5,$CLJS.Q,
[Oma,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Wma,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LZ],null)],null)],null)],null));
$CLJS.X(d_,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WZ,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[Lma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[Kma,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CB,$CLJS.NE],null)],null)],null)],null)],null)],null));
$CLJS.X(NZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YZ,$CLJS.Ss],null),new $CLJS.P(null,2,5,$CLJS.Q,[Mma,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WZ,$CLJS.EE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PZ,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,d_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VK,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,
$CLJS.EE],null)],null)],null));
$CLJS.X(Sma,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ct,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.rL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.gL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JZ,$CLJS.hL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZZ,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.ik],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$Z,
new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.ik],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MZ,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.Ss],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kL,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.aL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Fma,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,NZ],null)],null)],null)],null));
$CLJS.X(Pma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ct,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.$K],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.ZK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kL,$CLJS.aL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Wt,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.EE],null)],null)],null));
$CLJS.X(Qma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ct,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.bL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.XK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kL,$CLJS.aL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Wt,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.EE],null)],null)],null));
$CLJS.X(Tma,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ct,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.cL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.aL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.EE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ql,new $CLJS.h(null,
1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.EE],null)],null)],null));
$CLJS.X(Ema,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ct,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.oL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.hL],null),new $CLJS.P(null,3,5,$CLJS.Q,[Jma,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Rma,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.ik],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.g_,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.Xi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.Xi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Nma,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.Ss],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lL,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.ik],null)],null)],null));
$CLJS.X(TZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,"Valid MetadataProvider"],null),new $CLJS.Gc(function(){return $CLJS.GZ},Gma,$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[Dma,Vma,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ar],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.GZ)?$CLJS.GZ.H:null]))],null));
$CLJS.X(Ima,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ct,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TZ],null)],null)],null)],null));