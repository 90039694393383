var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.drill_thru.column_filter.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.drill_thru.distribution.js");require("./metabase.lib.drill_thru.fk_details.js");require("./metabase.lib.drill_thru.fk_filter.js");require("./metabase.lib.drill_thru.object_details.js");require("./metabase.lib.drill_thru.pivot.js");require("./metabase.lib.drill_thru.pk.js");require("./metabase.lib.drill_thru.quick_filter.js");require("./metabase.lib.drill_thru.sort.js");require("./metabase.lib.drill_thru.summarize_column.js");require("./metabase.lib.drill_thru.summarize_column_by_time.js");require("./metabase.lib.drill_thru.underlying_records.js");require("./metabase.lib.drill_thru.zoom.js");require("./metabase.lib.drill_thru.zoom_in_bins.js");require("./metabase.lib.drill_thru.zoom_in_geographic.js");require("./metabase.lib.drill_thru.zoom_in_timeseries.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var c8,e8,f8,g8,h8,j8,k8,l8,m8,Hra,n8,Ira,Jra,o8,q8,r8,s8,t8,Kra,u8,Lra,Mra,Nra,Ora,v8,Pra,Qra,Rra,w8,Sra,i8,Tra,Ura,Vra,Wra,Xra,Yra,x8,Zra,$ra,asa,bsa,csa,dsa,esa,y8,fsa,gsa,hsa,isa,jsa,ksa,lsa,msa,nsa,osa,psa,qsa,rsa,ssa,tsa,usa,z8,A8,vsa,wsa,xsa,B8,ysa,zsa,Asa,Bsa,Csa,Dsa,Esa,C8,Fsa,Gsa,Hsa,Isa;c8=function(a){var b=$CLJS.Y_(a);return $CLJS.n(b)?$CLJS.lf($CLJS.M_,$CLJS.e0(a,b)):$CLJS.Cf};
$CLJS.d8=function(a){return $CLJS.Sk.v(a,$CLJS.zL,$CLJS.be,new $CLJS.h(null,1,[$CLJS.Ej,$CLJS.eW],null))};
e8=function(a,b,c){var d=$CLJS.H2(a,$CLJS.OW(a,b)),e=$CLJS.Nk.g($CLJS.YK.h(c),$CLJS.sL)?new $CLJS.h(null,2,[$CLJS.RF,a,$CLJS.NL,b],null):$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.RF,a,$CLJS.NL,d],null):new $CLJS.h(null,2,[$CLJS.RF,$CLJS.d8(a),$CLJS.NL,-1],null),f=$CLJS.E3.g($CLJS.RF.h(e),$CLJS.NL.h(e));a=function(){var k=$CLJS.u2.v($CLJS.RF.h(e),$CLJS.NL.h(e),$CLJS.AW(c),f);if($CLJS.n(k))return k;k=$CLJS.m1.h(c);return $CLJS.n(k)?$CLJS.FH(function(l){return $CLJS.E.g($CLJS.m1.h(l),$CLJS.m1.h(c))},f):k}();
return $CLJS.R.j(e,$CLJS.Ti,a)};f8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ti);d=$CLJS.J.g(d,$CLJS.Dj);var e=$CLJS.$5(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.N_(c):c:e;return $CLJS.n(d)?(d=$CLJS.G_(c)?null:$CLJS.R.j($CLJS.A($CLJS.T2(c)),$CLJS.Ej,$CLJS.aK),$CLJS.gl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.Ej,$CLJS.XL,$CLJS.mj,$CLJS.x6,$CLJS.v6,d],null),e8(a,b,c)]))):null};
g8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ti);d=$CLJS.J.g(d,$CLJS.Dj);var e=$CLJS.$5(a,b);a=e?$CLJS.n(c)?null==d&&$CLJS.Nk.g($CLJS.YK.h(c),$CLJS.sL)&&!$CLJS.M_(c)&&!$CLJS.N_(c)&&!$CLJS.V_(c)&&!$CLJS.K_(c)&&$CLJS.Va($CLJS.y($CLJS.Z5.j(a,b,c))):c:e;return $CLJS.n(a)?new $CLJS.h(null,3,[$CLJS.Ej,$CLJS.XL,$CLJS.mj,$CLJS.U6,$CLJS.Ti,c],null):null};
h8=function(a,b,c){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.Ti);b=$CLJS.J.g(b,$CLJS.Dj);return $CLJS.n($CLJS.n(a)?null!=b&&$CLJS.Nk.g(b,$CLJS.Y2)&&!$CLJS.M_(a)&&$CLJS.L_(a):a)?new $CLJS.h(null,3,[$CLJS.Ej,$CLJS.XL,$CLJS.mj,$CLJS.B6,$CLJS.ZF,$CLJS.zW(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.es,$CLJS.N,$CLJS.AW(a),b],null))],null):null};j8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Ti),e=$CLJS.J.g(c,$CLJS.Dj);return $CLJS.n($CLJS.n(d)?null!=e:d)?i8(a,b,c,1<$CLJS.D(c8(a))):null};
k8=function(a,b,c,d){var e=$CLJS.O(c);c=$CLJS.J.g(e,$CLJS.Ti);e=$CLJS.J.g(e,$CLJS.Dj);var f=$CLJS.$5(a,b);c=f?$CLJS.n(c)?null!=e&&$CLJS.E.g($CLJS.YK.h(c),$CLJS.sL):c:f;return $CLJS.n(c)?$CLJS.lf(d,$CLJS.Y5.g(a,b)):null};
l8=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.Ti),f=$CLJS.J.g(d,$CLJS.Dj);if($CLJS.n(function(){var m=$CLJS.$5(a,b);return m?$CLJS.n(e)?null!=f&&$CLJS.E.g($CLJS.YK.h(e),$CLJS.sL)&&0<$CLJS.D($CLJS.I4.g(a,b)):e:m}())){var k=function(){var m=$CLJS.Rk.g(function(t){t=$CLJS.i0.j(a,b,t);return $CLJS.G_(t)?$CLJS.LP:$CLJS.P_(t)?$CLJS.H7:$CLJS.J_(t)?$CLJS.NG:null},$CLJS.W5.g(a,b));return $CLJS.E.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.LP],null),m)?new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.NG,null,
$CLJS.r6,null],null),null):$CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LP,$CLJS.NG],null),m)?new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.NG,null,$CLJS.r6,null],null),null):$CLJS.E.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.H7],null),m)?new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.NG,null,$CLJS.Qy,null],null),null):$CLJS.E.g($CLJS.Cf,m)?new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.NG,null,$CLJS.Qy,null,$CLJS.r6,null],null),null):$CLJS.E.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.NG],null),m)?new $CLJS.ah(null,
new $CLJS.h(null,3,[$CLJS.NG,null,$CLJS.Qy,null,$CLJS.r6,null],null),null):$CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NG,$CLJS.NG],null),m)?new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.NG,null,$CLJS.Qy,null,$CLJS.r6,null],null),null):$CLJS.bh}();c=$CLJS.n(k.h?k.h($CLJS.NG):k.call(null,$CLJS.NG))?k8(a,b,d,$CLJS.sE($CLJS.J_,$CLJS.Te($CLJS.P_))):null;var l=$CLJS.n(k.h?k.h($CLJS.r6):k.call(null,$CLJS.r6))?k8(a,b,d,$CLJS.P_):null;d=$CLJS.n(k.h?k.h($CLJS.Qy):k.call(null,$CLJS.Qy))?k8(a,b,d,$CLJS.G_):
null;c=$CLJS.gl.l($CLJS.H([$CLJS.y(c)?new $CLJS.h(null,1,[$CLJS.NG,c],null):null,$CLJS.y(l)?new $CLJS.h(null,1,[$CLJS.r6,l],null):null,$CLJS.y(d)?new $CLJS.h(null,1,[$CLJS.Qy,d],null):null]));return $CLJS.td(c)?null:new $CLJS.h(null,3,[$CLJS.Ej,$CLJS.XL,$CLJS.mj,$CLJS.c7,$CLJS.n6,c],null)}return null};m8=function(a,b){return $CLJS.zW($CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.N],null),b))};
Hra=function(a,b){var c=$CLJS.AW(a);if($CLJS.N_(a))return $CLJS.Cf;if($CLJS.E.g(b,$CLJS.Y2))return new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.T,"\x3d",$CLJS.ZF,m8($CLJS.WF,$CLJS.H([c]))],null),new $CLJS.h(null,2,[$CLJS.T,"≠",$CLJS.ZF,m8($CLJS.gG,$CLJS.H([c]))],null)],null);if($CLJS.H_(a)||$CLJS.G_(a))return a=function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,
u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);x=new $CLJS.h(null,2,[$CLJS.T,v,$CLJS.ZF,m8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}l=$CLJS.A(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.ee(new $CLJS.h(null,2,[$CLJS.T,l,$CLJS.ZF,m8(t,$CLJS.H([c,b]))],null),f($CLJS.Lc(k)))}return null}},null,null)},a(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,"\x3c"],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.Yr,"\x3e"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LG,"≠"],null)],null));a=function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);x=new $CLJS.h(null,2,[$CLJS.T,v,$CLJS.ZF,m8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),
f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}l=$CLJS.A(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.ee(new $CLJS.h(null,2,[$CLJS.T,l,$CLJS.ZF,m8(t,$CLJS.H([c,b]))],null),f($CLJS.Lc(k)))}return null}},null,null)};return a(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LG,"≠"],null)],null))};
n8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ti);d=$CLJS.J.g(d,$CLJS.Dj);var e=(e=$CLJS.$5(a,b))?$CLJS.n(c)?null!=d&&!$CLJS.M_(c)&&!$CLJS.L_(c):c:e;return $CLJS.n(e)?(a=e8(a,b,c),$CLJS.gl.l($CLJS.H([new $CLJS.h(null,4,[$CLJS.Ej,$CLJS.XL,$CLJS.mj,$CLJS.g6,$CLJS.LL,Hra($CLJS.Ti.h(a),d),$CLJS.Dj,d],null),a]))):null};Ira=function(a,b,c){return $CLJS.u2.v(a,b,$CLJS.AW(c),$CLJS.N7.g(a,b))};
Jra=function(a,b,c){a=$CLJS.P7(a,b,c);return $CLJS.n(a)?(b=$CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),b):null};
o8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Ti),e=$CLJS.J.g(c,$CLJS.Dj);if($CLJS.n(function(){var k=$CLJS.$5(a,b);return k?$CLJS.n(d)?null==e&&!$CLJS.N_(d):d:k}())&&$CLJS.n(Ira(a,b,d))){var f=Jra(a,b,d);return new $CLJS.h(null,4,[$CLJS.Ej,$CLJS.XL,$CLJS.mj,$CLJS.H6,$CLJS.Ti,d,$CLJS.K6,function(){switch(f instanceof $CLJS.M?f.T:null){case "asc":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kG],null);case "desc":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rG],null);default:return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.rG,$CLJS.kG],null)}}()],null)}return null};
q8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ti);d=$CLJS.J.g(d,$CLJS.Dj);var e=$CLJS.$5(a,b);a=e?$CLJS.n(c)?null==d&&!$CLJS.N_(c)&&$CLJS.Nk.g($CLJS.YK.h(c),$CLJS.sL)&&$CLJS.Va($CLJS.y($CLJS.Z5.j(a,b,c))):c:e;return $CLJS.n(a)?(a=$CLJS.gf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.$F],null),$CLJS.I_(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HG,$CLJS.oG],null):null),new $CLJS.h(null,4,[$CLJS.Ej,$CLJS.XL,$CLJS.mj,$CLJS.I6,$CLJS.Ti,c,$CLJS.M6,a],null)):null};
r8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ti);d=$CLJS.J.g(d,$CLJS.Dj);var e=$CLJS.$5(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.N_(c)&&$CLJS.I_(c)&&$CLJS.Nk.g($CLJS.YK.h(c),$CLJS.sL):c:e;if($CLJS.n(d)&&(d=$CLJS.FH($CLJS.G_,$CLJS.Y5.g(a,b)),$CLJS.n(d)&&(e=$CLJS.FH($CLJS.di,$CLJS.g1.j(a,b,d)),$CLJS.n(e)))){var f=$CLJS.H3(d,e);return $CLJS.n($CLJS.TV($CLJS.hf.g($CLJS.AW,$CLJS.ee(f,$CLJS.W5.g(a,b)))))?new $CLJS.h(null,5,[$CLJS.Ej,$CLJS.XL,$CLJS.mj,$CLJS.$6,$CLJS.Ti,c,$CLJS.iG,d,$CLJS.si,$CLJS.d1.h(e)],
null):null}return null};
s8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Ti),e=$CLJS.J.g(c,$CLJS.i6),f=$CLJS.J.g(c,$CLJS.QL),k=$CLJS.J.g(c,$CLJS.Dj);return $CLJS.n(function(){var l=$CLJS.$5(a,b);return l?$CLJS.n(d)?(l=null!=k)?(l=$CLJS.Fe(f),$CLJS.n(l)?!$CLJS.N_(d):l):l:d:l}())?new $CLJS.h(null,6,[$CLJS.Ej,$CLJS.XL,$CLJS.mj,$CLJS.W6,$CLJS.R6,"number"===typeof k?k:2,$CLJS.WZ,function(){var l=$CLJS.Y_(a);l=null==l?null:$CLJS.d0(a,l);return null==l?null:$CLJS.j0.j(a,b,l)}(),$CLJS.QL,f,$CLJS.i6,e],null):null};
t8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ti);d=$CLJS.J.g(d,$CLJS.Dj);if($CLJS.n($CLJS.n(c)?d:c))if(a=$CLJS.A($CLJS.Z5.j(a,b,c)),$CLJS.n(a))if(a=$CLJS.n7(a),$CLJS.n(a))if(b=$CLJS.S7(c,d),$CLJS.n(b)){var e=$CLJS.O(b);b=$CLJS.J.g(e,$CLJS.PL);var f=$CLJS.J.g(e,$CLJS.UL);e=$CLJS.J.g(e,$CLJS.OE);var k=$CLJS.PE.h(a);k=k instanceof $CLJS.M?k.T:null;switch(k){case "num-bins":return new $CLJS.h(null,6,[$CLJS.Ej,$CLJS.XL,$CLJS.mj,$CLJS.L6,$CLJS.Ti,c,$CLJS.PL,d,$CLJS.UL,d+e,$CLJS.E6,new $CLJS.h(null,
1,[$CLJS.PE,$CLJS.di],null)],null);case "bin-width":return new $CLJS.h(null,6,[$CLJS.Ej,$CLJS.XL,$CLJS.mj,$CLJS.L6,$CLJS.Ti,c,$CLJS.PL,b,$CLJS.UL,f,$CLJS.E6,$CLJS.Sk.j(a,$CLJS.OE,function(l){return l/10})],null);case "default":return null;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}else return null;else return null;else return null;else return null};
Kra=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.h6);b=$CLJS.U0.j(a,b,$CLJS.PW(a,b));a=$CLJS.FH($CLJS.T_,b);if($CLJS.n(a)&&(b=$CLJS.FH($CLJS.U_,b),$CLJS.n(b))){var e=function(f){return $CLJS.Re(function(k){var l=$CLJS.Ti.h(k);l=$CLJS.n($CLJS.Zi.h(f))?$CLJS.E.g($CLJS.Zi.h(f),$CLJS.Zi.h(l)):$CLJS.E.g($CLJS.UZ.h(f),$CLJS.UZ.h(l));return $CLJS.n(l)?$CLJS.Dj.h(k):null},d)};return $CLJS.R.l(c,$CLJS.Y7,a,$CLJS.H([$CLJS.Z7,b,$CLJS.$7,e(a),$CLJS.X7,e(b)]))}return null};
u8=function(a,b){var c=$CLJS.O(a);a=$CLJS.J.g(c,$CLJS.Ti);var d=$CLJS.J.g(c,$CLJS.Dj),e=$CLJS.J.g(c,$CLJS.Y7);c=$CLJS.J.g(c,$CLJS.Z7);return $CLJS.n(d)?new $CLJS.h(null,7,[$CLJS.Ej,$CLJS.XL,$CLJS.mj,$CLJS.g7,$CLJS.m6,$CLJS.z6,$CLJS.Ti,a,$CLJS.Dj,d,$CLJS.C6,new $CLJS.h(null,2,[$CLJS.Ti,e,$CLJS.OE,b],null),$CLJS.A6,new $CLJS.h(null,2,[$CLJS.Ti,c,$CLJS.OE,b],null)],null):null};Lra=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Ti);return $CLJS.n(null==b?null:$CLJS.S_(b))?u8(a,10):null};
Mra=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Ti);return $CLJS.n(null==b?null:$CLJS.R_(b))?u8(a,1):null};Nra=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Ti);return $CLJS.n(null==b?null:$CLJS.Q_(b))?u8(a,.1):null};
Ora=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.Y7);var c=$CLJS.J.g(b,$CLJS.Z7),d=$CLJS.J.g(b,$CLJS.$7);b=$CLJS.J.g(b,$CLJS.X7);if($CLJS.n($CLJS.n(d)?b:d)){var e=$CLJS.OE.h($CLJS.n7(a));if($CLJS.n(e)){var f=$CLJS.OE.h($CLJS.n7(c));if($CLJS.n(f)){var k=20<=e&&20<=f?new $CLJS.P(null,2,5,$CLJS.Q,[10,10],null):new $CLJS.P(null,2,5,$CLJS.Q,[e/10,f/10],null),l=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return new $CLJS.h(null,5,[$CLJS.Ej,$CLJS.XL,$CLJS.mj,$CLJS.g7,$CLJS.m6,$CLJS.e6,$CLJS.C6,new $CLJS.h(null,
4,[$CLJS.Ti,a,$CLJS.OE,l,$CLJS.Zn,d,$CLJS.jk,d+e],null),$CLJS.A6,new $CLJS.h(null,4,[$CLJS.Ti,c,$CLJS.OE,k,$CLJS.Zn,b,$CLJS.jk,b+f],null)],null)}}}return null};v8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Dj);if($CLJS.n(d)){var e=Kra(a,b,c);return $CLJS.n(e)?$CLJS.Re(function(f){return f.h?f.h(e):f.call(null,e)},new $CLJS.P(null,4,5,$CLJS.Q,[Lra,Mra,Nra,Ora],null)):null}return null};
Pra=function(a,b,c){return $CLJS.A(function(){return function f(e){return new $CLJS.ne(null,function(){for(var k=e;;){var l=$CLJS.y(k);if(l){var m=l,t=$CLJS.A(m);if($CLJS.n(function(){var u=$CLJS.RY(t,$CLJS.uG);return u?$CLJS.P2(t):u}())&&(l=$CLJS.y(function(u,v,x,z){return function K(G){return new $CLJS.ne(null,function(S,V){return function(){for(var Z=G;;)if(Z=$CLJS.y(Z)){if($CLJS.Ad(Z)){var ha=$CLJS.lc(Z),ra=$CLJS.D(ha),Ma=$CLJS.qe(ra);return function(){for(var eb=0;;)if(eb<ra){var Sa=$CLJS.kd(ha,
eb),mb=$CLJS.O(Sa);Sa=mb;mb=$CLJS.J.g(mb,$CLJS.Ti);var Ia=$CLJS.u2.g(V,new $CLJS.P(null,1,5,$CLJS.Q,[mb],null));mb=$CLJS.n(Ia)?$CLJS.E.g($CLJS.P2(V),$CLJS.P2(mb)):Ia;$CLJS.n(mb)&&(Sa=$CLJS.R.j(Sa,$CLJS.i6,V),Ma.add(Sa));eb+=1}else return!0}()?$CLJS.te($CLJS.ve(Ma),K($CLJS.mc(Z))):$CLJS.te($CLJS.ve(Ma),null)}var zb=$CLJS.A(Z),Oa=zb=$CLJS.O(zb),Ya=$CLJS.J.g(zb,$CLJS.Ti);if($CLJS.n(function(){var eb=$CLJS.u2.g(V,new $CLJS.P(null,1,5,$CLJS.Q,[Ya],null));return $CLJS.n(eb)?$CLJS.E.g($CLJS.P2(V),$CLJS.P2(Ya)):
eb}()))return $CLJS.ee($CLJS.R.j(Oa,$CLJS.i6,V),K($CLJS.Lc(Z)));Z=$CLJS.Lc(Z)}else return null}}(u,v,x,z),null,null)}}(k,t,m,l)(c))))return $CLJS.gf.g(l,f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null}},null,null)}($CLJS.W5.g(a,b))}())};Qra=function(a){a=$CLJS.d1.h(a);return $CLJS.n(a)?$CLJS.Hd($CLJS.fh($CLJS.a8),a)?$CLJS.b8.h?$CLJS.b8.h(a):$CLJS.b8.call(null,a):null:null};
Rra=function(a){a=a instanceof $CLJS.M?a.T:null;switch(a){case "quarter":return $CLJS.WE("See this year by quarter");case "month":return $CLJS.WE("See this quarter by month");case "week":return $CLJS.WE("See this month by week");case "day":return $CLJS.WE("See this week by day");case "hour":return $CLJS.WE("See this day by hour");case "minute":return $CLJS.WE("See this hour by minute");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}};
w8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Ti),e=$CLJS.J.g(c,$CLJS.QL),f=$CLJS.J.g(c,$CLJS.Dj);if($CLJS.n(function(){var l=$CLJS.$5(a,b);return l?$CLJS.n(d)?(l=$CLJS.Fe(e),$CLJS.n(l)?null!=f:l):d:l}())&&(c=Pra(a,b,e),$CLJS.n(c))){var k=Qra($CLJS.Ti.h(c));return $CLJS.n(k)?new $CLJS.h(null,5,[$CLJS.Ej,$CLJS.XL,$CLJS.zE,Rra(k),$CLJS.mj,$CLJS.e7,$CLJS.OF,c,$CLJS.Z6,k],null):null}return null};
Sra=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.QL);return $CLJS.Fe(function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.gl.l($CLJS.H([b,v]));t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.A(k);return $CLJS.ee($CLJS.gl.l($CLJS.H([b,t])),f($CLJS.Lc(k)))}return null}},null,
null)}(c)}())};
i8=function i8(a,b,c,d){c=$CLJS.O(c);var f=$CLJS.J.g(c,$CLJS.Ti),k=$CLJS.J.g(c,$CLJS.h6),l=$CLJS.J.g(c,$CLJS.Dj);l=new $CLJS.h(null,5,[$CLJS.Ej,$CLJS.XL,$CLJS.mj,$CLJS.SL,$CLJS.Ti,f,$CLJS.JL,l,$CLJS.IL,d],null);var m=$CLJS.$5(a,b);if($CLJS.n(function(){var u=$CLJS.M_(f);return u?$CLJS.n(d)?m:d:u}()))return $CLJS.R.j(l,$CLJS.mj,$CLJS.SL);if($CLJS.M_(f))return $CLJS.R.j(l,$CLJS.mj,$CLJS.k7);if($CLJS.L_(f))return $CLJS.R.j(l,$CLJS.mj,$CLJS.TL);if($CLJS.n(function(){var u=$CLJS.Va(d);return u?(u=$CLJS.Fe(k),
$CLJS.n(u)?$CLJS.td($CLJS.I4.g(a,b)):u):u}())){l=c8(a);var t=$CLJS.I(l,0,null);l=$CLJS.Dj.h($CLJS.FH(function(u){return $CLJS.E.g($CLJS.T.h($CLJS.Ti.h(u)),$CLJS.T.h(t))},k));if($CLJS.n($CLJS.n(l)?$CLJS.Nk.g($CLJS.T.h(f),$CLJS.T.h(t)):l))return c=$CLJS.R.l(c,$CLJS.Ti,t,$CLJS.H([$CLJS.Dj,l])),i8.v?i8.v(a,b,c,d):i8.call(null,a,b,c,d)}return null};Tra=new $CLJS.r(null,"_query","_query",1604043437,null);Ura=new $CLJS.r(null,"metabase.lib.drill-thru.pivot","metabase.lib.drill-thru.pivot",109208756,null);
Vra=new $CLJS.r(null,"_stage-number","_stage-number",1726062348,null);Wra=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-bins","metabase.lib.drill-thru.zoom-in-bins",477390405,null);Xra=new $CLJS.r("metabase.lib.drill-thru.zoom-in-bins","zoom-in-binning-drill","metabase.lib.drill-thru.zoom-in-bins/zoom-in-binning-drill",-862934837,null);Yra=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column-by-time","metabase.lib.drill-thru.summarize-column-by-time",-369504986,null);
x8=new $CLJS.r(null,"stage-number","stage-number",-112198111,null);Zra=new $CLJS.r(null,"underlying-records-drill","underlying-records-drill",713740052,null);$ra=new $CLJS.r("metabase.lib.drill-thru.zoom-in-timeseries","zoom-in-timeseries-drill","metabase.lib.drill-thru.zoom-in-timeseries/zoom-in-timeseries-drill",313862423,null);asa=new $CLJS.r(null,"zoom-in-timeseries-drill","zoom-in-timeseries-drill",-1304664195,null);bsa=new $CLJS.r(null,"distribution-drill","distribution-drill",-898205041,null);
csa=new $CLJS.r(null,"sort-drill","sort-drill",600387798,null);dsa=new $CLJS.M(null,"drill","drill",-1704624793);esa=new $CLJS.r("metabase.lib.drill-thru.quick-filter","quick-filter-drill","metabase.lib.drill-thru.quick-filter/quick-filter-drill",457872114,null);y8=new $CLJS.r(null,"_context","_context",-1238791322,null);fsa=new $CLJS.r(null,"metabase.lib.drill-thru.distribution","metabase.lib.drill-thru.distribution",-514507852,null);
gsa=new $CLJS.r(null,"zoom-in-binning-drill","zoom-in-binning-drill",1550598377,null);hsa=new $CLJS.r(null,"metabase.lib.drill-thru.object-details","metabase.lib.drill-thru.object-details",1788168194,null);isa=new $CLJS.r("metabase.lib.drill-thru.zoom-in-geographic","zoom-in-geographic-drill","metabase.lib.drill-thru.zoom-in-geographic/zoom-in-geographic-drill",754461586,null);
jsa=new $CLJS.r("metabase.lib.drill-thru.distribution","distribution-drill","metabase.lib.drill-thru.distribution/distribution-drill",-2103693273,null);ksa=new $CLJS.r(null,"fk-filter-drill","fk-filter-drill",-1258190784,null);lsa=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column","metabase.lib.drill-thru.summarize-column",490384430,null);msa=new $CLJS.r(null,"pivot-drill","pivot-drill",-1794856768,null);
nsa=new $CLJS.r(null,"metabase.lib.drill-thru.quick-filter","metabase.lib.drill-thru.quick-filter",671398986,null);osa=new $CLJS.r(null,"metabase.lib.drill-thru.underlying-records","metabase.lib.drill-thru.underlying-records",-827008688,null);psa=new $CLJS.r("metabase.lib.drill-thru.summarize-column-by-time","summarize-column-by-time-drill","metabase.lib.drill-thru.summarize-column-by-time/summarize-column-by-time-drill",391970943,null);
qsa=new $CLJS.r(null,"summarize-column-drill","summarize-column-drill",-1961542613,null);rsa=new $CLJS.r(null,"column-filter-drill","column-filter-drill",-1088669153,null);ssa=new $CLJS.r("metabase.lib.drill-thru.underlying-records","underlying-records-drill","metabase.lib.drill-thru.underlying-records/underlying-records-drill",1176519262,null);tsa=new $CLJS.r(null,"metabase.lib.drill-thru.sort","metabase.lib.drill-thru.sort",961185366,null);
usa=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-geographic","metabase.lib.drill-thru.zoom-in-geographic",-1562146002,null);z8=new $CLJS.r(null,"dimensions","dimensions",1385713430,null);A8=new $CLJS.M(null,"return-drills-for-dimensions?","return-drills-for-dimensions?",-1759804348);vsa=new $CLJS.r("metabase.lib.drill-thru.column-filter","column-filter-drill","metabase.lib.drill-thru.column-filter/column-filter-drill",-1130871894,null);
wsa=new $CLJS.r(null,"object-detail-drill","object-detail-drill",-613919053,null);xsa=new $CLJS.r(null,"metabase.lib.drill-thru.column-filter","metabase.lib.drill-thru.column-filter",23504270,null);B8=new $CLJS.r(null,"context","context",810340414,null);ysa=new $CLJS.r(null,"zoom-in-geographic-drill","zoom-in-geographic-drill",312914971,null);zsa=new $CLJS.r(null,"summarize-column-by-time-drill","summarize-column-by-time-drill",1117784387,null);
Asa=new $CLJS.r("metabase.lib.drill-thru.object-details","object-detail-drill","metabase.lib.drill-thru.object-details/object-detail-drill",-1376576725,null);Bsa=new $CLJS.r("metabase.lib.drill-thru.pivot","pivot-drill","metabase.lib.drill-thru.pivot/pivot-drill",1004065694,null);Csa=new $CLJS.r(null,"column-ref","column-ref",-636247393,null);Dsa=new $CLJS.r(null,"metabase.lib.drill-thru.fk-filter","metabase.lib.drill-thru.fk-filter",-1939155669,null);
Esa=new $CLJS.r("metabase.lib.drill-thru.summarize-column","summarize-column-drill","metabase.lib.drill-thru.summarize-column/summarize-column-drill",-894275347,null);C8=new $CLJS.r(null,"query","query",352022017,null);Fsa=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-timeseries","metabase.lib.drill-thru.zoom-in-timeseries",105846904,null);Gsa=new $CLJS.r("metabase.lib.drill-thru.fk-filter","fk-filter-drill","metabase.lib.drill-thru.fk-filter/fk-filter-drill",-716335012,null);
Hsa=new $CLJS.r("metabase.lib.drill-thru.sort","sort-drill","metabase.lib.drill-thru.sort/sort-drill",2019212116,null);Isa=new $CLJS.r(null,"quick-filter-drill","quick-filter-drill",534264366,null);var Jsa;$CLJS.Q0.m(null,$CLJS.XL,function(a,b,c){return $CLJS.c6.j(a,b,c)});
Jsa=new $CLJS.P(null,13,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.nt,new $CLJS.Gc(function(){return g8},jsa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,$CLJS.VL,$CLJS.Os,$CLJS.q6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.l6],null)],null),fsa,bsa,"metabase/lib/drill_thru/distribution.cljc",28,1,16,16,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,new $CLJS.h(null,
1,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jA,$CLJS.Vi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.distribution]\n          \n\n  Select a column and see a histogram of how many rows fall into an automatic set of bins/buckets.\n  - For dates, breaks out by month by default.\n  - For numeric values, by an auto-selected set of bins\n  - For strings, by each distinct value (which might be \x3d the number of rows)",
$CLJS.n(g8)?g8.H:null])),A8,!0],null),new $CLJS.h(null,2,[$CLJS.nt,new $CLJS.Gc(function(){return f8},vsa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,$CLJS.VL,$CLJS.Os,$CLJS.q6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.t6],null)],null),xsa,rsa,"metabase/lib/drill_thru/column_filter.cljc",29,1,56,56,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,new $CLJS.h(null,
1,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jA,$CLJS.Vi],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.column-filter]\n          \n\n  Filtering at the column level, based on its type. Displays a submenu of eg. "Today", "This Week", etc. for date\n  columns.\n\n  Note that if the clicked column is an aggregation, filtering by it will require a new stage. Therefore this drill\n  returns a possibly-updated `:query` and `:stage-number` along with a `:column` referencing that later stage.',
$CLJS.n(f8)?f8.H:null])),A8,!0],null),new $CLJS.h(null,2,[$CLJS.nt,new $CLJS.Gc(function(){return h8},Gsa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,$CLJS.VL,$CLJS.Os,$CLJS.q6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.X6],null)],null),Dsa,ksa,"metabase/lib/drill_thru/fk_filter.cljc",25,1,13,13,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[Tra,Vra,new $CLJS.h(null,
2,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jA,$CLJS.Vi],null),$CLJS.tt,y8],null)],null)),"Inputs: [_query :- :metabase.lib.schema/query _stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.fk-filter]\n          \n\n  When clicking on a foreign key value, filter this query by that column.\n\n  This has the same effect as the `\x3d` filter on a generic field (ie. not a key), but renders differently.\n\n  Contrast [[metabase.lib.drill-thru.object-details/object-detail-drill]], which shows the details of the foreign\n  object.",
$CLJS.n(h8)?h8.H:null])),A8,!1],null),new $CLJS.h(null,2,[$CLJS.nt,new $CLJS.Gc(function(){return j8},Asa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,$CLJS.VL,$CLJS.Os,$CLJS.q6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,$CLJS.f6,$CLJS.Y6,$CLJS.O6],null)],null)],null),hsa,wsa,"metabase/lib/drill_thru/object_details.cljc",
29,1,47,47,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,new $CLJS.h(null,2,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jA,$CLJS.Vi],null),$CLJS.tt,B8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe\n           [:or\n            :metabase.lib.schema.drill-thru/drill-thru.pk\n            :metabase.lib.schema.drill-thru/drill-thru.zoom\n            :metabase.lib.schema.drill-thru/drill-thru.fk-details]]\n          \n\n  When clicking a foreign key or primary key value, drill through to the details for that specific object.\n\n  Contrast [[metabase.lib.drill-thru.fk-filter/fk-filter-drill]], which filters this query to only those rows with a\n  specific value for a FK column.",
$CLJS.n(j8)?j8.H:null])),A8,!1],null),new $CLJS.h(null,2,[$CLJS.nt,new $CLJS.Gc(function(){return l8},Bsa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,$CLJS.VL,$CLJS.Os,$CLJS.q6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.o6],null)],null),Ura,msa,"metabase/lib/drill_thru/pivot.cljc",21,1,58,58,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,new $CLJS.h(null,
2,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jA,$CLJS.Vi],null),$CLJS.tt,B8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.pivot]\n          \n\n  Return all possible pivoting options on the given column and value.\n\n  See `:pivots` key, which holds a map `{t [breakouts...]}` where `t` is `:category`, `:location`, or `:time`.\n  If a key is missing, there are no breakouts of that kind.",
$CLJS.n(l8)?l8.H:null])),A8,!1],null),new $CLJS.h(null,2,[$CLJS.nt,new $CLJS.Gc(function(){return n8},esa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,$CLJS.VL,$CLJS.Os,$CLJS.q6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.P6],null)],null),nsa,Isa,"metabase/lib/drill_thru/quick_filter.cljc",28,1,39,39,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,new $CLJS.h(null,
1,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jA,$CLJS.Vi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.quick-filter]\n          \n\n  Filter the current query based on the value clicked.\n\n  The options vary depending on the type of the field:\n  - `:is-null` and `:not-null` for a `NULL` value;\n  - `:\x3d` and `:!\x3d` for everything else;\n  - plus `:\x3c` and `:\x3e` for numeric and date columns.\n\n  Note that this returns a single `::drill-thru` value with 1 or more `:operators`; these are rendered as a set of small\n  buttons in a single row of the drop-down.",
$CLJS.n(n8)?n8.H:null])),A8,!1],null),new $CLJS.h(null,2,[$CLJS.nt,new $CLJS.Gc(function(){return o8},Hsa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,$CLJS.VL,$CLJS.Os,$CLJS.q6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.h7],null)],null),tsa,csa,"metabase/lib/drill_thru/sort.cljc",20,1,34,34,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,new $CLJS.h(null,
2,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jA,$CLJS.Vi],null),$CLJS.tt,y8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.sort]\n          \n\n  Sorting on a clicked column.",$CLJS.n(o8)?o8.H:null])),A8,!0],null),new $CLJS.h(null,2,[$CLJS.nt,new $CLJS.Gc(function(){return q8},Esa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,
$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,$CLJS.VL,$CLJS.Os,$CLJS.q6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.w6],null)],null),lsa,qsa,"metabase/lib/drill_thru/summarize_column.cljc",32,1,11,11,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,new $CLJS.h(null,1,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jA,$CLJS.Vi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column]\n          \n\n  A set of possible aggregations that can summarize this column: distinct values, sum, average.\n  Separate from [[summarize-column-by-time-drill]] which breaks out a column over time.",
$CLJS.n(q8)?q8.H:null])),A8,!0],null),new $CLJS.h(null,2,[$CLJS.nt,new $CLJS.Gc(function(){return r8},psa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,$CLJS.VL,$CLJS.Os,$CLJS.q6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.u6],null)],null),Yra,zsa,"metabase/lib/drill_thru/summarize_column_by_time.cljc",40,1,15,15,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,
[C8,x8,new $CLJS.h(null,1,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jA,$CLJS.Vi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time]\n          \n\n  A breakout summarizing a column over time.\n  Separate from single-value [[summarize-column-drill]] for sum, average, and distinct value count.",$CLJS.n(r8)?
r8.H:null])),A8,!0],null),new $CLJS.h(null,2,[$CLJS.nt,new $CLJS.Gc(function(){return s8},ssa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,$CLJS.VL,$CLJS.Os,$CLJS.q6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.f7],null)],null),osa,Zra,"metabase/lib/drill_thru/underlying_records.cljc",34,1,19,19,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,new $CLJS.h(null,
1,[$CLJS.op,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jA,Csa,z8,$CLJS.Vi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.underlying-records]\n          \n\n  When clicking on a particular broken-out group, offer a look at the details of all the rows that went into this\n  bucket. Eg. distribution of People by State, then click New York and see the table of all People filtered by\n  `STATE \x3d 'New York'`.",
$CLJS.n(s8)?s8.H:null])),A8,!1],null),new $CLJS.h(null,2,[$CLJS.nt,new $CLJS.Gc(function(){return w8},$ra,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,$CLJS.VL,$CLJS.Os,$CLJS.q6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.a7],null)],null),Fsa,asa,"metabase/lib/drill_thru/zoom_in_timeseries.cljc",34,1,56,56,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,
new $CLJS.h(null,1,[$CLJS.op,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jA,z8,$CLJS.Vi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column dimensions value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries]\n          \n\n  Zooms in on some window, showing it in finer detail.\n\n  For example: The month of a year, days or weeks of a quarter, smaller lat/long regions, etc.\n\n  This is different from the `:drill-thru/zoom` type, which is for showing the details of a single object.",
$CLJS.n(w8)?w8.H:null])),A8,!1],null),new $CLJS.h(null,2,[$CLJS.nt,new $CLJS.Gc(function(){return v8},isa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,$CLJS.VL,$CLJS.Os,$CLJS.q6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.b7],null)],null),usa,ysa,"metabase/lib/drill_thru/zoom_in_geographic.cljc",34,1,135,135,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,
x8,new $CLJS.h(null,2,[$CLJS.op,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Vi],null),$CLJS.tt,B8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.geographic]\n          \n\n  Return a `:drill-thru/zoom-in.geographic` drill if appropriate. See docstring\n  for [[metabase.lib.drill-thru.zoom-in-geographic]] for more information on what circumstances this is returned in\n  and what it means to apply this drill.",
$CLJS.n(v8)?v8.H:null])),A8,!1],null),new $CLJS.h(null,2,[$CLJS.nt,new $CLJS.Gc(function(){return t8},Xra,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,$CLJS.VL,$CLJS.Os,$CLJS.q6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.d7],null)],null),Wra,gsa,"metabase/lib/drill_thru/zoom_in_bins.cljc",31,1,54,54,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,new $CLJS.h(null,
2,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jA,$CLJS.Vi],null),$CLJS.tt,y8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.binning]\n          \n\n  Return a drill thru that 'zooms in' on a breakout that uses `:binning` if applicable.\n  See [[metabase.lib.drill-thru.zoom-in-bins]] docstring for more information.",
$CLJS.n(t8)?t8.H:null])),A8,!1],null)],null);
$CLJS.Ksa=function(){function a(d,e,f){try{var k=Sra(f);return $CLJS.eg.g($CLJS.Cf,function(){return function u(t){return new $CLJS.ne(null,function(){for(var v=t;;){var x=$CLJS.y(v);if(x){var z=x,C=$CLJS.A(z),G=$CLJS.O(C),K=$CLJS.J.g(G,$CLJS.nt),S=$CLJS.J.g(G,A8);if(x=$CLJS.y(function(V,Z,ha,ra,Ma,zb,Oa,Ya){return function mb(Sa){return new $CLJS.ne(null,function(Ia,bc,Zd,Id){return function(){for(var qd=Sa;;)if(qd=$CLJS.y(qd)){if($CLJS.Ad(qd)){var nl=$CLJS.lc(qd),p8=$CLJS.D(nl),kp=$CLJS.qe(p8);
a:for(var iQ=0;;)if(iQ<p8){var rB=$CLJS.kd(nl,iQ);rB=Id.j?Id.j(d,e,rB):Id.call(null,d,e,rB);$CLJS.n(rB)&&kp.add(rB);iQ+=1}else{nl=!0;break a}return nl?$CLJS.te($CLJS.ve(kp),mb($CLJS.mc(qd))):$CLJS.te($CLJS.ve(kp),null)}kp=$CLJS.A(qd);kp=Id.j?Id.j(d,e,kp):Id.call(null,d,e,kp);if($CLJS.n(kp))return $CLJS.ee(kp,mb($CLJS.Lc(qd)));qd=$CLJS.Lc(qd)}else return null}}(V,Z,ha,ra,Ma,zb,Oa,Ya),null,null)}}(v,C,G,K,S,z,x,k)($CLJS.n(function(){var V=S;return $CLJS.n(V)?k:V}())?k:new $CLJS.P(null,1,5,$CLJS.Q,[f],
null))))return $CLJS.gf.g(x,u($CLJS.Lc(v)));v=$CLJS.Lc(v)}else return null}},null,null)}(Jsa)}())}catch(m){var l=m;throw $CLJS.hi(["Error getting available drill thrus for query: ",$CLJS.p.h($CLJS.CV(l))].join(""),new $CLJS.h(null,3,[$CLJS.RF,d,$CLJS.NL,e,$CLJS.zN,f],null),l);}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();
$CLJS.Lsa=function(){function a(d,e){return b.j?b.j(d,-1,e):b.call(null,d,-1,e)}var b=null,c=function(){function d(f,k,l,m){var t=null;if(3<arguments.length){t=0;for(var u=Array(arguments.length-3);t<u.length;)u[t]=arguments[t+3],++t;t=new $CLJS.w(u,0,null)}return e.call(this,f,k,l,t)}function e(f,k,l,m){var t=$CLJS.UA($CLJS.Ry);$CLJS.n($CLJS.TA("metabase.lib.drill-thru",t))&&$CLJS.SA("metabase.lib.drill-thru",t,$CLJS.EW("Applying drill thru: %s",$CLJS.H([$CLJS.FW(new $CLJS.h(null,4,[$CLJS.RF,f,$CLJS.NL,
k,dsa,l,$CLJS.ht,m],null))])),null);return $CLJS.Se.N($CLJS.b6,f,k,l,m)}d.A=3;d.B=function(f){var k=$CLJS.A(f);f=$CLJS.B(f);var l=$CLJS.A(f);f=$CLJS.B(f);var m=$CLJS.A(f);f=$CLJS.Lc(f);return e(k,l,m,f)};d.l=e;return d}();b=function(d,e,f,k){switch(arguments.length){case 2:return a.call(this,d,e);default:var l=null;if(3<arguments.length){l=0;for(var m=Array(arguments.length-3);l<m.length;)m[l]=arguments[l+3],++l;l=new $CLJS.w(m,0,null)}return c.l(d,e,f,l)}throw Error("Invalid arity: "+arguments.length);
};b.A=3;b.B=c.B;b.g=a;b.l=c.l;return b}();