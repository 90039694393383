var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var OY,Rla,QY,Sla,Tla,Ula,SY,Wla,Xla,Yla,Zla,VY,WY,XY,YY,$Y,ama,aZ,bZ,bma,cma,dma,UY,Vla,cZ,dZ,$la,fZ;OY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.bf(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
Rla=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.j?$CLJS.mj.j(k,l,m):$CLJS.mj.call(null,k,l,m),$CLJS.ml.j?$CLJS.ml.j(k,l,m):$CLJS.ml.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.g?$CLJS.mj.g(k,l):$CLJS.mj.call(null,k,l),$CLJS.ml.g?$CLJS.ml.g(k,l):$CLJS.ml.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.h?$CLJS.mj.h(k):$CLJS.mj.call(null,k),$CLJS.ml.h?$CLJS.ml.h(k):$CLJS.ml.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.o?$CLJS.mj.o():$CLJS.mj.call(null),$CLJS.ml.o?$CLJS.ml.o():$CLJS.ml.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.w(z,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Se.N($CLJS.mj,m,t,u,v),$CLJS.Se.N($CLJS.ml,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.A(m);m=$CLJS.B(m);var u=$CLJS.A(m);m=$CLJS.B(m);var v=$CLJS.A(m);m=$CLJS.Lc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};
$CLJS.PY=function(a){return function(b){var c=$CLJS.$e(-1);return function(){function d(l,m){var t=c.Dd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
QY=function(a){return $CLJS.$L($CLJS.vW)(a,$CLJS.Cf,$CLJS.Cf)};Sla=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.jG,b),$CLJS.st(2,2,c))};Tla=function(a,b){var c=$CLJS.fm(a,$CLJS.QQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Fb(c);b=$CLJS.Mt.g?$CLJS.Mt.g(c,b):$CLJS.Mt.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.QQ,b)}return a};Ula=function(a){return $CLJS.gf.g($CLJS.WV(a),$CLJS.XV(a))};
$CLJS.RY=function(a,b){return $CLJS.HW(a)&&$CLJS.E.g($CLJS.A(a),b)};SY=function(a,b,c){var d=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.$s,null,$CLJS.tl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.Gu(2,c):$CLJS.Gu(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?OY(2,c):OY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.GX.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.zd(k)?$CLJS.eg.j($CLJS.Cf,$CLJS.Qk.h($CLJS.Al),l):$CLJS.xd(k)?$CLJS.RA($CLJS.Wa,l):l}):$CLJS.Hk.g(a,f)};
Wla=function(a){return $CLJS.fb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return SY(b,Vla,d)},a,Ula(a))};
Xla=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.zL.h(a),b);a:{var d=c;for(var e=$CLJS.Cf;;){var f=$CLJS.A($CLJS.hf.g(Rla(),$CLJS.lf($CLJS.Pk.j(TY,$CLJS.A,$CLJS.ml),$CLJS.dt.h(QY(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=SY(d,f,k),m=$CLJS.UA($CLJS.Py);if($CLJS.n($CLJS.TA("metabase.lib.convert",m))){var t=$CLJS.LB.l($CLJS.H([$CLJS.Kz,$CLJS.Mh.l($CLJS.H([k]))])),u=$CLJS.LB,v=u.l,x=$CLJS.Mh,z=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.eM(QY(d));$CLJS.SA("metabase.lib.convert",
m,$CLJS.EW("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.Kz,z.call(x,$CLJS.H([C]))])),$CLJS.LB.l($CLJS.H([$CLJS.zz,$CLJS.FW($CLJS.A($CLJS.nu(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.be.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=Wla(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.zL.h(a))-1))return a;b+=1}else a=$CLJS.Sk.N(a,$CLJS.zL,$CLJS.R,b,d)}};
Yla=function(a){var b=$CLJS.SW();return $CLJS.Rk.g(function(c){return $CLJS.E.g($CLJS.hF.h(c),"__join")?$CLJS.Sk.j(c,$CLJS.hF,b):c},a)};Zla=function(a){return"string"===typeof $CLJS.oP.h(a)?$CLJS.Hk.g($CLJS.R.j(a,$CLJS.YV,$CLJS.QW($CLJS.oP.h(a))),$CLJS.oP):a};VY=function(a,b){var c=$CLJS.J.j(a,b,UY);if($CLJS.E.g(c,UY))throw $CLJS.ii(["Unable to find ",$CLJS.Mh.l($CLJS.H([b]))," in map."].join(""),new $CLJS.h(null,2,[$la,a,$CLJS.fx,b],null));return c};
WY=function(){return $CLJS.Qk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.me(b))?(b=$CLJS.ie(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};XY=function(a){return $CLJS.eg.j($CLJS.N,WY(),a)};YY=function(a){return $CLJS.Fe($CLJS.eg.j($CLJS.N,$CLJS.Pk.g(WY(),$CLJS.Qk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Fi)})),a))};
$Y=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.h($CLJS.ZY),c);a=$CLJS.E.g(a,$CLJS.dG)&&$CLJS.B(c)?$CLJS.be.g($CLJS.sd(d),new $CLJS.h(null,1,[$CLJS.di,$CLJS.rd(d)],null)):d;b=$CLJS.Fe(YY(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EM,a,b],null):a};ama=function(a){return $CLJS.eg.j($CLJS.Cf,$CLJS.Pk.g($CLJS.hf.h(function(b){return $CLJS.MA(b,$CLJS.QB)}),$CLJS.hf.h($CLJS.ZY)),$CLJS.MW.h(a))};
aZ=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.zL);a=$CLJS.A($CLJS.fb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.ZY.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.SQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.aP,ama(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.xV.h(c)],null)},null,a));return $CLJS.n($CLJS.tQ.h(a))?$CLJS.JV(a,new $CLJS.h(null,1,[$CLJS.tQ,$CLJS.RF],null)):a};
bZ=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.dX(a,b,$CLJS.Pk.g($CLJS.ZY,$CLJS.A)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.dX(d,b,function(e){return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Js],null),$CLJS.hf.h($CLJS.ZY),e)}):d;return $CLJS.JV(a,$CLJS.Ee([b,c]))};bma=function(a){var b=$CLJS.YV.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Hk.g(a,$CLJS.YV),$CLJS.oP,["card__",$CLJS.p.h(b)].join("")):a};
cma=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);dma=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);UY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Vla=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);cZ=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
dZ=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.eZ=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);$la=new $CLJS.M(null,"m","m",1632677161);fZ=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var TY,ema,fma,gma,hma,ima,jma,kma,lma,mma,nma;$CLJS.gZ=$CLJS.N;$CLJS.hZ=$CLJS.N;TY=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.sW,null,$CLJS.dF,null,$CLJS.NN,null,$CLJS.jQ,null,$CLJS.iG,null,$CLJS.ZR,null,$CLJS.jG,null],null),null);ema=$CLJS.Xe($CLJS.N);fma=$CLJS.Xe($CLJS.N);gma=$CLJS.Xe($CLJS.N);hma=$CLJS.Xe($CLJS.N);ima=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.rC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
$CLJS.iZ=new $CLJS.ei($CLJS.Fh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.pC,ima,ema,fma,gma,hma);$CLJS.iZ.m(null,$CLJS.di,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){var b=$CLJS.rd(a);var c=$CLJS.xd(b);a=c?$CLJS.sd(a):a;var d=$CLJS.y(a);a=$CLJS.A(d);d=$CLJS.B(d);b=$CLJS.zW($CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.hf.h($CLJS.iZ),d))}else b=a;return b});$CLJS.iZ.m(null,$CLJS.rW,function(a){return a});
$CLJS.iZ.m(null,$CLJS.eW,function(a){var b=$CLJS.iZ.h($CLJS.jG.h(a)),c=$CLJS.Fe($CLJS.Rk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.JW($CLJS.iZ.h(t),u)},$CLJS.jQ.h(a))),d=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.LE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.hZ,k=$CLJS.gZ;$CLJS.hZ=d;$CLJS.gZ=e;try{var l=Sla(Zla(a),b,$CLJS.H([$CLJS.jQ,c])),m=$CLJS.fb(function(t,u){return $CLJS.Va($CLJS.J.g(t,u))?t:$CLJS.Sk.j(t,u,$CLJS.iZ)},l,$CLJS.Ik.l(TY,$CLJS.jG,$CLJS.H([$CLJS.jQ])));return $CLJS.n($CLJS.NN.h(m))?$CLJS.Sk.j(m,$CLJS.NN,Yla):m}finally{$CLJS.gZ=k,$CLJS.hZ=f}});$CLJS.iZ.m(null,$CLJS.lW,function(a){return Tla(a,function(b){return $CLJS.dX(b,$CLJS.OF,$CLJS.iZ)})});
$CLJS.iZ.m(null,$CLJS.uL,function(a){a=$CLJS.Sk.j($CLJS.Sk.j(a,$CLJS.CL,$CLJS.iZ),$CLJS.zL,$CLJS.iZ);var b=$CLJS.n($CLJS.dF.h(a))?$CLJS.Sk.j(a,$CLJS.dF,function(c){return $CLJS.Ml(c)?$CLJS.Rk.g($CLJS.iZ,c):$CLJS.zh.h(c)}):a;return $CLJS.Va($CLJS.hF.h(a))?$CLJS.R.j(b,$CLJS.hF,"__join"):b});$CLJS.iZ.m(null,$CLJS.wB,function(a){return $CLJS.Rk.g($CLJS.iZ,a)});
$CLJS.iZ.m(null,$CLJS.yB,function(a){return $CLJS.n($CLJS.mj.h(a))?Xla($CLJS.R.j($CLJS.Sk.j($CLJS.NW(a),$CLJS.zL,function(b){return $CLJS.Rk.g($CLJS.iZ,b)}),$CLJS.eZ,!0)):$CLJS.Mt(a,$CLJS.iZ)});$CLJS.iZ.m(null,$CLJS.uG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.zW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,a,b],null))});
$CLJS.iZ.m(null,$CLJS.Dj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.JV(a,new $CLJS.h(null,3,[$CLJS.qD,$CLJS.CB,$CLJS.NO,$CLJS.Vj,$CLJS.gS,$CLJS.GE],null));var c=$CLJS.R.j;var d=$CLJS.Fi.h(a);$CLJS.n(d)||(d=$CLJS.CB.h(a),d=$CLJS.n(d)?d:$CLJS.ZE(b));a=c.call($CLJS.R,a,$CLJS.Fi,d);return $CLJS.zW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,a,b],null))});
$CLJS.iZ.m(null,$CLJS.dG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.di.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dG,$CLJS.Hk.g(c,$CLJS.di),$CLJS.Rk.g($CLJS.iZ,b)],null);b=$CLJS.zW(b);return null!=a?$CLJS.be.g(b,$CLJS.iZ.h(a)):b});$CLJS.iZ.m(null,$CLJS.zB,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.zW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.iZ.m(null,$CLJS.jG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.zW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,VY($CLJS.hZ,c)],null))});$CLJS.iZ.m(null,$CLJS.EM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.iZ.h(b);var c=$CLJS.y(b);b=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.gl.l($CLJS.H([c,a]))],null),d)});jma=$CLJS.Xe($CLJS.N);kma=$CLJS.Xe($CLJS.N);
lma=$CLJS.Xe($CLJS.N);mma=$CLJS.Xe($CLJS.N);nma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.rC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.ZY=new $CLJS.ei($CLJS.Fh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.pC,nma,jma,kma,lma,mma);
$CLJS.ZY.m(null,$CLJS.di,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);if($CLJS.xd(a)){c=$CLJS.eg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.gf.g;d=$CLJS.hf.g($CLJS.ZY,d);a=YY(a);a=c.call($CLJS.eg,b,e.call($CLJS.gf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.hf.g($CLJS.ZY,$CLJS.ee(a,d)))}else a=$CLJS.xd(a)?$CLJS.Mt(XY(a),$CLJS.ZY):
a;return a});for(var jZ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[fZ,dZ],null)),kZ=null,lZ=0,mZ=0;;)if(mZ<lZ){var oma=kZ.X(null,mZ);$CLJS.CF(oma,cZ);mZ+=1}else{var nZ=$CLJS.y(jZ);if(nZ){var oZ=nZ;if($CLJS.Ad(oZ)){var pZ=$CLJS.lc(oZ),pma=$CLJS.mc(oZ),qma=pZ,rma=$CLJS.D(pZ);jZ=pma;kZ=qma;lZ=rma}else{var sma=$CLJS.A(oZ);$CLJS.CF(sma,cZ);jZ=$CLJS.B(oZ);kZ=null;lZ=0}mZ=0}else break}
for(var qZ=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.by,$CLJS.oG,$CLJS.DG,$CLJS.$F,$CLJS.jk,$CLJS.IG,$CLJS.Zn,$CLJS.fG,$CLJS.BG,$CLJS.JG,$CLJS.HG,$CLJS.OG],null)),rZ=null,sZ=0,tZ=0;;)if(tZ<sZ){var tma=rZ.X(null,tZ);$CLJS.CF(tma,fZ);tZ+=1}else{var uZ=$CLJS.y(qZ);if(uZ){var vZ=uZ;if($CLJS.Ad(vZ)){var wZ=$CLJS.lc(vZ),uma=$CLJS.mc(vZ),vma=wZ,wma=$CLJS.D(wZ);qZ=uma;rZ=vma;sZ=wma}else{var xma=$CLJS.A(vZ);$CLJS.CF(xma,fZ);qZ=$CLJS.B(vZ);rZ=null;sZ=0}tZ=0}else break}
for(var xZ=$CLJS.y($CLJS.yf([$CLJS.gs,$CLJS.vt,$CLJS.hs,$CLJS.PG,$CLJS.dG,$CLJS.xG,$CLJS.VF,$CLJS.RG,$CLJS.MF,$CLJS.hG,$CLJS.QF,$CLJS.sG,$CLJS.zG,$CLJS.SF,$CLJS.hJ,$CLJS.jJ,$CLJS.Qy,$CLJS.dJ,$CLJS.lJ,$CLJS.YI,$CLJS.pJ,$CLJS.cJ,$CLJS.WI,$CLJS.oJ,$CLJS.wJ,$CLJS.sJ,$CLJS.bJ,$CLJS.zJ,$CLJS.rJ,$CLJS.xJ,$CLJS.UF,$CLJS.NF,$CLJS.SG,$CLJS.iI,$CLJS.wG,$CLJS.$x,$CLJS.KG,$CLJS.MG,$CLJS.TG,$CLJS.Pt,$CLJS.Vt],!0)),yZ=null,zZ=0,AZ=0;;)if(AZ<zZ){var yma=yZ.X(null,AZ);$CLJS.CF(yma,dZ);AZ+=1}else{var BZ=$CLJS.y(xZ);
if(BZ){var CZ=BZ;if($CLJS.Ad(CZ)){var DZ=$CLJS.lc(CZ),zma=$CLJS.mc(CZ),Ama=DZ,Bma=$CLJS.D(DZ);xZ=zma;yZ=Ama;zZ=Bma}else{var Cma=$CLJS.A(CZ);$CLJS.CF(Cma,dZ);xZ=$CLJS.B(CZ);yZ=null;zZ=0}AZ=0}else break}$CLJS.ZY.m(null,cZ,function(a){return $Y(a)});$CLJS.ZY.m(null,$CLJS.yB,function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Pk.g(WY(),$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.ZY.h(b)],null)})),a)});
$CLJS.ZY.m(null,$CLJS.jG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.xd(b))try{var d=YY(b),e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jG,VY($CLJS.gZ,c)],null);return $CLJS.n(d)?$CLJS.be.g(e,d):e}catch(f){throw b=f,c=$CLJS.CV(b),c=$CLJS.fa.g?$CLJS.fa.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.fa.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.hi(c,new $CLJS.h(null,1,[$CLJS.W,a],null),b);}else return a});
$CLJS.ZY.m(null,$CLJS.wB,function(a){return $CLJS.Rk.g($CLJS.ZY,a)});$CLJS.ZY.m(null,$CLJS.uG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,$CLJS.ZY.h(a),YY(b)],null)});
$CLJS.ZY.m(null,$CLJS.Dj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=YY($CLJS.JV(b,new $CLJS.h(null,3,[$CLJS.CB,$CLJS.qD,$CLJS.Vj,$CLJS.NO,$CLJS.GE,$CLJS.gS],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,a,b],null)});
$CLJS.ZY.m(null,$CLJS.uL,function(a){var b=XY(a);a=0==$CLJS.hF.h(a).lastIndexOf("__join",0)?$CLJS.Hk.g(b,$CLJS.hF):b;return $CLJS.gl.l($CLJS.H([$CLJS.Mt($CLJS.Hk.l(a,$CLJS.zL,$CLJS.H([$CLJS.CL])),$CLJS.ZY),bZ($CLJS.em(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.CL],null)),$CLJS.CL,$CLJS.aS),aZ(a)]))});
$CLJS.ZY.m(null,$CLJS.eW,function(a){var b=$CLJS.jG.h(a),c=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.hZ,e=$CLJS.gZ;$CLJS.hZ=c;$CLJS.gZ=b;try{return $CLJS.fb(function(f,k){return $CLJS.dX(f,
k,$CLJS.ZY)},bZ($CLJS.dX($CLJS.dX(bma(XY(a)),$CLJS.jG,function(f){return $CLJS.Rk.g($Y,f)}),$CLJS.jQ,function(f){return $CLJS.eg.g($CLJS.N,function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z),G=$CLJS.ZY.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IW(C),$CLJS.E.g($CLJS.Dj,$CLJS.A(G))?$CLJS.hd(G):G],null);x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),
m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);u=$CLJS.ZY.h(x);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IW(x),$CLJS.E.g($CLJS.Dj,$CLJS.A(u))?$CLJS.hd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.sW,$CLJS.ZF),$CLJS.Ik.l(TY,$CLJS.jG,$CLJS.H([$CLJS.sW,$CLJS.jQ])))}finally{$CLJS.gZ=e,$CLJS.hZ=d}});$CLJS.ZY.m(null,$CLJS.lW,function(a){return $CLJS.Mt(XY(a),$CLJS.ZY)});
$CLJS.ZY.m(null,$CLJS.rW,function(a){try{var b=XY(a),c=$CLJS.EN.h(b),d=aZ(b),e=$CLJS.E.g($CLJS.Ej.h($CLJS.id($CLJS.zL.h(a))),$CLJS.lW)?$CLJS.tQ:$CLJS.RF;return $CLJS.gl.l($CLJS.H([$CLJS.Mt($CLJS.Hk.l(b,$CLJS.zL,$CLJS.H([$CLJS.EN,$CLJS.eZ])),$CLJS.ZY),function(){var k=$CLJS.Ee([$CLJS.mj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.EN,c):k}()]))}catch(k){var f=k;throw $CLJS.hi(function(){var l=$CLJS.CV(f);return $CLJS.fa.g?$CLJS.fa.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.fa.call(null,
"Error converting MLv2 query to legacy query: %s",l)}(),new $CLJS.h(null,1,[$CLJS.RF,a],null),f);}});
$CLJS.EZ=function(){function a(d,e,f){f=$CLJS.NY(null,$CLJS.Xz(f,$CLJS.H([$CLJS.zi,!0])));var k=$CLJS.PW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.jG);k=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.hZ,t=$CLJS.gZ;$CLJS.hZ=k;$CLJS.gZ=l;try{try{return $CLJS.iZ.h(f)}catch(v){var u=v;throw $CLJS.hi(function(){var x=$CLJS.CV(u);return $CLJS.fa.g?$CLJS.fa.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.fa.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.RF,d,$CLJS.NL,e,dma,f,cma,$CLJS.hZ],null),u);}}finally{$CLJS.gZ=t,$CLJS.hZ=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();