var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");
'use strict';var vu,xu,yu,zu,Au,Bu,Cu,Ju,Ku,Lu,Mu,Nu,wba,Qu,xba,Tu,Uu,Wu,Xu,Yu,Zu,$u,av,fv,iv,kv,nv,yba,zba,sv,Aba,uv,vv,wv,Cv,Dv,Lv,Pv,Vv,Xv,bw,fw,kw,mw,nw,hw,pw,qw,sw,rw,tw,Eba,yw,Hw,Iw,Bw,Gba,Mw,Pw,Rw,Sw,Iba,Jba,Kba,Ww,$w,ax,bx,cx,Lba,hx,kx,Mba,Nba,Oba,Pba,Qba,Rba,Sba,Tba,Uba,Wba,px,Vba,tx,Xba,ux,Yba,Zba,vx,$ba,wx,aca,bca,cca,eca,fca,hca,ica,kca,mca,Bx,Ex,oca,nca,pca,gw,jw,Lx,Mx,Nx,Ox,Rx,Sx,sca,tca,uca,Vx,vca,Wx,Ux,Xx,dv,Yx,rv,jx,wca,Zv,xca,yca,gv,pv,Px,zca,Nv,xw,xv,Aca,Bca,Kv,Cca,Cx,Bv,ww,Iv,
lx,Dca,qv,Eca,sx,zx,Zx,Fca,Tw,ay,Vw,Mv,vw,cv,yx,Dba,Yv,Gv,jv,Gca,ow,Hca,Ew,Uw,Gx,Ica,Ix,Cba,Jca,Kca,Lca,cw,Mca,qx,aw,Nca,Fw,Dw,Oca,lv,Pca,rca,Jx,yv,cy,Yw,dy,ey,Jw,Qca,Av,Rca,qca,Sca,ew,ox,uw,Tca,Zw,Qx,Uca,nx,Vca,Cw,ov,Wca,Ov,gy,Xw,Xca,hv,xx,$v,Bba,Ax,mv,dx,Dx,ix,ex,gx,rx,Kw,hy,iy,Yca,Vu,Zca,Su,bv,$ca,zv,ada;vu=function(a){return 1==a.length&&" "<=a&&"~">=a||""<=a&&"�">=a};$CLJS.wu=function(){};
xu=function(a){if(null!=a&&null!=a.rc)a=a.rc(a);else{var b=xu[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=xu._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("IWriter.-flush",a);}return a};yu=function(){};zu=function(a){if(null!=a&&null!=a.Lc)a=a.Lc(a);else{var b=zu[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=zu._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("IPending.-realized?",a);}return a};
Au=function(a){if("number"===typeof a)return String.fromCharCode(a);if("string"===typeof a&&1===a.length)return a;throw Error("Argument to char must be a character or number");};Bu=function(a,b){return a-b*$CLJS.Vd(a,b)};Cu=function(a,b,c){if(null!=a&&null!=a.m)a.m(a,b,c);else{var d=Cu[$CLJS.wa(null==a?null:a)];if(null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else if(d=Cu._,null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else throw $CLJS.bb("IMultiFn.-add-method",a);}};
$CLJS.Du=function(a,b){var c=$CLJS.ec($CLJS.N);a=$CLJS.y(a);for(b=$CLJS.y(b);;)if(a&&b)c=$CLJS.hg.j(c,$CLJS.A(a),$CLJS.A(b)),a=$CLJS.B(a),b=$CLJS.B(b);else return $CLJS.gc(c)};$CLJS.Eu=function(a,b){return $CLJS.lh(a,b)};$CLJS.Fu=function(a,b){return new $CLJS.ne(null,function(){var c=$CLJS.y(b);if(c){var d=$CLJS.A(c);d=a.h?a.h(d):a.call(null,d);c=$CLJS.n(d)?$CLJS.ee($CLJS.A(c),$CLJS.Fu(a,$CLJS.Lc(c))):null}else c=null;return c},null,null)};
$CLJS.Gu=function(a,b){return $CLJS.hf.j(function(c){return c},b,$CLJS.bf(a,b))};Ju=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;$CLJS.Se.g(Hu,0<b.length?new $CLJS.w(b.slice(0),0,null):null);$CLJS.ac($CLJS.Iu,"\n")};Ku=function(a){if("number"===typeof a)return a;if("string"===typeof a&&1===a.length)return a.charCodeAt(0);throw Error("Argument to char must be a character or number");};
Lu=function(a,b,c){var d=c;for(c=$CLJS.Cf;;){if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null);var e=$CLJS.A(d);d=$CLJS.B(d);e=$CLJS.Se.g(a,new $CLJS.P(null,2,5,$CLJS.Q,[e,b],null));b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);c=$CLJS.be.g(c,b);b=e}};Mu=function(a,b){var c=b;for(b=$CLJS.Cf;;){var d=$CLJS.Se.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[c],null));c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);if($CLJS.Va(c))return new $CLJS.P(null,2,5,$CLJS.Q,[b,d],null);b=$CLJS.be.g(b,c);c=d}};
Nu=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);var v=$CLJS.I(t,0,null);$CLJS.I(t,1,null);l.add(new $CLJS.P(null,2,5,$CLJS.Q,[u,v],null));m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);k=$CLJS.I(f,0,null);$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}()),$CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);l.add(new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null));m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())],null)};
wba=function(a,b){return $CLJS.eg.g($CLJS.N,function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,new $CLJS.P(null,2,5,$CLJS.Q,[u,b],null)],null));t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);
k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,new $CLJS.P(null,2,5,$CLJS.Q,[k,b],null)],null),e($CLJS.Lc(f)))}return null}},null,null)}(a)}())};$CLJS.Ou=function(){var a=$CLJS.Iu;if(null!=a&&null!=a.uf)a.uf(a);else{var b=$CLJS.Ou[$CLJS.wa(null==a?null:a)];if(null!=b)b.h?b.h(a):b.call(null,a);else if(b=$CLJS.Ou._,null!=b)b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("IPrettyFlush.-ppflush",a);}};$CLJS.Pu=function(a,b){a=$CLJS.q($CLJS.q(a));return b.h?b.h(a):b.call(null,a)};
Qu=function(a,b,c){$CLJS.Oh.v($CLJS.q(a),$CLJS.R,b,c)};xba=function(a,b){$CLJS.E.g(b,"\n")?(Qu(a,$CLJS.Ru,0),Qu(a,$CLJS.nj,$CLJS.Pu(a,$CLJS.nj)+1)):Qu(a,$CLJS.Ru,$CLJS.Pu(a,$CLJS.Ru)+1);return $CLJS.ac($CLJS.Pu(a,Su),b)};Tu=function(a,b,c,d){this.Ca=a;this.Nf=b;this.Yc=c;this.Ch=d;this.C=1074167808;this.I=0};
Uu=function(a,b,c,d,e,f,k,l,m,t,u,v,x){this.parent=a;this.Cb=b;this.tb=c;this.sb=d;this.vb=e;this.wb=f;this.prefix=k;this.Ab=l;this.Db=m;this.xb=t;this.S=u;this.G=v;this.J=x;this.C=2230716170;this.I=139264};Wu=function(a,b){for(b=Vu.h(b);;){if(null==b)return!1;if(a===b)return!0;b=Vu.h(b)}};Xu=function(a,b,c,d,e,f,k,l){this.ca=a;this.data=b;this.ac=c;this.ba=d;this.aa=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};
Yu=function(a,b,c,d,e,f,k,l){this.ca=a;this.type=b;this.ea=c;this.ba=d;this.aa=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};Zu=function(a,b,c,d,e,f,k){this.ca=a;this.ea=b;this.ba=c;this.aa=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};$u=function(a,b,c,d,e,f,k){this.ca=a;this.ea=b;this.ba=c;this.aa=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
av=function(a,b,c,d,e,f,k,l,m){this.ca=a;this.ea=b;this.Wb=c;this.offset=d;this.ba=e;this.aa=f;this.S=k;this.G=l;this.J=m;this.C=2230716170;this.I=139264};
fv=function(a,b,c){b=$CLJS.y(b);for(var d=null,e=0,f=0;;)if(f<e){var k=d.X(null,f);if(!$CLJS.E.g(bv.h(k),cv)){var l=dv.h($CLJS.q($CLJS.q(a)));$CLJS.n(l)&&$CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),l)}ev.g(a,k);$CLJS.Oh.v($CLJS.q(a),$CLJS.R,dv,dv.h(k));k=dv.h($CLJS.q($CLJS.q(a)));$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),k),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,dv,null));f+=1}else if(b=$CLJS.y(b))$CLJS.Ad(b)?(d=$CLJS.lc(b),b=$CLJS.mc(b),k=d,e=$CLJS.D(d),d=k):(k=$CLJS.A(b),$CLJS.E.g(bv.h(k),cv)||
(d=dv.h($CLJS.q($CLJS.q(a))),$CLJS.n(d)&&$CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),d)),ev.g(a,k),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,dv,dv.h(k)),k=dv.h($CLJS.q($CLJS.q(a))),$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),k),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,dv,null)),b=$CLJS.B(b),d=null,e=0),f=0;else break};iv=function(a,b){var c=$CLJS.Pu(Su.h($CLJS.q($CLJS.q(a))),$CLJS.jk),d;(d=null==c)||(a=$CLJS.Pu(Su.h($CLJS.q($CLJS.q(a))),$CLJS.Ru),b=(b=$CLJS.y(b))?gv.h($CLJS.id(b))-hv.h($CLJS.A(b)):0,d=a+b<c);return d};
kv=function(a,b,c){b=$CLJS.q(jv.h(b));return $CLJS.n(b)?b:!iv(a,c)};nv=function(a,b,c){var d=lv.h($CLJS.q($CLJS.q(a))),e=$CLJS.Pu(Su.h($CLJS.q($CLJS.q(a))),$CLJS.jk);return $CLJS.n(d)?$CLJS.n(e)?(d=$CLJS.q(mv.h(b))>=e-d)?kv(a,b,c):d:e:d};yba=function(a){var b=$CLJS.A(a),c=ov.h(b);b=$CLJS.y($CLJS.Fu(function(d){return!($CLJS.E.g(bv.h(d),cv)&&Wu(ov.h(d),c))},$CLJS.B(a)));return new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.y($CLJS.bf($CLJS.D(b)+1,a))],null)};
zba=function(a){var b=$CLJS.A(a),c=ov.h(b);return $CLJS.y($CLJS.Fu(function(d){var e=ov.h(d);return!($CLJS.E.g(bv.h(d),cv)&&($CLJS.E.g(e,c)||Wu(e,c)))},$CLJS.B(a)))};
sv=function(a,b){$CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.Oh.v($CLJS.q(a),$CLJS.R,dv,null);b=ov.h(b);var c=pv.h(b);$CLJS.n(c)&&$CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),c);c=$CLJS.Se.g($CLJS.p,$CLJS.df($CLJS.q(qv.h(b))-$CLJS.D(c)," "));$CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),c);a:for($CLJS.Ye(rv.h(b),!0),$CLJS.Ye(jv.h(b),!0),a=Vu.h(b);;)if($CLJS.n(a))$CLJS.Ye(jv.h(a),!0),$CLJS.Ye(rv.h(a),!0),a=Vu.h(a);else break a;return null};
Aba=function(a){var b=$CLJS.y($CLJS.Fu(function(c){return!$CLJS.E.g(bv.h(c),cv)},a));return new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.y($CLJS.bf($CLJS.D(b),a))],null)};uv=function(a){for(var b=$CLJS.Qt.h($CLJS.q($CLJS.q(a)));;){$CLJS.Oh.v($CLJS.q(a),$CLJS.R,$CLJS.Qt,$CLJS.eg.g($CLJS.Cf,b));if(iv(a,b))return null;var c=tv(a,b);if(b!==c)b=c;else return null}};
vv=function(a,b){$CLJS.Oh.v($CLJS.q(a),$CLJS.R,$CLJS.Qt,$CLJS.be.g($CLJS.Qt.h($CLJS.q($CLJS.q(a))),b));return iv(a,$CLJS.Qt.h($CLJS.q($CLJS.q(a))))?null:uv(a)};wv=function(a){var b=dv.h($CLJS.q($CLJS.q(a)));return $CLJS.n(b)?($CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),b),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,dv,null)):null};
Cv=function(a,b){var c=$CLJS.Ot(b,"\n",-1);if($CLJS.E.g($CLJS.D(c),1))return b;b=pv.h($CLJS.A(xv.h($CLJS.q($CLJS.q(a)))));var d=$CLJS.A(c);if($CLJS.E.g(yv,$CLJS.yi.h($CLJS.q($CLJS.q(a))))){var e=zv.h($CLJS.q($CLJS.q(a))),f=e+$CLJS.D(d);$CLJS.Oh.v($CLJS.q(a),$CLJS.R,zv,f);vv(a,new Xu(Av,d,null,e,f,null,null,null));uv(a);d=$CLJS.Qt.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(fv(a,d,!0),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,$CLJS.Qt,$CLJS.Cf))}else wv(a),$CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),d);$CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),
"\n");d=$CLJS.y($CLJS.B($CLJS.Kt(c)));e=null;for(var k=f=0;;)if(k<f){var l=e.X(null,k);$CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),l);$CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.n(b)&&$CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),b);k+=1}else if(d=$CLJS.y(d))e=d,$CLJS.Ad(e)?(d=$CLJS.lc(e),k=$CLJS.mc(e),e=d,f=$CLJS.D(d),d=k):(d=$CLJS.A(e),$CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),d),$CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),"\n"),$CLJS.n(b)&&$CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),b),d=$CLJS.B(e),e=null,f=0),k=0;else break;$CLJS.Oh.v($CLJS.q(a),
$CLJS.R,yv,Bv);return $CLJS.id(c)};Dv=function(a,b,c,d,e,f){this.Ca=a;this.Nf=b;this.li=c;this.mh=d;this.Yc=e;this.Jh=f;this.C=1074167808;this.I=0};
$CLJS.Hv=function(a){var b=$CLJS.Ev,c=Fv,d=new Uu(null,null,$CLJS.Xe(0),$CLJS.Xe(0),$CLJS.Xe(!1),$CLJS.Xe(!1),null,null,null,null,null,null,null),e=$CLJS.Xe($CLJS.Ig([xv,lv,Bba,Gv,Cba,$CLJS.yi,zv,dv,Su,Dba,$CLJS.Qt],[d,c,d,!0,null,Bv,0,null,new Tu(a,b,$CLJS.Xe(new $CLJS.h(null,4,[$CLJS.jk,b,$CLJS.Ru,0,$CLJS.nj,0,Su,a],null)),$CLJS.N),1,$CLJS.Cf]));return new Dv(a,b,c,d,e,$CLJS.N)};
Lv=function(a,b){var c=$CLJS.Iu;b=new Uu(xv.h($CLJS.q($CLJS.q(c))),null,$CLJS.Xe(0),$CLJS.Xe(0),$CLJS.Xe(!1),$CLJS.Xe(!1),a,null,b,null,null,null,null);$CLJS.Oh.v($CLJS.q(c),$CLJS.R,xv,b);if($CLJS.E.g($CLJS.yi.h($CLJS.q($CLJS.q(c))),Bv)){wv(c);var d=Iv.h($CLJS.q($CLJS.q(c)));$CLJS.n(d)&&(d.h?d.h($CLJS.Jv):d.call(null,$CLJS.Jv));$CLJS.n(a)&&$CLJS.ac(Su.h($CLJS.q($CLJS.q(c))),a);c=$CLJS.Pu(Su.h($CLJS.q($CLJS.q(c))),$CLJS.Ru);$CLJS.Ye(b.tb,c);$CLJS.Ye(b.sb,c)}else d=zv.h($CLJS.q($CLJS.q(c))),a=d+($CLJS.n(a)?
$CLJS.D(a):0),$CLJS.Oh.v($CLJS.q(c),$CLJS.R,zv,a),vv(c,new Zu(Kv,b,d,a,null,null,null))};
Pv=function(){var a=$CLJS.Iu,b=xv.h($CLJS.q($CLJS.q(a))),c=Mv.h(b);if($CLJS.E.g($CLJS.yi.h($CLJS.q($CLJS.q(a))),Bv)){wv(a);$CLJS.n(c)&&$CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),c);var d=Iv.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(d.h?d.h(Nv):d.call(null,Nv))}else d=zv.h($CLJS.q($CLJS.q(a))),c=d+($CLJS.n(c)?$CLJS.D(c):0),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,zv,c),vv(a,new $u(Ov,b,d,c,null,null,null));$CLJS.Oh.v($CLJS.q(a),$CLJS.R,xv,Vu.h(b))};
$CLJS.Qv=function(a){var b=null!=a?a.C&32768||$CLJS.Bc===a.ag?!0:a.C?!1:$CLJS.$a($CLJS.wu,a):$CLJS.$a($CLJS.wu,a);return b?Gv.h($CLJS.q($CLJS.q(a))):b};$CLJS.Uv=function(a){var b=Rv;$CLJS.n(b)&&(b=$CLJS.Na,b=$CLJS.n(b)?Rv>=$CLJS.Na:b);$CLJS.Va($CLJS.Sv)?Hu.call(null,a):$CLJS.n(b)?$CLJS.ac($CLJS.Iu,"..."):($CLJS.n(Rv)&&(Rv+=1),Tv.call(null,a));return b};
Vv=function(a,b){if($CLJS.Va(b.h?b.h(a):b.call(null,a)))throw Error(["Bad argument: ",$CLJS.p.h(a),". It must be one of ",$CLJS.p.h(b)].join(""));};Xv=function(){var a=$CLJS.qh;return $CLJS.n(a)?Wv>=$CLJS.qh:a};bw=function(a){Vv(a,new $CLJS.ah(null,new $CLJS.h(null,4,[Yv,null,Zv,null,$v,null,aw,null],null),null));var b=$CLJS.Iu;$CLJS.Oh.v($CLJS.q(b),$CLJS.R,$CLJS.yi,yv);var c=zv.h($CLJS.q($CLJS.q(b))),d=xv.h($CLJS.q($CLJS.q(b)));vv(b,new Yu(cv,a,d,c,c,null,null,null))};
fw=function(a,b){Vv(a,new $CLJS.ah(null,new $CLJS.h(null,2,[cw,null,$CLJS.dw,null],null),null));var c=$CLJS.Iu,d=xv.h($CLJS.q($CLJS.q(c)));if($CLJS.E.g($CLJS.yi.h($CLJS.q($CLJS.q(c))),Bv)){wv(c);var e=qv.h(d);if($CLJS.n($CLJS.E.g?$CLJS.E.g(cw,a):$CLJS.E.call(null,cw,a)))a=$CLJS.q(mv.h(d));else if($CLJS.n($CLJS.E.g?$CLJS.E.g($CLJS.dw,a):$CLJS.E.call(null,$CLJS.dw,a)))a=$CLJS.Pu(Su.h($CLJS.q($CLJS.q(c))),$CLJS.Ru);else throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));$CLJS.Ye(e,b+a)}else e=
zv.h($CLJS.q($CLJS.q(c))),vv(c,new av(ew,d,a,b,e,e,null,null,null))};kw=function(a,b,c){b="string"===typeof b?gw(b):b;c=hw(c);a:{var d=new $CLJS.Ea,e=$CLJS.Va(a)||!0===a?new $CLJS.sc(d):a,f=iw(b)&&$CLJS.Va($CLJS.Qv(e))?$CLJS.n($CLJS.Qv(e))?e:$CLJS.Hv(e):e,k=$CLJS.Iu;$CLJS.Iu=f;try{try{jw(b,c)}finally{e!==f&&xu(f)}var l=$CLJS.Va(a)?$CLJS.p.h(d):!0===a?$CLJS.uh($CLJS.p.h(d)):null;break a}finally{$CLJS.Iu=k}l=void 0}return l};
mw=function(a,b){a=[$CLJS.p.h(a),"\n",$CLJS.p.h(lw),"\n",$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(b," "))),"^\n"].join("");throw Error(a);};nw=function(a,b,c,d,e,f){this.uc=a;this.pb=b;this.$b=c;this.S=d;this.G=e;this.J=f;this.C=2230716170;this.I=139264};hw=function(a){a=$CLJS.y(a);return new nw(a,a,0,null,null,null)};
pw=function(a){var b=$CLJS.Pi.h(a);if($CLJS.n(b))return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(b),new nw(ow.h(a),$CLJS.B(b),zv.h(a)+1,null,null,null)],null);throw Error("Not enough arguments for format definition");};qw=function(a){var b=pw(a);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);a="string"===typeof a?gw(a):a;return new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null)};sw=function(a,b){return b>=zv.h(a)?(b=zv.h(a)-b,rw.g?rw.g(a,b):rw.call(null,a,b)):new nw(ow.h(a),$CLJS.bf(b,ow.h(a)),b,null,null,null)};
rw=function(a,b){var c=zv.h(a)+b;return 0>b?sw(a,c):new nw(ow.h(a),$CLJS.bf(b,$CLJS.Pi.h(a)),c,null,null,null)};tw=function(a,b,c,d,e,f,k){this.dc=a;this.cc=b;this.kc=c;this.offset=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
Eba=function(a,b){var c=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);d=$CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,2,[uw,null,vw,null],null),null),c)?new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null):$CLJS.E.g(d,ww)?pw(b):$CLJS.E.g(d,xw)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.D($CLJS.Pi.h(b)),b],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.P(null,2,5,$CLJS.Q,
[b,a],null)],null),d],null)};yw=function(a,b){b=Lu(Eba,b,a);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eg.g($CLJS.N,a),b],null)};
Hw=function(a,b,c){c=pw(c);var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.Fd(d))if($CLJS.E.g(zw,10))var e=[$CLJS.p.h(d),$CLJS.n(Aw)?".":null].join("");else{e=$CLJS.p;var f=e.h;if($CLJS.n(Aw)){var k=$CLJS.J.g(Fba,zw);k=$CLJS.n(k)?k:["#",$CLJS.p.h(zw),"r"].join("")}else k=null;e=[f.call(e,k),$CLJS.p.h(Bw(zw,d))].join("")}else e=null;a=$CLJS.n(e)?e:a.h?a.h(d):a.call(null,d);d=a.length;e=d+Cw.h(b);e=e>=Dw.h(b)?e:e+($CLJS.Vd(Dw.h(b)-e-1,Ew.h(b))+1)*Ew.h(b);d=$CLJS.Se.g($CLJS.p,$CLJS.df(e-d,Fw.h(b)));
$CLJS.n(vw.h(b))?Gw.l($CLJS.H([[$CLJS.p.h(d),$CLJS.p.h(a)].join("")])):Gw.l($CLJS.H([[$CLJS.p.h(a),$CLJS.p.h(d)].join("")]));return c};Iw=function(a,b){return $CLJS.ce($CLJS.A(Mu(function(c){return 0<c?new $CLJS.P(null,2,5,$CLJS.Q,[Bu(c,a),$CLJS.Vd(c,a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,null],null)},b)))};Bw=function(a,b){return 0===b?"0":$CLJS.Se.g($CLJS.p,$CLJS.hf.g(function(c){return 10>c?Au(Ku("0")+c):Au(Ku("a")+(c-10))},Iw(a,b)))};
Gba=function(a,b){return $CLJS.ce($CLJS.A(Mu(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.y($CLJS.ce($CLJS.af(a,c))),$CLJS.y($CLJS.bf(a,c))],null)},$CLJS.ce(b))))};
Mw=function(a,b,c){c=pw(c);var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.Fd(d)||"number"===typeof d&&!isNaN(d)&&Infinity!==d&&parseFloat(d)!==parseInt(d,10)&&$CLJS.E.g(d,Math.floor(d))){var e=0>d,f=Bw(a,e?-d:d);a=$CLJS.n(uw.h(b))?function(){var k=$CLJS.hf.g(function(m){return $CLJS.Se.g($CLJS.p,m)},Gba(Jw.h(b),f)),l=$CLJS.df($CLJS.D(k),Kw.h(b));return $CLJS.Se.g($CLJS.p,$CLJS.B($CLJS.ef.g(l,k)))}():f;a=e?["-",$CLJS.p.h(a)].join(""):$CLJS.n(vw.h(b))?["+",$CLJS.p.h(a)].join(""):a;a=a.length<
Dw.h(b)?[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(Dw.h(b)-a.length,Fw.h(b)))),$CLJS.p.h(a)].join(""):a;Gw.l($CLJS.H([a]))}else Hw($CLJS.Lw,new $CLJS.h(null,5,[Dw,Dw.h(b),Ew,1,Cw,0,Fw,Fw.h(b),vw,!0],null),hw(new $CLJS.P(null,1,5,$CLJS.Q,[d],null)));return c};
Pw=function(a){var b=$CLJS.Vd(a,100);a=Bu(a,100);var c=0<b?[$CLJS.p.h($CLJS.F(Nw,b))," hundred"].join(""):null,d=$CLJS.p,e=d.h;if(0<a)if(20>a)var f=$CLJS.F(Nw,a);else{f=$CLJS.Vd(a,10);var k=Bu(a,10);f=[$CLJS.p.h(0<f?$CLJS.F(Ow,f):null),0<f&&0<k?"-":null,$CLJS.p.h(0<k?$CLJS.F(Nw,k):null)].join("")}else f=null;return[c,0<b&&0<a?" ":null,e.call(d,f)].join("")};
Rw=function(a,b){var c=$CLJS.D(a),d=$CLJS.Cf;--c;var e=$CLJS.A(a);for(a=$CLJS.B(a);;){if(null==a)return[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.ff(", ",d))),$CLJS.td(e)||$CLJS.td(d)?null:", ",$CLJS.p.h(e),!$CLJS.td(e)&&0<c+b?[" ",$CLJS.p.h($CLJS.F(Qw,c+b))].join(""):null].join("");d=$CLJS.td(e)?d:$CLJS.be.g(d,[$CLJS.p.h(e)," ",$CLJS.p.h($CLJS.F(Qw,c+b))].join(""));--c;e=$CLJS.A(a);a=$CLJS.B(a)}};
Sw=function(a,b){b=pw(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);if("number"===typeof c&&0<c&&4E3>c){var d=Iw(10,c);c=$CLJS.Cf;for(var e=$CLJS.D(d)-1;;)if($CLJS.td(d)){Gw.l($CLJS.H([$CLJS.Se.g($CLJS.p,c)]));break}else{var f=$CLJS.A(d);c=$CLJS.E.g(0,f)?c:$CLJS.be.g(c,$CLJS.F($CLJS.F(a,e),f-1));--e;d=$CLJS.B(d)}}else Mw(10,new $CLJS.h(null,5,[Dw,0,Fw," ",Kw,",",Jw,3,uw,!0],null),hw(new $CLJS.P(null,1,5,$CLJS.Q,[c],null)));return b};
Iba=function(a,b){a=pw(b);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);var c=Ku(b);b=c&127;c&=128;var d=$CLJS.J.g(Hba,b);0<c&&Gw.l($CLJS.H(["Meta-"]));Gw.l($CLJS.H([$CLJS.n(d)?d:32>b?["Control-",$CLJS.p.h(Au(b+64))].join(""):$CLJS.E.g(b,127)?"Control-?":Au(b)]));return a};
Jba=function(a,b){var c=pw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);a=Tw.h(a);if($CLJS.n($CLJS.E.g?$CLJS.E.g("o",a):$CLJS.E.call(null,"o",a)))kw(!0,"\\o~3,'0o",$CLJS.H([Ku(b)]));else if($CLJS.n($CLJS.E.g?$CLJS.E.g("u",a):$CLJS.E.call(null,"u",a)))kw(!0,"\\u~4,'0x",$CLJS.H([Ku(b)]));else if($CLJS.n($CLJS.E.g?$CLJS.E.g(null,a):$CLJS.E.call(null,null,a)))$CLJS.ac($CLJS.Iu,$CLJS.n($CLJS.E.g?$CLJS.E.g("\b",b):$CLJS.E.call(null,"\b",b))?"\\backspace":$CLJS.n($CLJS.E.g?$CLJS.E.g(" ",b):$CLJS.E.call(null,
" ",b))?"\\space":$CLJS.n($CLJS.E.g?$CLJS.E.g("\t",b):$CLJS.E.call(null,"\t",b))?"\\tab":$CLJS.n($CLJS.E.g?$CLJS.E.g("\n",b):$CLJS.E.call(null,"\n",b))?"\\newline":$CLJS.n($CLJS.E.g?$CLJS.E.g("\f",b):$CLJS.E.call(null,"\f",b))?"\\formfeed":$CLJS.n($CLJS.E.g?$CLJS.E.g("\r",b):$CLJS.E.call(null,"\r",b))?"\\return":$CLJS.n($CLJS.E.g?$CLJS.E.g('"',b):$CLJS.E.call(null,'"',b))?'\\"':$CLJS.n($CLJS.E.g?$CLJS.E.g("\\",b):$CLJS.E.call(null,"\\",b))?"\\\\":["\\",$CLJS.p.h(b)].join(""));else throw Error(["No matching clause: ",
$CLJS.p.h(a)].join(""));return c};Kba=function(a,b){b=pw(b);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);Gw.l($CLJS.H([a]));return b};Ww=function(a){a=$CLJS.A(a);return $CLJS.E.g(Uw,a)||$CLJS.E.g(Vw,a)};
$w=function(a,b,c){return $CLJS.hd(Lu(function(d,e){if(Ww(e))return new $CLJS.P(null,2,5,$CLJS.Q,[null,e],null);e=yw(Xw.h(d),e);var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=Nu(f);f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);f=$CLJS.R.j(f,Yw,c);return new $CLJS.P(null,2,5,$CLJS.Q,[null,$CLJS.Se.g(Zw.h(d),new $CLJS.P(null,3,5,$CLJS.Q,[f,e,k],null))],null)},b,a))};
ax=function(a){a=$CLJS.p.h(a).toLowerCase();var b=a.indexOf("e"),c=a.indexOf(".");a=0>b?0>c?new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.p.h(a.length-1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[[a.substring(0,c),a.substring(c+1)].join(""),$CLJS.p.h(c-1)],null):0>c?new $CLJS.P(null,2,5,$CLJS.Q,[a.substring(0,b),a.substring(b+1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[[a.substring(0,1),a.substring(2,b)].join(""),a.substring(b+1)],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);a:if(c=$CLJS.D(b),0<c&&$CLJS.E.g($CLJS.F(b,
$CLJS.D(b)-1),"0"))for(--c;;){if(0>c){b="";break a}if($CLJS.E.g($CLJS.F(b,c),"0"))--c;else{b=b.substring(0,c+1);break a}}a:{c=b;var d=$CLJS.D(c);if(0<d&&$CLJS.E.g($CLJS.F(c,0),"0"))for(var e=0;;){if($CLJS.E.g(e,d)||!$CLJS.E.g($CLJS.F(c,e),"0")){c=c.substring(e);break a}e+=1}}b=$CLJS.D(b)-$CLJS.D(c);a=0<$CLJS.D(a)&&$CLJS.E.g($CLJS.F(a,0),"+")?a.substring(1):a;return $CLJS.td(c)?new $CLJS.P(null,2,5,$CLJS.Q,["0",0],null):new $CLJS.P(null,2,5,$CLJS.Q,[c,parseInt(a,10)-b],null)};
bx=function(a,b,c,d){if($CLJS.n($CLJS.n(c)?c:d)){var e=$CLJS.D(a);d=$CLJS.n(d)?2>d?2:d:0;$CLJS.n(c)?c=b+c+1:0<=b?(c=b+1,--d,c=c>d?c:d):c=d+b;var f=$CLJS.E.g(c,0)?new $CLJS.P(null,4,5,$CLJS.Q,[["0",$CLJS.p.h(a)].join(""),b+1,1,e+1],null):new $CLJS.P(null,4,5,$CLJS.Q,[a,b,c,e],null);c=$CLJS.I(f,0,null);e=$CLJS.I(f,1,null);d=$CLJS.I(f,2,null);f=$CLJS.I(f,3,null);if($CLJS.n(d)){if(0>d)return new $CLJS.P(null,3,5,$CLJS.Q,["0",0,!1],null);if(f>d){b=$CLJS.F(c,d);a=c.substring(0,d);if(Ku(b)>=Ku("5")){a:for(b=
$CLJS.D(a)-1,c=b|0;;){if(0>c){b=$CLJS.Se.j($CLJS.p,"1",$CLJS.df(b+1,"0"));break a}if($CLJS.E.g("9",a.charAt(c)))--c;else{b=$CLJS.Se.v($CLJS.p,a.substring(0,c),Au(Ku(a.charAt(c))+1),$CLJS.df(b-c,"0"));break a}}a=$CLJS.D(b)>a.length;c=$CLJS.Q;a&&(d=$CLJS.D(b)-1,b=b.substring(0,d));return new $CLJS.P(null,3,5,c,[b,e,a],null)}return new $CLJS.P(null,3,5,$CLJS.Q,[a,e,!1],null)}}}return new $CLJS.P(null,3,5,$CLJS.Q,[a,b,!1],null)};
cx=function(a,b,c){var d=0>b?new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(-b-1,"0"))),$CLJS.p.h(a)].join(""),-1],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);a=$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null);d=$CLJS.D(a);c=$CLJS.n(c)?e+c+1:e+1;c=d<c?[$CLJS.p.h(a),$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(c-d,"0")))].join(""):a;0>b?b=[".",$CLJS.p.h(c)].join(""):(b+=1,b=[c.substring(0,b),".",c.substring(b)].join(""));return b};
Lba=function(a,b){return 0>b?[".",$CLJS.p.h(a)].join(""):[a.substring(0,b),".",a.substring(b)].join("")};
hx=function(a,b){var c=dx.h(a),d=ex.h(a);b=pw(b);var e=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var f=0>e?new $CLJS.P(null,2,5,$CLJS.Q,["-",-e],null):new $CLJS.P(null,2,5,$CLJS.Q,["+",e],null),k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);f=ax(f);var l=$CLJS.I(f,0,null),m=$CLJS.I(f,1,null)+$CLJS.fx.h(a);f=function(){var x=vw.h(a);return $CLJS.n(x)?x:0>e}();var t=$CLJS.Va(d)&&$CLJS.D(l)-1<=m,u=bx(l,m,d,$CLJS.n(c)?c-($CLJS.n(f)?1:0):null);l=$CLJS.I(u,0,null);m=$CLJS.I(u,1,null);u=$CLJS.I(u,2,null);l=cx(l,$CLJS.n(u)?
m+1:m,d);d=$CLJS.n($CLJS.n(c)?$CLJS.n(d)?1<=d&&$CLJS.E.g(l.charAt(0),"0")&&$CLJS.E.g(l.charAt(1),".")&&l.length>c-($CLJS.n(f)?1:0):d:c)?l.substring(1):l;m=$CLJS.E.g($CLJS.A(d),".");if($CLJS.n(c)){l=d.length;l=$CLJS.n(f)?l+1:l;m=m&&!(l>=c);t=t&&!(l>=c);var v=m||t?l+1:l;$CLJS.n(function(){var x=v>c;return x?gx.h(a):x}())?Gw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(c,gx.h(a)))])):Gw.l($CLJS.H([[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(c-v,Fw.h(a)))),$CLJS.p.h($CLJS.n(f)?k:null),m?"0":null,d,t?"0":null].join("")]))}else Gw.l($CLJS.H([[$CLJS.p.h($CLJS.n(f)?
k:null),m?"0":null,d,t?"0":null].join("")]));return b};
kx=function(a,b){b=pw(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=ax(0>c?-c:c);$CLJS.I(d,0,null);for($CLJS.I(d,1,null);;){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null),k=dx.h(a),l=ex.h(a),m=ix.h(a),t=$CLJS.fx.h(a),u=function(){var K=jx.h(a);return $CLJS.n(K)?K:"E"}();d=function(){var K=vw.h(a);return $CLJS.n(K)?K:0>c}();var v=0>=t,x=f-(t-1),z=$CLJS.p.h(Math.abs(x));u=[$CLJS.p.h(u),0>x?"-":"+",$CLJS.p.h($CLJS.n(m)?$CLJS.Se.g($CLJS.p,$CLJS.df(m-z.length,"0")):null),z].join("");var C=u.length;
x=$CLJS.D(e);e=[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(-t,"0"))),$CLJS.p.h(e),$CLJS.p.h($CLJS.n(l)?$CLJS.Se.g($CLJS.p,$CLJS.df(l-(x-1)-(0>t?-t:0),"0")):null)].join("");x=$CLJS.n(k)?k-C:null;e=bx(e,0,$CLJS.E.g(t,0)?l-1:0<t?l:0>t?l-1:null,$CLJS.n(x)?x-($CLJS.n(d)?1:0):null);x=$CLJS.I(e,0,null);$CLJS.I(e,1,null);z=$CLJS.I(e,2,null);e=Lba(x,t);l=$CLJS.E.g(t,$CLJS.D(x))&&null==l;if($CLJS.Va(z)){if($CLJS.n(k)){f=e.length+C;f=$CLJS.n(d)?f+1:f;var G=(v=v&&!$CLJS.E.g(f,k))?f+1:f;f=l&&G<k;$CLJS.n(function(){var K=
G>k;K||(K=m,K=$CLJS.n(K)?C-2>m:K);return $CLJS.n(K)?gx.h(a):K}())?Gw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(k,gx.h(a)))])):Gw.l($CLJS.H([[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(k-G-(f?1:0),Fw.h(a)))),$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,f?"0":null,u].join("")]))}else Gw.l($CLJS.H([[$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,l?"0":null,u].join("")]));break}else d=new $CLJS.P(null,2,5,$CLJS.Q,[x,f+1],null)}return b};
Mba=function(a,b,c){var d=pw(b),e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);d=ax(0>e?-e:e);var f=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var k=dx.h(a),l=ex.h(a),m=ix.h(a);d=$CLJS.E.g(e,0)?0:d+1;e=$CLJS.n(m)?m+2:4;k=$CLJS.n(k)?k-e:null;$CLJS.n(l)?f=l:(f=$CLJS.D(f),l=7>d?d:7,f=f>l?f:l);d=f-d;return 0<=d&&d<=f?(a=hx(new $CLJS.h(null,6,[dx,k,ex,d,$CLJS.fx,0,gx,gx.h(a),Fw,Fw.h(a),vw,vw.h(a)],null),b,c),Gw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(e," "))])),a):kx(a,b,c)};
Nba=function(a,b){b=pw(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=ax(Math.abs(c)),e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null),k=ex.h(a),l=$CLJS.rk.h(a);d=dx.h(a);var m=function(){var u=vw.h(a);return $CLJS.n(u)?u:0>c}(),t=bx(e,f,k,null);e=$CLJS.I(t,0,null);f=$CLJS.I(t,1,null);t=$CLJS.I(t,2,null);k=cx(e,$CLJS.n(t)?f+1:f,k);l=[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(l-k.indexOf("."),"0"))),k].join("");k=l.length+($CLJS.n(m)?1:0);Gw.l($CLJS.H([[$CLJS.n(function(){var u=uw.h(a);return $CLJS.n(u)?
m:u}())?0>c?"-":"+":null,$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(d-k,Fw.h(a)))),$CLJS.n(function(){var u=$CLJS.Va(uw.h(a));return u?m:u}())?0>c?"-":"+":null,l].join("")]));return b};Oba=function(a,b){var c=lx.h(a);c=$CLJS.n(c)?new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null):pw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var d=$CLJS.mx.h(a);b=0>b||b>=$CLJS.D(d)?$CLJS.A($CLJS.Rt.h(a)):$CLJS.F(d,b);return $CLJS.n(b)?$w(b,c,Yw.h(a)):c};
Pba=function(a,b){var c=pw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var d=$CLJS.mx.h(a);b=$CLJS.n(b)?$CLJS.hd(d):$CLJS.A(d);return $CLJS.n(b)?$w(b,c,Yw.h(a)):c};Qba=function(a,b){var c=pw(b),d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.mx.h(a);e=$CLJS.n(d)?$CLJS.A(e):null;return $CLJS.n(d)?$CLJS.n(e)?$w(e,b,Yw.h(a)):b:c};
Rba=function(a,b){var c=nx.h(a),d=$CLJS.A($CLJS.mx.h(a));d=$CLJS.td(d)?qw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=pw(d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=0;e=hw(e);for(var k=-1;;){if($CLJS.Va(c)&&$CLJS.E.g(zv.h(e),k)&&1<f)throw Error("%{ construct not consuming any arguments: Infinite loop!");k=$CLJS.td($CLJS.Pi.h(e))&&($CLJS.Va(uw.h(ox.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=$w(b,e,Yw.h(a));if($CLJS.E.g(Uw,$CLJS.A(k)))return d;
f+=1;var l=zv.h(e);e=k;k=l}};Sba=function(a,b){var c=nx.h(a),d=$CLJS.A($CLJS.mx.h(a));d=$CLJS.td(d)?qw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=pw(d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);for(var f=0;;){var k=$CLJS.td(e)&&($CLJS.Va(uw.h(ox.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=$w(b,hw($CLJS.A(e)),hw($CLJS.B(e)));if($CLJS.E.g(Vw,$CLJS.A(k)))return d;f+=1;e=$CLJS.B(e)}};
Tba=function(a,b){var c=nx.h(a),d=$CLJS.A($CLJS.mx.h(a)),e=$CLJS.td(d)?qw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(e,0,null);d=0;e=$CLJS.I(e,1,null);for(var f=-1;;){if($CLJS.Va(c)&&$CLJS.E.g(zv.h(e),f)&&1<d)throw Error("%@{ construct not consuming any arguments: Infinite loop!");f=$CLJS.td($CLJS.Pi.h(e))&&($CLJS.Va(uw.h(ox.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=$w(b,e,Yw.h(a));if($CLJS.E.g(Uw,$CLJS.A(f)))return $CLJS.hd(f);d+=1;var k=zv.h(e);e=f;f=k}};
Uba=function(a,b){var c=nx.h(a),d=$CLJS.A($CLJS.mx.h(a)),e=$CLJS.td(d)?qw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(e,0,null);d=0;for(e=$CLJS.I(e,1,null);;){var f=$CLJS.td($CLJS.Pi.h(e))&&($CLJS.Va(uw.h(ox.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=$CLJS.Pi.h(e);f=$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(f),new nw(ow.h(e),$CLJS.B(f),zv.h(e)+1,null,null,null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,e],null);e=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);e=$w(b,hw(e),f);
if($CLJS.E.g(Vw,$CLJS.A(e)))return f;e=f;d+=1}};Wba=function(a,b){if($CLJS.n(uw.h(ox.h(a)))){var c=$CLJS.mx.h(a),d=$CLJS.D(c),e=1<d?$CLJS.Zj.h(Xw.h($CLJS.A($CLJS.A(c)))):$CLJS.n(uw.h(a))?"(":null,f=$CLJS.F(c,1<d?1:0);c=2<d?$CLJS.Zj.h(Xw.h($CLJS.A($CLJS.F(c,2)))):$CLJS.n(uw.h(a))?")":null;d=pw(b);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);if($CLJS.n(Xv()))$CLJS.ac($CLJS.Iu,"#");else{var k=Wv,l=Rv;Wv+=1;Rv=0;try{Lv(e,c),$w(f,hw(b),Yw.h(a)),Pv()}finally{Rv=l,Wv=k}}a=d}else a=Vba(a,b);return a};
px=function(a,b,c){for(var d=$CLJS.Cf;;){if($CLJS.td(a))return new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);var e=$CLJS.A(a);a:{var f=new $CLJS.Ea,k=$CLJS.Iu;$CLJS.Iu=new $CLJS.sc(f);try{var l=new $CLJS.P(null,2,5,$CLJS.Q,[$w(e,b,c),$CLJS.p.h(f)],null);break a}finally{$CLJS.Iu=k}l=void 0}b=$CLJS.I(l,0,null);e=$CLJS.I(l,1,null);if($CLJS.E.g(Uw,$CLJS.A(b)))return new $CLJS.P(null,2,5,$CLJS.Q,[d,$CLJS.hd(b)],null);a=$CLJS.B(a);d=$CLJS.be.g(d,e)}};
Vba=function(a,b){var c=function(){var V=$CLJS.Rt.h(a);return $CLJS.n(V)?px(V,b,Yw.h(a)):null}(),d=$CLJS.I(c,0,null);d=$CLJS.I(d,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.n(c)?c:b;c=function(){var V=qx.h(a);return $CLJS.n(V)?yw(V,e):null}();var f=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var k=$CLJS.n(c)?c:e;c=function(){var V=$CLJS.A(rx.h(f));return $CLJS.n(V)?V:0}();var l=function(){var V=$CLJS.A(sx.h(f));return $CLJS.n(V)?V:$CLJS.Pu($CLJS.Iu,$CLJS.jk)}(),m=$CLJS.mx.h(a);k=px(m,k,Yw.h(a));var t=$CLJS.I(k,
0,null);k=$CLJS.I(k,1,null);var u=function(){var V=$CLJS.D(t)-1+($CLJS.n(uw.h(a))?1:0)+($CLJS.n(vw.h(a))?1:0);return 1>V?1:V}();m=$CLJS.Qd($CLJS.Jk,$CLJS.hf.g($CLJS.D,t));var v=Dw.h(a),x=Cw.h(a),z=Ew.h(a),C=m+u*x;v=C<=v?v:v+z*(1+$CLJS.Vd(C-v-1,z));var G=v-m;m=function(){var V=$CLJS.Vd(G,u);return x>V?x:V}();z=G-m*u;m=$CLJS.Se.g($CLJS.p,$CLJS.df(m,Fw.h(a)));$CLJS.n($CLJS.n(d)?$CLJS.Pu(Su.h($CLJS.q($CLJS.q($CLJS.Iu))),$CLJS.Ru)+c+v>l:d)&&Gw.l($CLJS.H([d]));c=z;for(var K=t,S=function(){var V=uw.h(a);
return $CLJS.n(V)?V:$CLJS.E.g($CLJS.D(K),1)&&$CLJS.Va(vw.h(a))}();;)if($CLJS.y(K))Gw.l($CLJS.H([[$CLJS.p.h($CLJS.Va(S)?$CLJS.A(K):null),$CLJS.p.h($CLJS.n(function(){var V=S;return $CLJS.n(V)?V:(V=$CLJS.B(K))?V:vw.h(a)}())?m:null),$CLJS.p.h(0<c?Fw.h(a):null)].join("")])),--c,K=d=$CLJS.n(S)?K:$CLJS.B(K),S=!1;else break;return k};tx=function(a,b){this.Ca=a;this.Uh=b;this.C=1074135040;this.I=0};Xba=function(a){return new tx(a,$CLJS.N)};ux=function(a,b){this.Ca=a;this.Vh=b;this.C=1074135040;this.I=0};
Yba=function(a){return new ux(a,$CLJS.N)};
Zba=function(a,b){var c=$CLJS.A(a);return $CLJS.Se.g($CLJS.p,$CLJS.A(Mu(function(d){if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,null],null);var e=RegExp("\\W\\w","g").exec(d);e=$CLJS.n(e)?e.index+1:e;return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[[d.substring(0,e),$CLJS.F(d,e).toUpperCase()].join(""),d.substring(e+1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,null],null)},$CLJS.n($CLJS.n(b)?$CLJS.n(c)?vu(c):c:b)?[c.toUpperCase(),a.substring(1)].join(""):a)))};
vx=function(a,b,c){this.Ca=a;this.Jd=b;this.Xh=c;this.C=1074135040;this.I=0};$ba=function(a){return new vx(a,$CLJS.Xe(!0),$CLJS.N)};wx=function(a,b,c){this.Ca=a;this.vd=b;this.Yh=c;this.C=1074135040;this.I=0};aca=function(a){return new wx(a,$CLJS.Xe(!1),$CLJS.N)};bca=function(a,b){var c=$CLJS.n(uw.h(a))?$CLJS.dw:cw;fw(c,$CLJS.rk.h(a));return b};cca=function(a,b){a=$CLJS.n(uw.h(a))?$CLJS.n(vw.h(a))?Yv:$v:$CLJS.n(vw.h(a))?Zv:aw;bw(a);return b};
eca=function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null);a=new RegExp(dca.source,"g");var e=a.exec(b);return $CLJS.n(e)?(d=$CLJS.A(e),b=b.substring(a.lastIndex),a=c+a.lastIndex,$CLJS.E.g(",",$CLJS.F(b,0))?new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[b.substring(1),a+1,!0],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[b,a,!1],null)],null)):$CLJS.n(d)?mw("Badly formed parameters in format directive",
c):new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,2,5,$CLJS.Q,[b,c],null)],null)};fca=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.E.g(b.length,0)?null:$CLJS.E.g(b.length,1)&&$CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,2,["V",null,"v",null],null),null),$CLJS.F(b,0))?ww:$CLJS.E.g(b.length,1)&&$CLJS.E.g("#",$CLJS.F(b,0))?xw:$CLJS.E.g(b.length,2)&&$CLJS.E.g("'",$CLJS.F(b,0))?$CLJS.F(b,1):parseInt(b,10),a],null)};
hca=function(a,b){return Mu(function(c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c],null)],null);var f=$CLJS.J.g(gca,$CLJS.A(d));return $CLJS.n(f)?$CLJS.Hd(c,f)?mw(['Flag "',$CLJS.p.h($CLJS.A(d)),'" appears more than once in a directive'].join(""),e):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[d.substring(1),e+1,$CLJS.R.j(c,f,new $CLJS.P(null,2,5,$CLJS.Q,[!0,e],null))],
null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c],null)],null)},new $CLJS.P(null,3,5,$CLJS.Q,[a,b,$CLJS.N],null))};
ica=function(a,b){var c=xx.h(a);$CLJS.n(function(){var d=$CLJS.Va(vw.h(c));return d?vw.h(b):d}())&&mw(['"@" is an illegal flag for format directive "',$CLJS.p.h(yx.h(a)),'"'].join(""),$CLJS.F(vw.h(b),1));$CLJS.n(function(){var d=$CLJS.Va(uw.h(c));return d?uw.h(b):d}())&&mw(['":" is an illegal flag for format directive "',$CLJS.p.h(yx.h(a)),'"'].join(""),$CLJS.F(uw.h(b),1));$CLJS.n(function(){var d=$CLJS.Va(zx.h(c));return d?(d=vw.h(b),$CLJS.n(d)?uw.h(b):d):d}())&&mw(['Cannot combine "@" and ":" flags for format directive "',
$CLJS.p.h(yx.h(a)),'"'].join(""),function(){var d=$CLJS.F(uw.h(b),1),e=$CLJS.F(vw.h(b),1);return d<e?d:e}())};
kca=function(a,b,c,d){ica(a,c);$CLJS.D(b)>$CLJS.D(Xw.h(a))&&mw(kw(null,'Too many parameters for directive "~C": ~D~:* ~[were~;was~:;were~] specified but only ~D~:* ~[are~;is~:;are~] allowed',$CLJS.H([yx.h(a),$CLJS.D(b),$CLJS.D(Xw.h(a))])),$CLJS.hd($CLJS.A(b)));$CLJS.mh($CLJS.hf.j(function(e,f){var k=$CLJS.A(e);return null==k||$CLJS.Hd(jca,k)||$CLJS.E.g($CLJS.hd($CLJS.hd(f)),$CLJS.ab(k))?null:mw(["Parameter ",$CLJS.gh($CLJS.A(f)),' has bad type in directive "',$CLJS.p.h(yx.h(a)),'": ',$CLJS.p.h($CLJS.ab(k))].join(""),
$CLJS.hd(e))},b,Xw.h(a)));return $CLJS.gl.l($CLJS.H([$CLJS.eg.g($CLJS.N,$CLJS.ce(function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.I(x,0,null);u.add(new $CLJS.P(null,2,5,$CLJS.Q,[z,new $CLJS.P(null,2,5,$CLJS.Q,[x,d],null)],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):
$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.I(m,0,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,new $CLJS.P(null,2,5,$CLJS.Q,[m,d],null)],null),k($CLJS.Lc(l)))}return null}},null,null)}(Xw.h(a))}())),$CLJS.fb(function(e,f){return $CLJS.Se.j($CLJS.R,e,f)},$CLJS.N,$CLJS.lf(function(e){return $CLJS.A($CLJS.F(e,1))},$CLJS.Du($CLJS.Xg(Xw.h(a)),b))),c]))};
mca=function(a,b){b=Mu(eca,new $CLJS.P(null,3,5,$CLJS.Q,[a,b,!1],null));a=$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);b=hca(b,c);$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);c=$CLJS.A(d);var f=$CLJS.J.g(lca,c.toUpperCase()),k=$CLJS.n(f)?kca(f,$CLJS.hf.g(fca,a),b,e):null;$CLJS.Va(c)&&mw("Format string ended in the middle of a directive",e);$CLJS.Va(f)&&mw(['Directive "',$CLJS.p.h(c),'" is undefined'].join(""),
e);return new $CLJS.P(null,2,5,$CLJS.Q,[new tw(function(){var l=Ax.h(f);return l.g?l.g(k,e):l.call(null,k,e)}(),f,k,e,null,null,null),function(){var l=d.substring(1),m=e+1;if($CLJS.E.g("\n",yx.h(f))&&$CLJS.Va(uw.h(k)))a:{var t=new $CLJS.P(null,2,5,$CLJS.Q,[" ","\t"],null);t=$CLJS.ud(t)?$CLJS.fh(t):$CLJS.ch([t]);for(var u=0;;){var v;(v=$CLJS.E.g(u,$CLJS.D(l)))||(v=$CLJS.F(l,u),v=t.h?t.h(v):t.call(null,v),v=$CLJS.Va(v));if(v){t=u;break a}u+=1}}else t=0;return new $CLJS.P(null,2,5,$CLJS.Q,[l.substring(t),
m+t],null)}()],null)};Bx=function(a,b){return new tw(function(c,d){Gw.l($CLJS.H([a]));return d},null,new $CLJS.h(null,1,[$CLJS.Zj,a],null),b,null,null,null)};Ex=function(a,b){var c=nca(Cx.h(Dx.h(a)),$CLJS.St.h(a),b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[new tw(Zw.h(a),Dx.h(a),$CLJS.gl.l($CLJS.H([Xw.h(a),wba(b,$CLJS.St.h(a))])),$CLJS.St.h(a),null,null,null),c],null)};
oca=function(a,b,c){return Mu(function(d){if($CLJS.td(d))return mw("No closing bracket found.",b);var e=$CLJS.A(d);d=$CLJS.B(d);if($CLJS.n($CLJS.Fx.h(Cx.h(Dx.h(e)))))e=Ex(e,d);else if($CLJS.E.g($CLJS.Fx.h(a),yx.h(Dx.h(e))))e=new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[Gx,Xw.h(e),null,d],null)],null);else{var f=$CLJS.Tt.h(Cx.h(Dx.h(e)));f=$CLJS.n(f)?uw.h(Xw.h(e)):f;e=$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Rt,null,Xw.h(e),d],null)],null):
$CLJS.n($CLJS.Tt.h(Cx.h(Dx.h(e))))?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Tt,null,null,d],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[e,d],null)}return e},c)};
nca=function(a,b,c){return $CLJS.hd(Mu(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);d=$CLJS.I(d,2,null);var k=oca(a,b,d);d=$CLJS.I(k,0,null);var l=$CLJS.I(k,1,null);k=$CLJS.I(l,0,null);var m=$CLJS.I(l,1,null),t=$CLJS.I(l,2,null);l=$CLJS.I(l,3,null);return $CLJS.E.g(k,Gx)?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hx.l($CLJS.gf,$CLJS.H([e,$CLJS.Ee([$CLJS.n(f)?$CLJS.Rt:$CLJS.mx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null),ox,m])])),l],null)],null):$CLJS.E.g(k,$CLJS.Rt)?
$CLJS.n($CLJS.Rt.h(e))?mw('Two else clauses ("~:;") inside bracket construction.',b):$CLJS.Va($CLJS.Rt.h(a))?mw('An else clause ("~:;") is in a bracket type that doesn\'t support it.',b):$CLJS.E.g(Ix,$CLJS.Rt.h(a))&&$CLJS.y($CLJS.mx.h(e))?mw('The else clause ("~:;") is only allowed in the first position for this directive.',b):$CLJS.E.g(Ix,$CLJS.Rt.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hx.l($CLJS.gf,$CLJS.H([e,new $CLJS.h(null,2,[$CLJS.Rt,new $CLJS.P(null,1,5,
$CLJS.Q,[d],null),qx,t],null)])),!1,l],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hx.l($CLJS.gf,$CLJS.H([e,new $CLJS.h(null,1,[$CLJS.mx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null)],null)])),!0,l],null)],null):$CLJS.E.g(k,$CLJS.Tt)?$CLJS.n(f)?mw('A plain clause (with "~;") follows an else clause ("~:;") inside bracket construction.',b):$CLJS.Va(Jx.h(a))?mw('A separator ("~;") is in a bracket type that doesn\'t support it.',b):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.Hx.l($CLJS.gf,$CLJS.H([e,new $CLJS.h(null,1,[$CLJS.mx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null)],null)])),!1,l],null)],null):null},new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.h(null,1,[$CLJS.mx,$CLJS.Cf],null),!1,c],null)))};pca=function(a){return $CLJS.A(Mu(function(b){var c=$CLJS.A(b);b=$CLJS.B(b);var d=Cx.h(Dx.h(c));return $CLJS.n($CLJS.Fx.h(d))?Ex(c,b):new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)},a))};
gw=function(a){var b=lw;lw=a;try{return pca($CLJS.A(Mu(function(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);var e=d.indexOf("~");return 0>e?new $CLJS.P(null,2,5,$CLJS.Q,[Bx(d,c),new $CLJS.P(null,2,5,$CLJS.Q,["",c+d.length],null)],null):0===e?mca(d.substring(1),c+1):new $CLJS.P(null,2,5,$CLJS.Q,[Bx(d.substring(0,e),c),new $CLJS.P(null,2,5,$CLJS.Q,[d.substring(e),e+c],null)],null)},new $CLJS.P(null,2,5,$CLJS.Q,[a,0],null))))}finally{lw=
b}};jw=function(a,b){Lu(function(c,d){if(Ww(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);d=yw(Xw.h(c),d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=Nu(e);e=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);e=$CLJS.R.j(e,Yw,d);return new $CLJS.P(null,2,5,$CLJS.Q,[null,$CLJS.Se.g(Zw.h(c),new $CLJS.P(null,3,5,$CLJS.Q,[e,d,f],null))],null)},b,a);return null};
Lx=function(a){var b=$CLJS.A(a);b=Kx.h?Kx.h(b):Kx.call(null,b);return $CLJS.n($CLJS.n(b)?$CLJS.E.g(2,$CLJS.D(a)):b)?($CLJS.ac($CLJS.Iu,b),$CLJS.Uv($CLJS.hd(a)),!0):null};
Mx=function(a){if($CLJS.n(Xv()))$CLJS.ac($CLJS.Iu,"#");else{var b=Wv,c=Rv;Wv+=1;Rv=0;try{Lv("[","]");for(var d=0,e=$CLJS.y(a);;){if($CLJS.Va($CLJS.Na)||d<$CLJS.Na){if(e&&($CLJS.Uv($CLJS.A(e)),$CLJS.B(e))){$CLJS.ac($CLJS.Iu," ");bw(aw);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.ac($CLJS.Iu,"...");break}Pv()}finally{Rv=c,Wv=b}}return null};
Nx=function(a){var b=$CLJS.yd(a)?null:function(){var m=new $CLJS.Gc(function(){return $CLJS.Hh},qca,$CLJS.Ig([$CLJS.wk,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[!0,$CLJS.mi,rca,"cljs/core.cljs",15,1,10543,10543,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ej],null)),"Returns [lifted-ns lifted-map] or nil if m can't be lifted.",$CLJS.n($CLJS.Hh)?$CLJS.Hh.H:null]));return m.h?m.h(a):m.call(null,a)}(),c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.n(b)?
b:a,e=$CLJS.n(c)?["#:",$CLJS.p.h(c),"{"].join(""):"{";if($CLJS.n(Xv()))$CLJS.ac($CLJS.Iu,"#");else{c=Wv;b=Rv;Wv+=1;Rv=0;try{Lv(e,"}");e=0;for(var f=$CLJS.y(d);;){if($CLJS.Va($CLJS.Na)||e<$CLJS.Na){if(f){if($CLJS.n(Xv()))$CLJS.ac($CLJS.Iu,"#");else{d=Wv;var k=Rv;Wv+=1;Rv=0;try{Lv(null,null),$CLJS.Uv($CLJS.A($CLJS.A(f))),$CLJS.ac($CLJS.Iu," "),bw(aw),Rv=0,$CLJS.Uv($CLJS.A($CLJS.B($CLJS.A(f)))),Pv()}finally{Rv=k,Wv=d}}if($CLJS.B(f)){$CLJS.ac($CLJS.Iu,", ");bw(aw);d=e+1;var l=$CLJS.B(f);e=d;f=l;continue}}}else $CLJS.ac($CLJS.Iu,
"...");break}Pv()}finally{Rv=b,Wv=c}}return null};Ox=function(a){return $CLJS.ac($CLJS.Iu,$CLJS.Mh.l($CLJS.H([a])))};Rx=function(a){return a instanceof $CLJS.Rf?Px:(null!=a?a.C&32768||$CLJS.Bc===a.ag||(a.C?0:$CLJS.$a($CLJS.wu,a)):$CLJS.$a($CLJS.wu,a))?Qx:a instanceof $CLJS.r?$CLJS.Hi:$CLJS.Dd(a)?$CLJS.Cj:$CLJS.xd(a)?$CLJS.ik:$CLJS.zd(a)?$CLJS.Kj:$CLJS.vd(a)?$CLJS.$i:null==a?null:$CLJS.di};
Sx=function(a){return $CLJS.zd(a)?new $CLJS.P(null,2,5,$CLJS.Q,["[","]"],null):new $CLJS.P(null,2,5,$CLJS.Q,["(",")"],null)};
sca=function(a){if($CLJS.wd(a)){var b=Sx(a),c=$CLJS.I(b,0,null),d=$CLJS.I(b,1,null),e=$CLJS.y(a),f=$CLJS.A(e),k=$CLJS.B(e);if($CLJS.n(Xv()))$CLJS.ac($CLJS.Iu,"#");else{var l=Wv,m=Rv,t=Wv+1;Wv=t;Rv=0;try{Lv(c,d);(function(){var Ma=Tx("~w~:i");return function(){function zb(Ya){var eb=null;if(0<arguments.length){eb=0;for(var Sa=Array(arguments.length-0);eb<Sa.length;)Sa[eb]=arguments[eb+0],++eb;eb=new $CLJS.w(Sa,0,null)}return Oa.call(this,eb)}function Oa(Ya){Ya=hw(Ya);return jw(Ma,Ya)}zb.A=0;zb.B=function(Ya){Ya=
$CLJS.y(Ya);return Oa(Ya)};zb.l=Oa;return zb}()})()(f);for(var u=k;;)if($CLJS.y(u)){(function(){var Ma=Tx(" ");return function(zb,Oa,Ya){return function(){function eb(mb){var Ia=null;if(0<arguments.length){Ia=0;for(var bc=Array(arguments.length-0);Ia<bc.length;)bc[Ia]=arguments[Ia+0],++Ia;Ia=new $CLJS.w(bc,0,null)}return Sa.call(this,Ia)}function Sa(mb){mb=hw(mb);return jw(Ya,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u," ",Ma,l,m,t,0,b,c,d,a,e,f,k,f,k)})()();
var v=$CLJS.A(u);if($CLJS.wd(v)){var x=Sx(v),z=$CLJS.I(x,0,null),C=$CLJS.I(x,1,null);if($CLJS.n(Xv()))$CLJS.ac($CLJS.Iu,"#");else{var G=Wv,K=Rv,S=Wv+1;Wv=S;Rv=0;try{Lv(z,C);if($CLJS.E.g($CLJS.D(v),3)&&$CLJS.hd(v)instanceof $CLJS.M){var V=v,Z=$CLJS.I(V,0,null),ha=$CLJS.I(V,1,null),ra=$CLJS.I(V,2,null);(function(){var Ma=Tx("~w ~w ");return function(zb,Oa,Ya){return function(){function eb(mb){var Ia=null;if(0<arguments.length){Ia=0;for(var bc=Array(arguments.length-0);Ia<bc.length;)bc[Ia]=arguments[Ia+
0],++Ia;Ia=new $CLJS.w(bc,0,null)}return Sa.call(this,Ia)}function Sa(mb){mb=hw(mb);return jw(Ya,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u,"~w ~w ",Ma,V,Z,ha,ra,G,K,S,0,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)})()(Z,ha);$CLJS.wd(ra)?function(){var Ma=$CLJS.zd(ra)?"~\x3c[~;~@{~w~^ ~:_~}~;]~:\x3e":"~\x3c(~;~@{~w~^ ~:_~}~;)~:\x3e",zb="string"===typeof Ma?Tx(Ma):Ma;return function(Oa,Ya,eb){return function(){function Sa(Ia){var bc=null;if(0<arguments.length){bc=0;for(var Zd=
Array(arguments.length-0);bc<Zd.length;)Zd[bc]=arguments[bc+0],++bc;bc=new $CLJS.w(Zd,0,null)}return mb.call(this,bc)}function mb(Ia){Ia=hw(Ia);return jw(eb,Ia)}Sa.A=0;Sa.B=function(Ia){Ia=$CLJS.y(Ia);return mb(Ia)};Sa.l=mb;return Sa}()}(u,Ma,zb,V,Z,ha,ra,G,K,S,0,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()(ra):$CLJS.Uv(ra)}else $CLJS.Se.g(function(){var Ma=Tx("~w ~:i~@{~w~^ ~:_~}");return function(zb,Oa,Ya){return function(){function eb(mb){var Ia=null;if(0<arguments.length){Ia=0;for(var bc=Array(arguments.length-
0);Ia<bc.length;)bc[Ia]=arguments[Ia+0],++Ia;Ia=new $CLJS.w(bc,0,null)}return Sa.call(this,Ia)}function Sa(mb){mb=hw(mb);return jw(Ya,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u,"~w ~:i~@{~w~^ ~:_~}",Ma,G,K,S,0,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}(),v);Pv()}finally{Rv=K,Wv=G}}$CLJS.B(u)&&function(){var Ma=Tx("~_");return function(zb,Oa,Ya){return function(){function eb(mb){var Ia=null;if(0<arguments.length){Ia=0;for(var bc=Array(arguments.length-0);Ia<bc.length;)bc[Ia]=
arguments[Ia+0],++Ia;Ia=new $CLJS.w(bc,0,null)}return Sa.call(this,Ia)}function Sa(mb){mb=hw(mb);return jw(Ya,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u,"~_",Ma,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()()}else $CLJS.Uv(v),$CLJS.B(u)&&function(){var Ma=Tx("~:_");return function(zb,Oa,Ya){return function(){function eb(mb){var Ia=null;if(0<arguments.length){Ia=0;for(var bc=Array(arguments.length-0);Ia<bc.length;)bc[Ia]=arguments[Ia+0],++Ia;Ia=new $CLJS.w(bc,0,null)}return Sa.call(this,
Ia)}function Sa(mb){mb=hw(mb);return jw(Ya,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u,"~:_",Ma,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()();u=$CLJS.B(u)}else break;Pv()}finally{Rv=m,Wv=l}}}else $CLJS.Uv(a)};
tca=function(a,b){$CLJS.y(a)&&($CLJS.n(b)?function(){var c=Tx(" ~_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=hw(f);return jw(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;return d}()}()():function(){var c=Tx(" ~@_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<
l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=hw(f);return jw(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;return d}()}()(),function(){var c=Tx("~{~w~^ ~_~}");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=hw(f);return jw(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;
return d}()}()(a))};uca=function(a){$CLJS.y(a)&&function(){var b=Tx(" ~_~{~w~^ ~_~}");return function(){function c(e){var f=null;if(0<arguments.length){f=0;for(var k=Array(arguments.length-0);f<k.length;)k[f]=arguments[f+0],++f;f=new $CLJS.w(k,0,null)}return d.call(this,f)}function d(e){e=hw(e);return jw(b,e)}c.A=0;c.B=function(e){e=$CLJS.y(e);return d(e)};c.l=d;return c}()}()(a)};
Vx=function(a){if($CLJS.B(a)){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d="string"===typeof $CLJS.A(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(c),$CLJS.B(c)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,c],null);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var e=$CLJS.xd($CLJS.A(d))?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(d),$CLJS.B(d)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);d=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n(Xv()))$CLJS.ac($CLJS.Iu,"#");else{var f=
Wv,k=Rv;Wv+=1;Rv=0;try{Lv("(",")"),function(){var l=Tx("~w ~1I~@_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=hw(u);return jw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}()(a,b),$CLJS.n(c)&&function(){var l=Tx(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-
0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=hw(u);return jw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}()(c),$CLJS.n(d)&&function(){var l=Tx(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=hw(u);return jw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};
m.l=t;return m}()}()(d),$CLJS.zd($CLJS.A(e))?tca(e,$CLJS.n(c)?c:d):uca(e),Pv()}finally{Rv=k,Wv=f}}return null}return Ux(a)};
vca=function(a){if($CLJS.n(Xv()))$CLJS.ac($CLJS.Iu,"#");else{var b=Wv,c=Rv;Wv+=1;Rv=0;try{Lv("[","]");for(var d=0;;){if($CLJS.Va($CLJS.Na)||d<$CLJS.Na){if($CLJS.y(a)){if($CLJS.n(Xv()))$CLJS.ac($CLJS.Iu,"#");else{var e=Wv,f=Rv;Wv+=1;Rv=0;try{Lv(null,null),$CLJS.Uv($CLJS.A(a)),$CLJS.B(a)&&($CLJS.ac($CLJS.Iu," "),bw(Zv),$CLJS.Uv($CLJS.hd(a))),Pv()}finally{Rv=f,Wv=e}}if($CLJS.B($CLJS.Lc(a))){$CLJS.ac($CLJS.Iu," ");bw(aw);e=d+1;var k=$CLJS.B($CLJS.Lc(a));d=e;a=k;continue}}}else $CLJS.ac($CLJS.Iu,"...");
break}Pv()}finally{Rv=c,Wv=b}}};
Wx=function(a){var b=$CLJS.A(a);if($CLJS.n(Xv()))$CLJS.ac($CLJS.Iu,"#");else{var c=Wv,d=Rv;Wv+=1;Rv=0;try{Lv("(",")"),$CLJS.B(a)&&$CLJS.zd($CLJS.hd(a))?(function(){var e=Tx("~w ~1I~@_");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.w(t,0,null)}return k.call(this,m)}function k(l){l=hw(l);return jw(e,l)}f.A=0;f.B=function(l){l=$CLJS.y(l);return k(l)};f.l=k;return f}()}()(b),vca($CLJS.hd(a)),function(){var e=
Tx(" ~_~{~w~^ ~_~}");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.w(t,0,null)}return k.call(this,m)}function k(l){l=hw(l);return jw(e,l)}f.A=0;f.B=function(l){l=$CLJS.y(l);return k(l)};f.l=k;return f}()}()($CLJS.B($CLJS.Lc(a)))):Ux(a),Pv()}finally{Rv=d,Wv=c}}return null};
Ux=function(a){if($CLJS.n(Xv()))$CLJS.ac($CLJS.Iu,"#");else{var b=Wv,c=Rv;Wv+=1;Rv=0;try{Lv("(",")");fw(cw,1);for(var d=0,e=$CLJS.y(a);;){if($CLJS.Va($CLJS.Na)||d<$CLJS.Na){if(e&&($CLJS.Uv($CLJS.A(e)),$CLJS.B(e))){$CLJS.ac($CLJS.Iu," ");bw(aw);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.ac($CLJS.Iu,"...");break}Pv()}finally{Rv=c,Wv=b}}return null};$CLJS.ne.prototype.Lc=$CLJS.va(2,function(){return $CLJS.Va(this.Rb)});$CLJS.cf.prototype.Lc=$CLJS.va(1,function(){return!1});
$CLJS.Jh.prototype.Lc=$CLJS.va(0,function(){return $CLJS.Va(this.f)});$CLJS.Iu=null;Xx=function Xx(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Xx.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};Xx.l=function(a){a=$CLJS.y(a);for(var b=$CLJS.ec($CLJS.gg);;)if(a){var c=$CLJS.B(a);if(null==c)throw Error(["No value supplied for key: ",$CLJS.p.h($CLJS.A(a))].join(""));var d=$CLJS.B(c);b=$CLJS.hg.j(b,$CLJS.A(a),$CLJS.A(c));a=d}else return $CLJS.gc(b)};
Xx.A=0;Xx.B=function(a){return this.l($CLJS.y(a))};$CLJS.Hx=function Hx(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Hx.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
$CLJS.Hx.l=function(a,b){if($CLJS.n($CLJS.Re($CLJS.Td,b))){var c=function(d,e){var f=$CLJS.Eb(e),k=$CLJS.Fb(e);if($CLJS.Hd(d,f)){e=$CLJS.R.j;var l=$CLJS.J.g(d,f);k=a.g?a.g(l,k):a.call(null,l,k);d=e.call($CLJS.R,d,f,k)}else d=$CLJS.R.j(d,f,k);return d};return $CLJS.Qd(function(d,e){return $CLJS.fb(c,$CLJS.n(d)?d:$CLJS.N,$CLJS.y(e))},b)}return null};$CLJS.Hx.A=1;$CLJS.Hx.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
$CLJS.Lw=function Lw(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Lw.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};$CLJS.Lw.l=function(a){return $CLJS.Eh(a,$CLJS.R.j($CLJS.Pa(),$CLJS.Ga,!1))};$CLJS.Lw.A=0;$CLJS.Lw.B=function(a){return this.l($CLJS.y(a))};dv=new $CLJS.M(null,"trailing-white-space","trailing-white-space",1496006996);Yx=new $CLJS.M(null,"relative-to","relative-to",-470100051);rv=new $CLJS.M(null,"intra-block-nl","intra-block-nl",1808826875);
jx=new $CLJS.M(null,"exponentchar","exponentchar",1986664222);wca=new $CLJS.r(null,"when-let","when-let",-1383043480,null);Zv=new $CLJS.M(null,"miser","miser",-556060186);$CLJS.dw=new $CLJS.M(null,"current","current",-1088038603);xca=new $CLJS.r(null,"-\x3e","-\x3e",-2139605430,null);yca=new $CLJS.r(null,"..","..",-300507420,null);gv=new $CLJS.M(null,"end-pos","end-pos",-1643883926);pv=new $CLJS.M(null,"per-line-prefix","per-line-prefix",846941813);Px=new $CLJS.M(null,"queue","queue",1455835879);
zca=new $CLJS.r(null,"if-let","if-let",1803593690,null);Nv=new $CLJS.M(null,"end","end",-268185958);xw=new $CLJS.M(null,"remaining-arg-count","remaining-arg-count",-1216589335);xv=new $CLJS.M(null,"logical-blocks","logical-blocks",-1466339776);Aca=new $CLJS.r(null,"defmacro","defmacro",2054157304,null);Bca=new $CLJS.r(null,"when-first","when-first",821699168,null);Kv=new $CLJS.M(null,"start-block-t","start-block-t",-373430594);Cca=new $CLJS.r(null,"binding","binding",-2114503176,null);
Cx=new $CLJS.M(null,"bracket-info","bracket-info",-1600092774);Bv=new $CLJS.M(null,"writing","writing",-1486865108);ww=new $CLJS.M(null,"parameter-from-args","parameter-from-args",-758446196);Iv=new $CLJS.M(null,"logical-block-callback","logical-block-callback",1612691194);lx=new $CLJS.M(null,"selector","selector",762528866);Dca=new $CLJS.r(null,"struct","struct",325972931,null);qv=new $CLJS.M(null,"indent","indent",-148200125);Eca=new $CLJS.r(null,"loop","loop",1244978678,null);
sx=new $CLJS.M(null,"max-columns","max-columns",1742323262);zx=new $CLJS.M(null,"both","both",-393648840);Zx=new $CLJS.M(null,"colnum","colnum",2023796854);Fca=new $CLJS.r(null,"doseq","doseq",221164135,null);$CLJS.$x=new $CLJS.M(null,"length","length",588987862);Tw=new $CLJS.M(null,"char-format","char-format",-1016499218);ay=new $CLJS.M(null,"prefix","prefix",-265908465);Vw=new $CLJS.M(null,"colon-up-arrow","colon-up-arrow",244853007);Mv=new $CLJS.M(null,"suffix","suffix",367373057);
vw=new $CLJS.M(null,"at","at",1476951349);cv=new $CLJS.M(null,"nl-t","nl-t",-1608382114);yx=new $CLJS.M(null,"directive","directive",793559132);Dba=new $CLJS.M(null,"buffer-level","buffer-level",928864731);Yv=new $CLJS.M(null,"mandatory","mandatory",542802336);Gv=new $CLJS.M(null,"pretty-writer","pretty-writer",-1222834267);jv=new $CLJS.M(null,"done-nl","done-nl",-381024340);Gca=new $CLJS.r(null,"condp","condp",1054325175,null);ow=new $CLJS.M(null,"seq","seq",-1817803783);
Hca=new $CLJS.r(null,"defn","defn",-126010802,null);Ew=new $CLJS.M(null,"colinc","colinc",-584873385);Uw=new $CLJS.M(null,"up-arrow","up-arrow",1705310333);Gx=new $CLJS.M(null,"right-bracket","right-bracket",951856080);Ica=new $CLJS.M(null,"radix","radix",857016463);Ix=new $CLJS.M(null,"first","first",-644103046);Cba=new $CLJS.M(null,"sections","sections",-886710106);$CLJS.Jv=new $CLJS.M(null,"start","start",-355208981);Jca=new $CLJS.r("cljs.core","unquote","cljs.core/unquote",1013085760,null);
Kca=new $CLJS.r(null,"defn-","defn-",1097765044,null);Lca=new $CLJS.r("cljs.core","deref","cljs.core/deref",1901963335,null);cw=new $CLJS.M(null,"block","block",664686210);Mca=new $CLJS.r(null,"when","when",1064114221,null);qx=new $CLJS.M(null,"else-params","else-params",-832171646);$CLJS.by=new $CLJS.M(null,"count","count",2139924085);$CLJS.Fx=new $CLJS.M(null,"right","right",-452581833);aw=new $CLJS.M(null,"linear","linear",872268697);Nca=new $CLJS.r(null,"when-not","when-not",-1223136340,null);
Fw=new $CLJS.M(null,"padchar","padchar",2018584530);Dw=new $CLJS.M(null,"mincol","mincol",1230695445);Oca=new $CLJS.M(null,"not-delivered","not-delivered",1599158697);lv=new $CLJS.M(null,"miser-width","miser-width",-1310049437);Pca=new $CLJS.r(null,"with-local-vars","with-local-vars",837642072,null);rca=new $CLJS.r(null,"lift-ns","lift-ns",602311926,null);Jx=new $CLJS.M(null,"allows-separator","allows-separator",-818967742);yv=new $CLJS.M(null,"buffering","buffering",-876713613);
cy=new $CLJS.M(null,"arg1","arg1",951899358);Yw=new $CLJS.M(null,"base-args","base-args",-1268706822);dy=new $CLJS.M(null,"arg3","arg3",-1486822496);ey=new $CLJS.M(null,"arg2","arg2",1729550917);Jw=new $CLJS.M(null,"commainterval","commainterval",-1980061083);Qca=new $CLJS.M(null,"right-margin","right-margin",-810413306);Av=new $CLJS.M(null,"buffer-blob","buffer-blob",-1830112173);Rca=new $CLJS.r(null,"with-open","with-open",172119667,null);
qca=new $CLJS.r("cljs.core","lift-ns","cljs.core/lift-ns",463499081,null);Sca=new $CLJS.M(null,"lines","lines",-700165781);ew=new $CLJS.M(null,"indent-t","indent-t",528318969);ox=new $CLJS.M(null,"right-params","right-params",-1790676237);uw=new $CLJS.M(null,"colon","colon",-965200945);Tca=new $CLJS.r(null,"if-not","if-not",-265415609,null);Zw=new $CLJS.M(null,"func","func",-238706040);$CLJS.fy=new $CLJS.M(null,"last","last",1105735132);Qx=new $CLJS.M(null,"deref","deref",-145586795);
Uca=new $CLJS.r(null,"dotimes","dotimes",-818708397,null);nx=new $CLJS.M(null,"max-iterations","max-iterations",2021275563);Vca=new $CLJS.r(null,"cond","cond",1606708055,null);Cw=new $CLJS.M(null,"minpad","minpad",323570901);ov=new $CLJS.M(null,"logical-block","logical-block",-581022564);Wca=new $CLJS.r(null,"struct-map","struct-map",-1387540878,null);Ov=new $CLJS.M(null,"end-block-t","end-block-t",1544648735);gy=new $CLJS.M(null,"stream","stream",1534941648);
Xw=new $CLJS.M(null,"params","params",710516235);Xca=new $CLJS.M(null,"circle","circle",1903212362);hv=new $CLJS.M(null,"start-pos","start-pos",668789086);xx=new $CLJS.M(null,"flags","flags",1775418075);$v=new $CLJS.M(null,"fill","fill",883462889);Bba=new $CLJS.M(null,"buffer-block","buffer-block",-10937307);Ax=new $CLJS.M(null,"generator-fn","generator-fn",811851656);mv=new $CLJS.M(null,"start-col","start-col",668080143);dx=new $CLJS.M(null,"w","w",354169001);Dx=new $CLJS.M(null,"def","def",-1043430536);
$CLJS.fx=new $CLJS.M(null,"k","k",-2146297393);ix=new $CLJS.M(null,"e","e",1381269198);ex=new $CLJS.M(null,"d","d",1972142424);gx=new $CLJS.M(null,"overflowchar","overflowchar",-1620088106);rx=new $CLJS.M(null,"min-remaining","min-remaining",962687677);Kw=new $CLJS.M(null,"commachar","commachar",652859327);hy=new $CLJS.M(null,"section","section",-300141526);iy=new $CLJS.M(null,"pretty","pretty",-1916372486);Yca=new $CLJS.r(null,"let","let",358118826,null);Vu=new $CLJS.M(null,"parent","parent",-878878779);
$CLJS.mx=new $CLJS.M(null,"clauses","clauses",1454841241);Zca=new $CLJS.r(null,"defonce","defonce",-1681484013,null);Su=new $CLJS.M(null,"base","base",185279322);bv=new $CLJS.M(null,"type-tag","type-tag",-1873863267);$ca=new $CLJS.r(null,"locking","locking",1542862874,null);zv=new $CLJS.M(null,"pos","pos",-864607220);ada=new $CLJS.M(null,"suppress-namespaces","suppress-namespaces",2130686956);$CLJS.Ru=new $CLJS.M(null,"cur","cur",1153190599);var Gw=function Gw(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Gw.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)},Tv,Fv,ky,ly,my,Aw,zw,Wv,Rv,ny;Gw.l=function(a){return $CLJS.ac($CLJS.Iu,$CLJS.Se.g($CLJS.Lw,a))};Gw.A=0;Gw.B=function(a){return this.l($CLJS.y(a))};var Hu=function Hu(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Hu.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};
Hu.l=function(a){return $CLJS.ac($CLJS.Iu,$CLJS.Se.g($CLJS.Mh,a))};Hu.A=0;Hu.B=function(a){return this.l($CLJS.y(a))};$CLJS.g=Tu.prototype;$CLJS.g.P=function(a,b){return new Tu(this.Ca,this.Nf,this.Yc,b)};$CLJS.g.O=function(){return this.Ch};$CLJS.g.Qb=function(){return this.Yc};$CLJS.g.rc=function(){return xu(this.Ca)};
$CLJS.g.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return a=b.lastIndexOf("\n"),0>a?Qu(this,$CLJS.Ru,$CLJS.Pu(this,$CLJS.Ru)+$CLJS.D(b)):(Qu(this,$CLJS.Ru,$CLJS.D(b)-a-1),Qu(this,$CLJS.nj,$CLJS.Pu(this,$CLJS.nj)+$CLJS.D($CLJS.lf(function(c){return $CLJS.E.g(c,"\n")},b)))),$CLJS.ac($CLJS.Pu(this,Su),b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return xba(this,b);throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));
};$CLJS.g=Uu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "parent":return this.parent;case "section":return this.Cb;case "start-col":return this.tb;case "indent":return this.sb;case "done-nl":return this.vb;case "intra-block-nl":return this.wb;case "prefix":return this.prefix;case "per-line-prefix":return this.Ab;case "suffix":return this.Db;case "logical-block-callback":return this.xb;default:return $CLJS.J.j(this.G,b,c)}};
$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.logical-block{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Vu,this.parent],null),new $CLJS.P(null,2,5,$CLJS.Q,[hy,this.Cb],null),new $CLJS.P(null,2,5,$CLJS.Q,[mv,this.tb],null),new $CLJS.P(null,2,5,$CLJS.Q,[qv,this.sb],null),new $CLJS.P(null,2,5,$CLJS.Q,[jv,this.vb],null),new $CLJS.P(null,2,5,$CLJS.Q,[rv,this.wb],null),new $CLJS.P(null,2,5,$CLJS.Q,
[ay,this.prefix],null),new $CLJS.P(null,2,5,$CLJS.Q,[pv,this.Ab],null),new $CLJS.P(null,2,5,$CLJS.Q,[Mv,this.Db],null),new $CLJS.P(null,2,5,$CLJS.Q,[Iv,this.xb],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Lt(this,10,new $CLJS.P(null,10,5,$CLJS.Q,[Vu,hy,mv,qv,jv,rv,ay,pv,Mv,Iv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 10+$CLJS.D(this.G)};
$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1977012399^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.parent,b.parent)&&$CLJS.E.g(this.Cb,b.Cb)&&$CLJS.E.g(this.tb,b.tb)&&$CLJS.E.g(this.sb,b.sb)&&$CLJS.E.g(this.vb,b.vb)&&$CLJS.E.g(this.wb,b.wb)&&$CLJS.E.g(this.prefix,b.prefix)&&$CLJS.E.g(this.Ab,b.Ab)&&$CLJS.E.g(this.Db,b.Db)&&$CLJS.E.g(this.xb,b.xb)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,10,[Mv,null,qv,null,Vu,null,hy,null,jv,null,mv,null,ay,null,pv,null,Iv,null,rv,null],null),null),b)?$CLJS.Hk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Uu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,$CLJS.Fe($CLJS.Hk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "parent":case "section":case "start-col":case "indent":case "done-nl":case "intra-block-nl":case "prefix":case "per-line-prefix":case "suffix":case "logical-block-callback":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Vu,b):$CLJS.he.call(null,Vu,b))?new Uu(c,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(hy,b):$CLJS.he.call(null,hy,b))?new Uu(this.parent,c,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(mv,b):$CLJS.he.call(null,mv,b))?new Uu(this.parent,this.Cb,c,this.sb,this.vb,this.wb,this.prefix,this.Ab,
this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(qv,b):$CLJS.he.call(null,qv,b))?new Uu(this.parent,this.Cb,this.tb,c,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(jv,b):$CLJS.he.call(null,jv,b))?new Uu(this.parent,this.Cb,this.tb,this.sb,c,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(rv,b):$CLJS.he.call(null,rv,b))?new Uu(this.parent,this.Cb,this.tb,this.sb,this.vb,c,this.prefix,
this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(ay,b):$CLJS.he.call(null,ay,b))?new Uu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,c,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(pv,b):$CLJS.he.call(null,pv,b))?new Uu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,c,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Mv,b):$CLJS.he.call(null,Mv,b))?new Uu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,
this.prefix,this.Ab,c,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Iv,b):$CLJS.he.call(null,Iv,b))?new Uu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,c,this.S,this.G,null):new Uu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.Yf(Vu,this.parent),new $CLJS.Yf(hy,this.Cb),new $CLJS.Yf(mv,this.tb),new $CLJS.Yf(qv,this.sb),new $CLJS.Yf(jv,this.vb),new $CLJS.Yf(rv,this.wb),new $CLJS.Yf(ay,this.prefix),new $CLJS.Yf(pv,this.Ab),new $CLJS.Yf(Mv,this.Db),new $CLJS.Yf(Iv,this.xb)],null),this.G))};$CLJS.g.P=function(a,b){return new Uu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,b,this.G,this.J)};
$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=Xu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "data":return this.data;case "trailing-white-space":return this.ac;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};
$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.buffer-blob{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[bv,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,this.data],null),new $CLJS.P(null,2,5,$CLJS.Q,[dv,this.ac],null),new $CLJS.P(null,2,5,$CLJS.Q,[hv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[gv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Lt(this,5,new $CLJS.P(null,5,5,$CLJS.Q,[bv,$CLJS.rj,dv,hv,gv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1809113693^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.data,b.data)&&$CLJS.E.g(this.ac,b.ac)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,5,[gv,null,dv,null,bv,null,hv,null,$CLJS.rj,null],null),null),b)?$CLJS.Hk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Xu(this.ca,this.data,this.ac,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Hk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "data":case "trailing-white-space":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(bv,b):$CLJS.he.call(null,bv,b))?new Xu(c,this.data,this.ac,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.rj,b):$CLJS.he.call(null,$CLJS.rj,b))?new Xu(this.ca,c,this.ac,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(dv,b):$CLJS.he.call(null,dv,b))?new Xu(this.ca,this.data,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(hv,b):$CLJS.he.call(null,hv,b))?new Xu(this.ca,this.data,
this.ac,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(gv,b):$CLJS.he.call(null,gv,b))?new Xu(this.ca,this.data,this.ac,this.ba,c,this.S,this.G,null):new Xu(this.ca,this.data,this.ac,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.Yf(bv,this.ca),new $CLJS.Yf($CLJS.rj,this.data),new $CLJS.Yf(dv,this.ac),new $CLJS.Yf(hv,this.ba),new $CLJS.Yf(gv,this.aa)],null),this.G))};
$CLJS.g.P=function(a,b){return new Xu(this.ca,this.data,this.ac,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=Yu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "type":return this.type;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.nl-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[bv,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,this.type],null),new $CLJS.P(null,2,5,$CLJS.Q,[ov,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[hv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[gv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Lt(this,5,new $CLJS.P(null,5,5,$CLJS.Q,[bv,$CLJS.mj,ov,hv,gv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1640656800^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.type,b.type)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,5,[gv,null,$CLJS.mj,null,ov,null,bv,null,hv,null],null),null),b)?$CLJS.Hk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Yu(this.ca,this.type,this.ea,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Hk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "type":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(bv,b):$CLJS.he.call(null,bv,b))?new Yu(c,this.type,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.mj,b):$CLJS.he.call(null,$CLJS.mj,b))?new Yu(this.ca,c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(ov,b):$CLJS.he.call(null,ov,b))?new Yu(this.ca,this.type,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(hv,b):$CLJS.he.call(null,hv,b))?new Yu(this.ca,this.type,
this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(gv,b):$CLJS.he.call(null,gv,b))?new Yu(this.ca,this.type,this.ea,this.ba,c,this.S,this.G,null):new Yu(this.ca,this.type,this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.Yf(bv,this.ca),new $CLJS.Yf($CLJS.mj,this.type),new $CLJS.Yf(ov,this.ea),new $CLJS.Yf(hv,this.ba),new $CLJS.Yf(gv,this.aa)],null),this.G))};
$CLJS.g.P=function(a,b){return new Yu(this.ca,this.type,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=Zu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.start-block-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[bv,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[ov,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[hv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[gv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Lt(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[bv,ov,hv,gv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-414877272^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,4,[gv,null,ov,null,bv,null,hv,null],null),null),b)?$CLJS.Hk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Zu(this.ca,this.ea,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Hk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(bv,b):$CLJS.he.call(null,bv,b))?new Zu(c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(ov,b):$CLJS.he.call(null,ov,b))?new Zu(this.ca,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(hv,b):$CLJS.he.call(null,hv,b))?new Zu(this.ca,this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(gv,b):$CLJS.he.call(null,gv,b))?new Zu(this.ca,this.ea,this.ba,c,this.S,this.G,null):new Zu(this.ca,
this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Yf(bv,this.ca),new $CLJS.Yf(ov,this.ea),new $CLJS.Yf(hv,this.ba),new $CLJS.Yf(gv,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new Zu(this.ca,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=$u.prototype;
$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.end-block-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[bv,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[ov,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[hv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[gv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Lt(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[bv,ov,hv,gv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1365867980^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,4,[gv,null,ov,null,bv,null,hv,null],null),null),b)?$CLJS.Hk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new $u(this.ca,this.ea,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Hk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(bv,b):$CLJS.he.call(null,bv,b))?new $u(c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(ov,b):$CLJS.he.call(null,ov,b))?new $u(this.ca,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(hv,b):$CLJS.he.call(null,hv,b))?new $u(this.ca,this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(gv,b):$CLJS.he.call(null,gv,b))?new $u(this.ca,this.ea,this.ba,c,this.S,this.G,null):new $u(this.ca,
this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Yf(bv,this.ca),new $CLJS.Yf(ov,this.ea),new $CLJS.Yf(hv,this.ba),new $CLJS.Yf(gv,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new $u(this.ca,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=av.prototype;
$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "relative-to":return this.Wb;case "offset":return this.offset;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.indent-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[bv,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[ov,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[Yx,this.Wb],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.St,this.offset],null),new $CLJS.P(null,2,5,$CLJS.Q,[hv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[gv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Lt(this,6,new $CLJS.P(null,6,5,$CLJS.Q,[bv,ov,Yx,$CLJS.St,hv,gv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 6+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1602780238^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.Wb,b.Wb)&&$CLJS.E.g(this.offset,b.offset)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,6,[$CLJS.St,null,gv,null,Yx,null,ov,null,bv,null,hv,null],null),null),b)?$CLJS.Hk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new av(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Hk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "relative-to":case "offset":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(bv,b):$CLJS.he.call(null,bv,b))?new av(c,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(ov,b):$CLJS.he.call(null,ov,b))?new av(this.ca,c,this.Wb,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Yx,b):$CLJS.he.call(null,Yx,b))?new av(this.ca,this.ea,c,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.St,b):$CLJS.he.call(null,$CLJS.St,
b))?new av(this.ca,this.ea,this.Wb,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(hv,b):$CLJS.he.call(null,hv,b))?new av(this.ca,this.ea,this.Wb,this.offset,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(gv,b):$CLJS.he.call(null,gv,b))?new av(this.ca,this.ea,this.Wb,this.offset,this.ba,c,this.S,this.G,null):new av(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.Yf(bv,this.ca),new $CLJS.Yf(ov,this.ea),new $CLJS.Yf(Yx,this.Wb),new $CLJS.Yf($CLJS.St,this.offset),new $CLJS.Yf(hv,this.ba),new $CLJS.Yf(gv,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new av(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};
var ev=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("cljs.pprint","write-token"),function(f,k){return bv.h(k)},e,a,b,c,d)}();
ev.m(null,Kv,function(a,b){var c=Iv.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h($CLJS.Jv):c.call(null,$CLJS.Jv));b=ov.h(b);c=ay.h(b);$CLJS.n(c)&&$CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),c);a=$CLJS.Pu(Su.h($CLJS.q($CLJS.q(a))),$CLJS.Ru);$CLJS.Ye(mv.h(b),a);return $CLJS.Ye(qv.h(b),a)});ev.m(null,Ov,function(a,b){var c=Iv.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h(Nv):c.call(null,Nv));b=Mv.h(ov.h(b));return $CLJS.n(b)?$CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),b):null});
ev.m(null,ew,function(a,b){var c=ov.h(b),d=qv.h(c),e=$CLJS.St.h(b);b=Yx.h(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(cw,b):$CLJS.E.call(null,cw,b)))a=$CLJS.q(mv.h(c));else if($CLJS.n($CLJS.E.g?$CLJS.E.g($CLJS.dw,b):$CLJS.E.call(null,$CLJS.dw,b)))a=$CLJS.Pu(Su.h($CLJS.q($CLJS.q(a))),$CLJS.Ru);else throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));return $CLJS.Ye(d,e+a)});ev.m(null,Av,function(a,b){return $CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),$CLJS.rj.h(b))});
ev.m(null,cv,function(a,b){var c=$CLJS.E.g($CLJS.mj.h(b),Yv);c||(c=(c=!$CLJS.E.g($CLJS.mj.h(b),$v))?$CLJS.q(jv.h(ov.h(b))):c);$CLJS.n(c)?sv.g?sv.g(a,b):sv.call(null,a,b):(b=dv.h($CLJS.q($CLJS.q(a))),$CLJS.n(b)&&$CLJS.ac(Su.h($CLJS.q($CLJS.q(a))),b));return $CLJS.Oh.v($CLJS.q(a),$CLJS.R,dv,null)});
var jy=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("cljs.pprint","emit-nl?"),function(f){return $CLJS.mj.h(f)},e,a,b,c,d)}();jy.m(null,aw,function(a,b,c){a=ov.h(a);return kv(b,a,c)});jy.m(null,Zv,function(a,b,c){a=ov.h(a);return nv(b,a,c)});
jy.m(null,$v,function(a,b,c,d){a=ov.h(a);var e=$CLJS.q(rv.h(a));return $CLJS.n(e)?e:(d=!iv(b,d))?d:nv(b,a,c)});jy.m(null,Yv,function(){return!0});
var tv=function tv(a,b){var d=Aba(b);b=$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null);$CLJS.n(b)&&fv(a,b,!1);if($CLJS.n(e)){d=yba(e);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=$CLJS.A(e),k=jy.v(f,a,b,zba(e));$CLJS.n(k)&&(sv(a,f),e=$CLJS.B(e));iv(a,e)?a=e:(e=tv.g?tv.g(a,b):tv.call(null,a,b),$CLJS.E.g(e,b)?(fv(a,b,!1),a=d):a=$CLJS.eg.g($CLJS.Cf,$CLJS.gf.g(e,d)));return a}return null};$CLJS.g=Dv.prototype;$CLJS.g.P=function(a,b){return new Dv(this.Ca,this.Nf,this.li,this.mh,this.Yc,b)};$CLJS.g.O=function(){return this.Jh};
$CLJS.g.Qb=function(){return this.Yc};
$CLJS.g.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a))){var c=Cv(this,b);b=c.replace(/\s+$/,"");a=c.substring(b.length);var d=$CLJS.yi.h($CLJS.q($CLJS.q(this)));if($CLJS.E.g(d,Bv))return wv(this),$CLJS.ac(Su.h($CLJS.q($CLJS.q(this))),b),$CLJS.Oh.v($CLJS.q(this),$CLJS.R,dv,a);d=zv.h($CLJS.q($CLJS.q(this)));c=d+$CLJS.D(c);$CLJS.Oh.v($CLJS.q(this),$CLJS.R,zv,c);return vv(this,new Xu(Av,b,a,d,c,null,null,null))}if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,
a):$CLJS.E.call(null,Number,a)))return $CLJS.E.g($CLJS.yi.h($CLJS.q($CLJS.q(this))),Bv)?(wv(this),b=$CLJS.ac(Su.h($CLJS.q($CLJS.q(this))),b)):$CLJS.E.g(b,"\n")?b=Cv(this,"\n"):(a=zv.h($CLJS.q($CLJS.q(this))),c=a+1,$CLJS.Oh.v($CLJS.q(this),$CLJS.R,zv,c),b=Au(b),b=vv(this,new Xu(Av,b,null,a,c,null,null,null))),b;throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};$CLJS.g.rc=function(){this.uf(null);return xu(Su.h($CLJS.q($CLJS.q(this))))};
$CLJS.g.uf=function(){return $CLJS.E.g($CLJS.yi.h($CLJS.q($CLJS.q(this))),yv)?(fv(this,$CLJS.Qt.h($CLJS.q($CLJS.q(this))),!0),$CLJS.Oh.v($CLJS.q(this),$CLJS.R,$CLJS.Qt,$CLJS.Cf)):wv(this)};$CLJS.Sv=!0;Tv=null;$CLJS.Ev=72;Fv=40;ky=null;ly=null;my=null;Aw=null;zw=10;Wv=0;Rv=null;ny=function ny(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return ny.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
ny.l=function(a,b){var c=$CLJS.gl.l($CLJS.H([new $CLJS.h(null,1,[gy,!0],null),$CLJS.Se.g(Xx,b)]));b=zw;var d=ly,e=$CLJS.Na,f=$CLJS.qh,k=ky,l=Fv,m=Tv,t=$CLJS.Sv,u=Aw,v=$CLJS.Ha,x=$CLJS.Ev,z=my,C=Su.g(c,zw),G=Xca.g(c,ly),K=$CLJS.$x.g(c,$CLJS.Na),S=$CLJS.Ut.g(c,$CLJS.qh),V=Sca.g(c,ky),Z=lv.g(c,Fv),ha=$CLJS.Oi.g(c,Tv),ra=iy.g(c,$CLJS.Sv),Ma=Ica.g(c,Aw),zb=$CLJS.Ga.g(c,$CLJS.Ha),Oa=Qca.g(c,$CLJS.Ev),Ya=ada.g(c,my);zw=C;ly=G;$CLJS.Na=K;$CLJS.qh=S;ky=V;Fv=Z;Tv=ha;$CLJS.Sv=ra;Aw=Ma;$CLJS.Ha=zb;$CLJS.Ev=Oa;
my=Ya;try{var eb=new $CLJS.Ea,Sa=$CLJS.Hd(c,gy)?gy.h(c):!0,mb=!0===Sa||null==Sa?new $CLJS.sc(eb):Sa;if($CLJS.n($CLJS.Sv)){var Ia=$CLJS.Va($CLJS.Qv(mb));c=$CLJS.Iu;$CLJS.Iu=Ia?$CLJS.Hv(mb):mb;try{$CLJS.Uv(a),$CLJS.Ou()}finally{$CLJS.Iu=c}}else{Ia=$CLJS.Iu;$CLJS.Iu=mb;try{Hu.call(null,a)}finally{$CLJS.Iu=Ia}}!0===Sa&&$CLJS.uh($CLJS.p.h(eb));return null==Sa?$CLJS.p.h(eb):null}finally{my=z,$CLJS.Ev=x,$CLJS.Ha=v,Aw=u,$CLJS.Sv=t,Tv=m,Fv=l,ky=k,$CLJS.qh=f,$CLJS.Na=e,ly=d,zw=b}};ny.A=1;
ny.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};var lw=null;$CLJS.g=nw.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "seq":return this.uc;case "rest":return this.pb;case "pos":return this.$b;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.arg-navigator{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[ow,this.uc],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,this.pb],null),new $CLJS.P(null,2,5,$CLJS.Q,[zv,this.$b],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Lt(this,3,new $CLJS.P(null,3,5,$CLJS.Q,[ow,$CLJS.Pi,zv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};
$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 3+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-402038447^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.uc,b.uc)&&$CLJS.E.g(this.pb,b.pb)&&$CLJS.E.g(this.$b,b.$b)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,3,[zv,null,ow,null,$CLJS.Pi,null],null),null),b)?$CLJS.Hk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new nw(this.uc,this.pb,this.$b,this.S,$CLJS.Fe($CLJS.Hk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "seq":case "rest":case "pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(ow,b):$CLJS.he.call(null,ow,b))?new nw(c,this.pb,this.$b,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.Pi,b):$CLJS.he.call(null,$CLJS.Pi,b))?new nw(this.uc,c,this.$b,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(zv,b):$CLJS.he.call(null,zv,b))?new nw(this.uc,this.pb,c,this.S,this.G,null):new nw(this.uc,this.pb,this.$b,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.Yf(ow,this.uc),new $CLJS.Yf($CLJS.Pi,this.pb),new $CLJS.Yf(zv,this.$b)],null),this.G))};$CLJS.g.P=function(a,b){return new nw(this.uc,this.pb,this.$b,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=tw.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "func":return this.dc;case "def":return this.cc;case "params":return this.kc;case "offset":return this.offset;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.compiled-directive{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Zw,this.dc],null),new $CLJS.P(null,2,5,$CLJS.Q,[Dx,this.cc],null),new $CLJS.P(null,2,5,$CLJS.Q,[Xw,this.kc],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.St,this.offset],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Lt(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Zw,Dx,Xw,$CLJS.St],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-829256337^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.dc,b.dc)&&$CLJS.E.g(this.cc,b.cc)&&$CLJS.E.g(this.kc,b.kc)&&$CLJS.E.g(this.offset,b.offset)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.St,null,Zw,null,Xw,null,Dx,null],null),null),b)?$CLJS.Hk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new tw(this.dc,this.cc,this.kc,this.offset,this.S,$CLJS.Fe($CLJS.Hk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "func":case "def":case "params":case "offset":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Zw,b):$CLJS.he.call(null,Zw,b))?new tw(c,this.cc,this.kc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Dx,b):$CLJS.he.call(null,Dx,b))?new tw(this.dc,c,this.kc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Xw,b):$CLJS.he.call(null,Xw,b))?new tw(this.dc,this.cc,c,this.offset,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.St,b):$CLJS.he.call(null,$CLJS.St,b))?new tw(this.dc,this.cc,this.kc,c,this.S,
this.G,null):new tw(this.dc,this.cc,this.kc,this.offset,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Yf(Zw,this.dc),new $CLJS.Yf(Dx,this.cc),new $CLJS.Yf(Xw,this.kc),new $CLJS.Yf($CLJS.St,this.offset)],null),this.G))};$CLJS.g.P=function(a,b){return new tw(this.dc,this.cc,this.kc,this.offset,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};
var Fba=new $CLJS.h(null,3,[2,"#b",8,"#o",16,"#x"],null),Nw=new $CLJS.P(null,20,5,$CLJS.Q,"zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" "),null),oy=new $CLJS.P(null,20,5,$CLJS.Q,"zeroth first second third fourth fifth sixth seventh eighth ninth tenth eleventh twelfth thirteenth fourteenth fifteenth sixteenth seventeenth eighteenth nineteenth".split(" "),null),Ow=new $CLJS.P(null,10,5,$CLJS.Q,"  twenty thirty forty fifty sixty seventy eighty ninety".split(" "),
null),bda=new $CLJS.P(null,10,5,$CLJS.Q,"  twentieth thirtieth fortieth fiftieth sixtieth seventieth eightieth ninetieth".split(" "),null),Qw=new $CLJS.P(null,22,5,$CLJS.Q," thousand million billion trillion quadrillion quintillion sextillion septillion octillion nonillion decillion undecillion duodecillion tredecillion quattuordecillion quindecillion sexdecillion septendecillion octodecillion novemdecillion vigintillion".split(" "),null),cda=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,9,5,$CLJS.Q,
"I II III IIII V VI VII VIII VIIII".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"X XX XXX XXXX L LX LXX LXXX LXXXX".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"C CC CCC CCCC D DC DCC DCCC DCCCC".split(" "),null),new $CLJS.P(null,3,5,$CLJS.Q,["M","MM","MMM"],null)],null),dda=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,9,5,$CLJS.Q,"I II III IV V VI VII VIII IX".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"X XX XXX XL L LX LXX LXXX XC".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"C CC CCC CD D DC DCC DCCC CM".split(" "),
null),new $CLJS.P(null,3,5,$CLJS.Q,["M","MM","MMM"],null)],null),Hba=new $CLJS.h(null,5,[8,"Backspace",9,"Tab",10,"Newline",13,"Return",32,"Space"],null);tx.prototype.P=function(a,b){return new tx(this.Ca,b)};tx.prototype.O=function(){return this.Uh};tx.prototype.rc=function(){return xu(this.Ca)};
tx.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.ac(this.Ca,b.toLowerCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return $CLJS.ac(this.Ca,Au(b).toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};ux.prototype.P=function(a,b){return new ux(this.Ca,b)};ux.prototype.O=function(){return this.Vh};ux.prototype.rc=function(){return xu(this.Ca)};
ux.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.ac(this.Ca,b.toUpperCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return $CLJS.ac(this.Ca,Au(b).toUpperCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};vx.prototype.P=function(a,b){return new vx(this.Ca,this.Jd,b)};vx.prototype.O=function(){return this.Xh};vx.prototype.rc=function(){return xu(this.Ca)};
vx.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.ac(this.Ca,Zba(b.toLowerCase(),$CLJS.q(this.Jd))),0<b.length?$CLJS.Ye(this.Jd,$CLJS.Aa($CLJS.F(b,$CLJS.D(b)-1))):null;if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return b=Au(b),a=$CLJS.n($CLJS.q(this.Jd))?b.toUpperCase():b,$CLJS.ac(this.Ca,a),$CLJS.Ye(this.Jd,$CLJS.Aa(b));throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
wx.prototype.P=function(a,b){return new wx(this.Ca,this.vd,b)};wx.prototype.O=function(){return this.Yh};wx.prototype.rc=function(){return xu(this.Ca)};
wx.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return b=b.toLowerCase(),$CLJS.Va($CLJS.q(this.vd))?(a=RegExp("\\S","g").exec(b),a=$CLJS.n(a)?a.index:a,$CLJS.n(a)?($CLJS.ac(this.Ca,[b.substring(0,a),$CLJS.F(b,a).toUpperCase(),b.substring(a+1).toLowerCase()].join("")),$CLJS.Ye(this.vd,!0)):$CLJS.ac(this.Ca,b)):$CLJS.ac(this.Ca,b.toLowerCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return b=Au(b),a=$CLJS.Va($CLJS.q(this.vd)),
$CLJS.n(a?vu(b):a)?($CLJS.Ye(this.vd,!0),$CLJS.ac(this.Ca,b.toUpperCase())):$CLJS.ac(this.Ca,b.toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
var lca=$CLJS.Ig("ASDBOXRPCFEG$%\x26|~\nT*?()[;]{}\x3c\x3e^W_I".split(""),[new $CLJS.h(null,5,[yx,"A",Xw,new $CLJS.h(null,4,[Dw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),Cw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Fw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,3,[uw,null,vw,null,zx,null],null),null),Cx,$CLJS.N,Ax,function(){return function(a,b){return Hw($CLJS.Lw,a,b)}}],null),new $CLJS.h(null,
5,[yx,"S",Xw,new $CLJS.h(null,4,[Dw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),Cw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Fw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,3,[uw,null,vw,null,zx,null],null),null),Cx,$CLJS.N,Ax,function(){return function(a,b){return Hw($CLJS.Mh,a,b)}}],null),new $CLJS.h(null,5,[yx,"D",Xw,new $CLJS.h(null,4,[Dw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Fw,new $CLJS.P(null,
2,5,$CLJS.Q,[" ",String],null),Kw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Jw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,3,[uw,null,vw,null,zx,null],null),null),Cx,$CLJS.N,Ax,function(){return function(a,b){return Mw(10,a,b)}}],null),new $CLJS.h(null,5,[yx,"B",Xw,new $CLJS.h(null,4,[Dw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Fw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Kw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Jw,new $CLJS.P(null,
2,5,$CLJS.Q,[3,Number],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,3,[uw,null,vw,null,zx,null],null),null),Cx,$CLJS.N,Ax,function(){return function(a,b){return Mw(2,a,b)}}],null),new $CLJS.h(null,5,[yx,"O",Xw,new $CLJS.h(null,4,[Dw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Fw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Kw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Jw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,3,[uw,null,vw,null,zx,
null],null),null),Cx,$CLJS.N,Ax,function(){return function(a,b){return Mw(8,a,b)}}],null),new $CLJS.h(null,5,[yx,"X",Xw,new $CLJS.h(null,4,[Dw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Fw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Kw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Jw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,3,[uw,null,vw,null,zx,null],null),null),Cx,$CLJS.N,Ax,function(){return function(a,b){return Mw(16,a,b)}}],null),new $CLJS.h(null,
5,[yx,"R",Xw,new $CLJS.h(null,5,[Su,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Dw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Fw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Kw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Jw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,3,[uw,null,vw,null,zx,null],null),null),Cx,$CLJS.N,Ax,function(a){return $CLJS.n($CLJS.A(Su.h(a)))?function(b,c){return Mw(Su.h(b),b,c)}:$CLJS.n(function(){var b=vw.h(a);return $CLJS.n(b)?
uw.h(a):b}())?function(b,c){return Sw(cda,c)}:$CLJS.n(vw.h(a))?function(b,c){return Sw(dda,c)}:$CLJS.n(uw.h(a))?function(b,c){b=pw(c);c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);if($CLJS.E.g(0,c))Gw.l($CLJS.H(["zeroth"]));else{var d=Iw(1E3,0>c?-c:c);if($CLJS.D(d)<=$CLJS.D(Qw)){var e=$CLJS.hf.g(Pw,$CLJS.Gu(1,d));e=Rw(e,1);var f=$CLJS.id(d);d=$CLJS.Vd(f,100);f=Bu(f,100);var k=0<d?[$CLJS.p.h($CLJS.F(Nw,d))," hundred"].join(""):null,l=$CLJS.p,m=l.h;if(0<f)if(20>f)var t=$CLJS.F(oy,f);else{t=$CLJS.Vd(f,10);
var u=Bu(f,10);t=0<t&&!(0<u)?$CLJS.F(bda,t):[$CLJS.p.h(0<t?$CLJS.F(Ow,t):null),0<t&&0<u?"-":null,$CLJS.p.h(0<u?$CLJS.F(oy,u):null)].join("")}else t=0<d?"th":null;d=[k,0<d&&0<f?" ":null,m.call(l,t)].join("");Gw.l($CLJS.H([[0>c?"minus ":null,$CLJS.td(e)||$CLJS.td(d)?$CLJS.td(e)?d:[e,"th"].join(""):[e,", ",d].join("")].join("")]))}else Mw(10,new $CLJS.h(null,5,[Dw,0,Fw," ",Kw,",",Jw,3,uw,!0],null),hw(new $CLJS.P(null,1,5,$CLJS.Q,[c],null))),e=Bu(c,100),c=11<e||19>e,e=Bu(e,10),Gw.l($CLJS.H([1===e&&c?
"st":2===e&&c?"nd":3===e&&c?"rd":"th"]))}return b}:function(b,c){c=pw(c);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.E.g(0,b))Gw.l($CLJS.H(["zero"]));else{var d=Iw(1E3,0>b?-b:b);$CLJS.D(d)<=$CLJS.D(Qw)?(d=$CLJS.hf.g(Pw,d),d=Rw(d,0),Gw.l($CLJS.H([[0>b?"minus ":null,d].join("")]))):Mw(10,new $CLJS.h(null,5,[Dw,0,Fw," ",Kw,",",Jw,3,uw,!0],null),hw(new $CLJS.P(null,1,5,$CLJS.Q,[b],null)))}return c}}],null),new $CLJS.h(null,5,[yx,"P",Xw,$CLJS.N,xx,new $CLJS.ah(null,new $CLJS.h(null,3,[uw,null,vw,
null,zx,null],null),null),Cx,$CLJS.N,Ax,function(){return function(a,b){b=$CLJS.n(uw.h(a))?rw(b,-1):b;a=$CLJS.n(vw.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,["y","ies"],null):new $CLJS.P(null,2,5,$CLJS.Q,["","s"],null);var c=pw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);Gw.l($CLJS.H([$CLJS.E.g(b,1)?$CLJS.A(a):$CLJS.hd(a)]));return c}}],null),new $CLJS.h(null,5,[yx,"C",Xw,new $CLJS.h(null,1,[Tw,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,3,[uw,null,vw,null,
zx,null],null),null),Cx,$CLJS.N,Ax,function(a){return $CLJS.n(uw.h(a))?Iba:$CLJS.n(vw.h(a))?Jba:Kba}],null),new $CLJS.h(null,5,[yx,"F",Xw,new $CLJS.h(null,5,[dx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),ex,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.fx,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),gx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),Fw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,1,[vw,null],null),null),Cx,$CLJS.N,Ax,
function(){return hx}],null),new $CLJS.h(null,5,[yx,"E",Xw,new $CLJS.h(null,7,[dx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),ex,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),ix,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.fx,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),gx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),Fw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),jx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,1,[vw,null],
null),null),Cx,$CLJS.N,Ax,function(){return kx}],null),new $CLJS.h(null,5,[yx,"G",Xw,new $CLJS.h(null,7,[dx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),ex,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),ix,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.fx,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),gx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),Fw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),jx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,
1,[vw,null],null),null),Cx,$CLJS.N,Ax,function(){return Mba}],null),new $CLJS.h(null,5,[yx,"$",Xw,new $CLJS.h(null,4,[ex,new $CLJS.P(null,2,5,$CLJS.Q,[2,Number],null),$CLJS.rk,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),dx,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Fw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,3,[uw,null,vw,null,zx,null],null),null),Cx,$CLJS.N,Ax,function(){return Nba}],null),new $CLJS.h(null,5,[yx,"%",Xw,new $CLJS.h(null,1,[$CLJS.by,
new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),xx,$CLJS.bh,Cx,$CLJS.N,Ax,function(){return function(a,b){a=$CLJS.by.h(a);for(var c=0;;)if(c<a)Ju(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[yx,"\x26",Xw,new $CLJS.h(null,1,[$CLJS.by,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,1,[iy,null],null),null),Cx,$CLJS.N,Ax,function(){return function(a,b){a=$CLJS.by.h(a);0<a&&((null!=$CLJS.Iu?$CLJS.Iu.C&32768||$CLJS.Bc===$CLJS.Iu.ag||($CLJS.Iu.C?
0:$CLJS.$a($CLJS.wu,$CLJS.Iu)):$CLJS.$a($CLJS.wu,$CLJS.Iu))?$CLJS.E.g(0,$CLJS.Pu(Su.h($CLJS.q($CLJS.q($CLJS.Iu))),$CLJS.Ru))||Ju():Ju());--a;for(var c=0;;)if(c<a)Ju(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[yx,"|",Xw,new $CLJS.h(null,1,[$CLJS.by,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),xx,$CLJS.bh,Cx,$CLJS.N,Ax,function(){return function(a,b){a=$CLJS.by.h(a);for(var c=0;;)if(c<a)Gw.l($CLJS.H(["\f"])),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[yx,"~",Xw,new $CLJS.h(null,
1,[$CLJS.rk,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),xx,$CLJS.bh,Cx,$CLJS.N,Ax,function(){return function(a,b){a=$CLJS.rk.h(a);Gw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(a,"~"))]));return b}}],null),new $CLJS.h(null,5,[yx,"\n",Xw,$CLJS.N,xx,new $CLJS.ah(null,new $CLJS.h(null,2,[uw,null,vw,null],null),null),Cx,$CLJS.N,Ax,function(){return function(a,b){$CLJS.n(vw.h(a))&&Ju();return b}}],null),new $CLJS.h(null,5,[yx,"T",Xw,new $CLJS.h(null,2,[Zx,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),
Ew,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,2,[vw,null,iy,null],null),null),Cx,$CLJS.N,Ax,function(a){return $CLJS.n(vw.h(a))?function(b,c){var d=Zx.h(b);b=Ew.h(b);var e=d+$CLJS.Pu(Su.h($CLJS.q($CLJS.q($CLJS.Iu))),$CLJS.Ru);e=0<b?Bu(e,b):0;d+=$CLJS.E.g(0,e)?0:b-e;Gw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(d," "))]));return c}:function(b,c){var d=Zx.h(b);b=Ew.h(b);var e=$CLJS.Pu(Su.h($CLJS.q($CLJS.q($CLJS.Iu))),$CLJS.Ru);d=e<d?d-e:$CLJS.E.g(b,0)?0:b-
Bu(e-d,b);Gw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(d," "))]));return c}}],null),new $CLJS.h(null,5,[yx,"*",Xw,new $CLJS.h(null,1,[$CLJS.rk,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,2,[uw,null,vw,null],null),null),Cx,$CLJS.N,Ax,function(){return function(a,b){var c=$CLJS.rk.h(a);return $CLJS.n(vw.h(a))?sw(b,c):rw(b,$CLJS.n(uw.h(a))?-c:c)}}],null),new $CLJS.h(null,5,[yx,"?",Xw,$CLJS.N,xx,new $CLJS.ah(null,new $CLJS.h(null,1,[vw,null],null),null),Cx,
$CLJS.N,Ax,function(a){return $CLJS.n(vw.h(a))?function(b,c){var d=qw(c);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $w(c,d,Yw.h(b))}:function(b,c){var d=qw(c);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var e=pw(d);d=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);d=hw(d);$w(c,d,Yw.h(b));return e}}],null),new $CLJS.h(null,5,[yx,"(",Xw,$CLJS.N,xx,new $CLJS.ah(null,new $CLJS.h(null,3,[uw,null,vw,null,zx,null],null),null),Cx,new $CLJS.h(null,3,[$CLJS.Fx,")",Jx,null,$CLJS.Rt,null],null),Ax,function(a){var b=$CLJS.n(function(){var c=
vw.h(a);return $CLJS.n(c)?uw.h(a):c}())?Yba:$CLJS.n(uw.h(a))?$ba:$CLJS.n(vw.h(a))?aca:Xba;return function(c,d){a:{var e=$CLJS.A($CLJS.mx.h(c)),f=$CLJS.Iu;$CLJS.Iu=b.h?b.h($CLJS.Iu):b.call(null,$CLJS.Iu);try{var k=$w(e,d,Yw.h(c));break a}finally{$CLJS.Iu=f}k=void 0}return k}}],null),new $CLJS.h(null,5,[yx,")",Xw,$CLJS.N,xx,$CLJS.bh,Cx,$CLJS.N,Ax,function(){return null}],null),new $CLJS.h(null,5,[yx,"[",Xw,new $CLJS.h(null,1,[lx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),xx,new $CLJS.ah(null,
new $CLJS.h(null,2,[uw,null,vw,null],null),null),Cx,new $CLJS.h(null,3,[$CLJS.Fx,"]",Jx,!0,$CLJS.Rt,$CLJS.fy],null),Ax,function(a){return $CLJS.n(uw.h(a))?Pba:$CLJS.n(vw.h(a))?Qba:Oba}],null),new $CLJS.h(null,5,[yx,";",Xw,new $CLJS.h(null,2,[rx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),sx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,1,[uw,null],null),null),Cx,new $CLJS.h(null,1,[$CLJS.Tt,!0],null),Ax,function(){return null}],null),new $CLJS.h(null,
5,[yx,"]",Xw,$CLJS.N,xx,$CLJS.bh,Cx,$CLJS.N,Ax,function(){return null}],null),new $CLJS.h(null,5,[yx,"{",Xw,new $CLJS.h(null,1,[nx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,3,[uw,null,vw,null,zx,null],null),null),Cx,new $CLJS.h(null,2,[$CLJS.Fx,"}",Jx,!1],null),Ax,function(a){var b=vw.h(a);b=$CLJS.n(b)?uw.h(a):b;return $CLJS.n(b)?Uba:$CLJS.n(uw.h(a))?Sba:$CLJS.n(vw.h(a))?Tba:Rba}],null),new $CLJS.h(null,5,[yx,"}",Xw,$CLJS.N,xx,new $CLJS.ah(null,
new $CLJS.h(null,1,[uw,null],null),null),Cx,$CLJS.N,Ax,function(){return null}],null),new $CLJS.h(null,5,[yx,"\x3c",Xw,new $CLJS.h(null,4,[Dw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),Cw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Fw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,4,[uw,null,vw,null,zx,null,iy,null],null),null),Cx,new $CLJS.h(null,3,[$CLJS.Fx,"\x3e",Jx,!0,$CLJS.Rt,Ix],null),Ax,function(){return Wba}],
null),new $CLJS.h(null,5,[yx,"\x3e",Xw,$CLJS.N,xx,new $CLJS.ah(null,new $CLJS.h(null,1,[uw,null],null),null),Cx,$CLJS.N,Ax,function(){return null}],null),new $CLJS.h(null,5,[yx,"^",Xw,new $CLJS.h(null,3,[cy,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),ey,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),dy,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,1,[uw,null],null),null),Cx,$CLJS.N,Ax,function(){return function(a,b){var c=cy.h(a),d=ey.h(a),e=
dy.h(a),f=$CLJS.n(uw.h(a))?Vw:Uw;return $CLJS.n($CLJS.n(c)?$CLJS.n(d)?e:d:c)?c<=d&&d<=e?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:$CLJS.n($CLJS.n(c)?d:c)?$CLJS.E.g(c,d)?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:$CLJS.n(c)?$CLJS.E.g(c,0)?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:($CLJS.n(uw.h(a))?$CLJS.td($CLJS.Pi.h(Yw.h(a))):$CLJS.td($CLJS.Pi.h(b)))?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b}}],null),new $CLJS.h(null,5,[yx,"W",Xw,$CLJS.N,xx,new $CLJS.ah(null,new $CLJS.h(null,4,[uw,null,vw,null,
zx,null,iy,null],null),null),Cx,$CLJS.N,Ax,function(a){if($CLJS.n(function(){var c=vw.h(a);return $CLJS.n(c)?c:uw.h(a)}())){var b=$CLJS.gf.g($CLJS.n(vw.h(a))?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ut,null,$CLJS.$x,null],null):$CLJS.Cf,$CLJS.n(uw.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,[iy,!0],null):$CLJS.Cf);return function(c,d){d=pw(d);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.n($CLJS.Se.j(ny,c,b))?new $CLJS.P(null,2,5,$CLJS.Q,[Uw,d],null):d}}return function(c,d){d=pw(d);c=$CLJS.I(d,0,null);d=
$CLJS.I(d,1,null);return $CLJS.n($CLJS.Uv(c))?new $CLJS.P(null,2,5,$CLJS.Q,[Uw,d],null):d}}],null),new $CLJS.h(null,5,[yx,"_",Xw,$CLJS.N,xx,new $CLJS.ah(null,new $CLJS.h(null,3,[uw,null,vw,null,zx,null],null),null),Cx,$CLJS.N,Ax,function(){return cca}],null),new $CLJS.h(null,5,[yx,"I",Xw,new $CLJS.h(null,1,[$CLJS.rk,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null)],null),xx,new $CLJS.ah(null,new $CLJS.h(null,1,[uw,null],null),null),Cx,$CLJS.N,Ax,function(){return bca}],null)]),dca=/^([vV]|#|('.)|([+-]?\d+)|(?=,))/,
jca=new $CLJS.ah(null,new $CLJS.h(null,2,[xw,null,ww,null],null),null),gca=new $CLJS.h(null,2,[":",uw,"@",vw],null),iw=function iw(a){for(;;){if($CLJS.td(a))return!1;var c=iy.h(xx.h(Dx.h($CLJS.A(a))));$CLJS.n(c)||(c=$CLJS.Re(iw,$CLJS.A($CLJS.mx.h(Xw.h($CLJS.A(a))))),c=$CLJS.n(c)?c:$CLJS.Re(iw,$CLJS.A($CLJS.Rt.h(Xw.h($CLJS.A(a))))));if($CLJS.n(c))return!0;a=$CLJS.B(a)}},Tx=$CLJS.Ph(gw),Kx=new $CLJS.h(null,6,[$CLJS.Qj,"'",$CLJS.Uj,"#'",$CLJS.jba,"@",$CLJS.kba,"~",Lca,"@",Jca,"~"],null);
(function(){var a=Tx("~\x3c[~;~@{~w~^, ~:_~}~;]~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=hw(d);return jw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()})();
var py=function(){var a=Tx("~\x3c#{~;~@{~w~^ ~:_~}~;}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=hw(d);return jw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),qy=new $CLJS.h(null,2,["core$future_call","Future","core$promise","Promise"],null),eda=function(){var a=Tx("~\x3c\x3c-(~;~@{~w~^ ~_~}~;)-\x3c~:\x3e");
return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=hw(d);return jw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),ry,fda=$CLJS.Xe($CLJS.N),gda=$CLJS.Xe($CLJS.N),hda=$CLJS.Xe($CLJS.N),ida=$CLJS.Xe($CLJS.N),jda=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
ry=new $CLJS.ei($CLJS.Fh.g("cljs.pprint","simple-dispatch"),Rx,jda,fda,gda,hda,ida);Cu(ry,$CLJS.Cj,function(a){if($CLJS.Va(Lx(a)))if($CLJS.n(Xv()))$CLJS.ac($CLJS.Iu,"#");else{var b=Wv,c=Rv;Wv+=1;Rv=0;try{Lv("(",")");for(var d=0,e=$CLJS.y(a);;){if($CLJS.Va($CLJS.Na)||d<$CLJS.Na){if(e&&($CLJS.Uv($CLJS.A(e)),$CLJS.B(e))){$CLJS.ac($CLJS.Iu," ");bw(aw);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.ac($CLJS.Iu,"...");break}Pv()}finally{Rv=c,Wv=b}}return null});Cu(ry,$CLJS.Kj,Mx);Cu(ry,$CLJS.ik,Nx);
Cu(ry,$CLJS.$i,py);Cu(ry,null,function(){return $CLJS.ac($CLJS.Iu,$CLJS.Mh.l($CLJS.H([null])))});Cu(ry,$CLJS.di,Ox);Tv=ry;
var sy=function(){var a=Tx("~:\x3c~w~^ ~@_~w~^ ~_~@{~w~^ ~_~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=hw(d);return jw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),ty=function(){var a=Tx("~:\x3c~1I~w~^ ~@_~w~@{ ~_~w~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=
Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=hw(d);return jw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),uy=$CLJS.N,kda=function(a){return $CLJS.eg.g($CLJS.N,$CLJS.jf($CLJS.Td,$CLJS.H([function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=new $CLJS.P(null,
2,5,$CLJS.Q,[t,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fh.h($CLJS.gh($CLJS.A(t))),$CLJS.hd(t)],null)],null);l.add(t);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fh.h($CLJS.gh($CLJS.A(l))),$CLJS.hd(l)],null)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}()])))}(function(a){return $CLJS.eg.g($CLJS.N,$CLJS.hf.g(function(b){var c=$CLJS.I(b,0,null),d=$CLJS.I(b,
1,null);var e=$CLJS.ie(c);e=$CLJS.n(e)?e:$CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,24,[$CLJS.aj,"null",$CLJS.qj,"null",$CLJS.sk,"null",$CLJS.uk,"null",$CLJS.lj,"null",$CLJS.Mj,"null",$CLJS.Ij,"null",$CLJS.Nj,"null",$CLJS.ri,"null",$CLJS.Xj,"null",$CLJS.Bj,"null",$CLJS.tj,"null",$CLJS.ek,"null",$CLJS.tk,"null",$CLJS.Si,"null",$CLJS.Ni,"null",$CLJS.jj,"null",$CLJS.vj,"null",$CLJS.bj,"null",$CLJS.Uj,"null",$CLJS.Qj,"null",$CLJS.lk,"null",$CLJS.Ji,"null",$CLJS.hk,"null"],null),null),c);return $CLJS.Va(e)?
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fh.g("clojure.core",$CLJS.gh(c)),d],null):b},a))}($CLJS.Ig([$CLJS.bj,$CLJS.Ni,Bca,$CLJS.Xj,Gca,yca,Aca,Hca,Eca,Dca,Fca,Tca,Nca,$CLJS.hk,Mca,Rca,Pca,Zca,wca,$CLJS.tj,Uca,Vca,Yca,$CLJS.Rj,Kca,$ca,xca,zca,Cca,Wca],[sy,function(a){var b=$CLJS.hd(a),c=$CLJS.A($CLJS.Lc($CLJS.Lc(a)));if($CLJS.zd(b)){a=uy;uy=$CLJS.E.g(1,$CLJS.D(b))?$CLJS.Ee([$CLJS.A(b),"%"]):$CLJS.eg.g($CLJS.N,$CLJS.hf.j(function(d,e){return new $CLJS.P(null,2,5,$CLJS.Q,[d,["%",$CLJS.p.h(e)].join("")],
null)},b,$CLJS.Eu(1,$CLJS.D(b)+1)));try{return function(){var d=Tx("~\x3c#(~;~@{~w~^ ~_~}~;)~:\x3e");return function(){function e(k){var l=null;if(0<arguments.length){l=0;for(var m=Array(arguments.length-0);l<m.length;)m[l]=arguments[l+0],++l;l=new $CLJS.w(m,0,null)}return f.call(this,l)}function f(k){k=hw(k);return jw(d,k)}e.A=0;e.B=function(k){k=$CLJS.y(k);return f(k)};e.l=f;return e}()}()(c)}finally{uy=a}}else return Ux(a)},Wx,ty,function(a){if(3<$CLJS.D(a)){if($CLJS.n(Xv()))$CLJS.ac($CLJS.Iu,
"#");else{var b=Wv,c=Rv;Wv+=1;Rv=0;try{Lv("(",")");fw(cw,1);$CLJS.Se.g(function(){var l=Tx("~w ~@_~w ~@_~w ~_");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=hw(u);return jw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}(),a);for(var d=0,e=$CLJS.y($CLJS.bf(3,a));;){if($CLJS.Va($CLJS.Na)||d<$CLJS.Na){if(e){if($CLJS.n(Xv()))$CLJS.ac($CLJS.Iu,
"#");else{a=Wv;var f=Rv;Wv+=1;Rv=0;try{Lv(null,null),$CLJS.Uv($CLJS.A(e)),$CLJS.B(e)&&($CLJS.ac($CLJS.Iu," "),bw(Zv),$CLJS.Uv($CLJS.hd(e))),Pv()}finally{Rv=f,Wv=a}}if($CLJS.B($CLJS.Lc(e))){$CLJS.ac($CLJS.Iu," ");bw(aw);a=d+1;var k=$CLJS.B($CLJS.Lc(e));d=a;e=k;continue}}}else $CLJS.ac($CLJS.Iu,"...");break}Pv()}finally{Rv=c,Wv=b}}return null}return Ux(a)},sy,Vx,Vx,Wx,sy,Wx,ty,ty,sy,ty,Wx,Wx,sy,Wx,function(a){if($CLJS.B(a)){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=
"string"===typeof $CLJS.A(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(c),$CLJS.B(c)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,c],null);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=$CLJS.xd($CLJS.A(d))?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(d),$CLJS.B(d)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);if($CLJS.n(Xv()))$CLJS.ac($CLJS.Iu,"#");else{d=Wv;var k=Rv;Wv+=1;Rv=0;try{Lv("(",")");(function(){var m=Tx("~w ~1I~@_~w");return function(){function t(v){var x=
null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.w(z,0,null)}return u.call(this,x)}function u(v){v=hw(v);return jw(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};t.l=u;return t}()})()(a,b);$CLJS.n($CLJS.n(c)?c:$CLJS.n(e)?e:$CLJS.y(f))&&function(){var m=Tx("~@:_");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.w(z,0,null)}return u.call(this,
x)}function u(v){v=hw(v);return jw(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};t.l=u;return t}()}()();$CLJS.n(c)&&kw(!0,'"~a"~:[~;~:@_~]',$CLJS.H([c,$CLJS.n(e)?e:$CLJS.y(f)]));$CLJS.n(e)&&function(){var m=Tx("~w~:[~;~:@_~]");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.w(z,0,null)}return u.call(this,x)}function u(v){v=hw(v);return jw(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};
t.l=u;return t}()}()(e,$CLJS.y(f));for(a=f;;){sca($CLJS.A(a));var l=$CLJS.B(a);if(l)b=l,bw(aw),a=b;else break}Pv()}finally{Rv=k,Wv=d}}return null}return $CLJS.Uv(a)},Wx,function(a){if($CLJS.n(Xv()))$CLJS.ac($CLJS.Iu,"#");else{var b=Wv,c=Rv;Wv+=1;Rv=0;try{Lv("(",")");fw(cw,1);$CLJS.Uv($CLJS.A(a));if($CLJS.B(a)){$CLJS.ac($CLJS.Iu," ");bw(aw);for(var d=0,e=$CLJS.B(a);;){if($CLJS.Va($CLJS.Na)||d<$CLJS.Na){if(e){if($CLJS.n(Xv()))$CLJS.ac($CLJS.Iu,"#");else{a=Wv;var f=Rv;Wv+=1;Rv=0;try{Lv(null,null),$CLJS.Uv($CLJS.A(e)),
$CLJS.B(e)&&($CLJS.ac($CLJS.Iu," "),bw(Zv),$CLJS.Uv($CLJS.hd(e))),Pv()}finally{Rv=f,Wv=a}}if($CLJS.B($CLJS.Lc(e))){$CLJS.ac($CLJS.Iu," ");bw(aw);a=d+1;var k=$CLJS.B($CLJS.Lc(e));d=a;e=k;continue}}}else $CLJS.ac($CLJS.Iu,"...");break}}Pv()}finally{Rv=c,Wv=b}}return null},Wx,Vx,Vx,sy,sy,Wx,Wx,sy]))),vy,lda=$CLJS.Xe($CLJS.N),mda=$CLJS.Xe($CLJS.N),nda=$CLJS.Xe($CLJS.N),oda=$CLJS.Xe($CLJS.N),pda=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
vy=new $CLJS.ei($CLJS.Fh.g("cljs.pprint","code-dispatch"),Rx,pda,lda,mda,nda,oda);Cu(vy,$CLJS.Cj,function(a){if($CLJS.Va(Lx(a))){var b=kda.call(null,$CLJS.A(a));return $CLJS.n(b)?b.h?b.h(a):b.call(null,a):Ux(a)}return null});Cu(vy,$CLJS.Hi,function(a){var b=a.h?a.h(uy):a.call(null,uy);return $CLJS.n(b)?Gw.l($CLJS.H([b])):$CLJS.n(my)?Gw.l($CLJS.H([$CLJS.gh(a)])):Hu.call(null,a)});Cu(vy,$CLJS.Kj,Mx);Cu(vy,$CLJS.ik,Nx);Cu(vy,$CLJS.$i,py);Cu(vy,Px,eda);
Cu(vy,Qx,function(a){var b=$CLJS.p,c=b.h,d=$CLJS.ab(a).name;var e=$CLJS.oh(/^[^$]+\$[^$]+/,d);e=$CLJS.n(e)?qy.h?qy.h(e):qy.call(null,e):null;b=["#\x3c",c.call(b,$CLJS.n(e)?e:d),"@",$CLJS.p.h($CLJS.ya(a)),": "].join("");if($CLJS.n(Xv()))$CLJS.ac($CLJS.Iu,"#");else{c=Wv;d=Rv;Wv+=1;Rv=0;try{Lv(b,"\x3e");fw(cw,-(b.length-2));bw(aw);var f=null!=a?a.I&1||$CLJS.Bc===a.jj?!0:a.I?!1:$CLJS.$a(yu,a):$CLJS.$a(yu,a);var k=f?!zu(a):f;$CLJS.Uv(k?Oca:$CLJS.q(a));Pv()}finally{Rv=d,Wv=c}}return null});Cu(vy,null,Hu);
Cu(vy,$CLJS.di,Ox);Tv=ry;