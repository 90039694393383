var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./no.en.core.js");
'use strict';var W1,Vna,X1,a2,c2;W1=function(a,b,c,d,e){this.pattern=a;this.Fc=b;this.S=c;this.G=d;this.J=e;this.C=2230716170;this.I=139264};Vna=function(a,b){return new W1(a,b,null,null,null)};X1=function(a,b){$CLJS.Hd($CLJS.fh($CLJS.q(a)),b)||$CLJS.Oh.j(a,$CLJS.be,b)};$CLJS.Y1=function(a){if(null==a||"string"===typeof a)return a;if(a instanceof $CLJS.M||a instanceof $CLJS.r){var b=$CLJS.ie(a);return $CLJS.n(b)?[b,"/",$CLJS.gh(a)].join(""):$CLJS.gh(a)}return null};
a2=function(a){a=$CLJS.y($CLJS.Se.g(Z1,a));for(var b=null,c=0,d=0;;)if(d<c){var e=b.X(null,d);X1($CLJS.$1,e);d+=1}else if(a=$CLJS.y(a))b=a,$CLJS.Ad(b)?(a=$CLJS.lc(b),c=$CLJS.mc(b),b=a,e=$CLJS.D(a),a=c,c=e):(e=$CLJS.A(b),X1($CLJS.$1,e),a=$CLJS.B(b),b=null,c=0),d=0;else break};
c2=function(a){a=$CLJS.y($CLJS.Se.g(Z1,a));for(var b=null,c=0,d=0;;)if(d<c){var e=b.X(null,d);X1(b2,e);d+=1}else if(a=$CLJS.y(a))b=a,$CLJS.Ad(b)?(a=$CLJS.lc(b),c=$CLJS.mc(b),b=a,e=$CLJS.D(a),a=c,c=e):(e=$CLJS.A(b),X1(b2,e),a=$CLJS.B(b),b=null,c=0),d=0;else break};var b2,e2;$CLJS.g=W1.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "pattern":return this.pattern;case "replacement":return this.Fc;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#inflections.core.Rule{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uA,this.pattern],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vA,this.Fc],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Lt(this,2,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uA,$CLJS.vA],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};
$CLJS.g.ha=function(){return 2+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1327743444^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.pattern,b.pattern)&&$CLJS.E.g(this.Fc,b.Fc)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.vA,null,$CLJS.uA,null],null),null),b)?$CLJS.Hk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new W1(this.pattern,this.Fc,this.S,$CLJS.Fe($CLJS.Hk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "pattern":case "replacement":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.uA,b):$CLJS.he.call(null,$CLJS.uA,b))?new W1(c,this.Fc,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.vA,b):$CLJS.he.call(null,$CLJS.vA,b))?new W1(this.pattern,c,this.S,this.G,null):new W1(this.pattern,this.Fc,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.Yf($CLJS.uA,this.pattern),new $CLJS.Yf($CLJS.vA,this.Fc)],null),this.G))};
$CLJS.g.P=function(a,b){return new W1(this.pattern,this.Fc,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};var Z1=function Z1(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Z1.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};Z1.l=function(a){return $CLJS.hf.g(function(b){return $CLJS.Se.g(Vna,b)},$CLJS.st(2,2,a))};Z1.A=0;Z1.B=function(a){return this.l($CLJS.y(a))};
$CLJS.d2=$CLJS.Xe(new $CLJS.ah(null,new $CLJS.h(null,81,["gossip",null,"sadness",null,"space",null,"happiness",null,"blood",null,"silver",null,"cotton",null,"species",null,"mist",null,"paper",null,"education",null,"wine",null,"up",null,"food",null,"sugar",null,"gold",null,"literature",null,"pork",null,"lightning",null,"sheep",null,"shopping",null,"fresh",null,"news",null,"pepper",null,"experience",null,"milk",null,"honey",null,"oxygen",null,"fiction",null,"luck",null,"vinegar",null,"coffee",null,
"ground",null,"peanut",null,"confusion",null,"tennis",null,"power",null,"meat",null,"butter",null,"music",null,"luggage",null,"love",null,"grass",null,"knowledge",null,"time",null,"cheese",null,"jam",null,"thunder",null,"electricity",null,"gum",null,"sunshine",null,"history",null,"snow",null,"money",null,"wool",null,"rice",null,"series",null,"liquid",null,"equipment",null,"pressure",null,"oil",null,"information",null,"steam",null,"chewing",null,"petrol",null,"research",null,"patience",null,"toothpaste",
null,"speed",null,"entertainment",null,"wood",null,"tea",null,"art",null,"washing",null,"forgiveness",null,"traffic",null,"alcohol",null,"ice",null,"homework",null,"fish",null,"air",null],null),null));$CLJS.$1=$CLJS.Xe($CLJS.Cf);b2=$CLJS.Xe($CLJS.Cf);e2=$CLJS.Xe(function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.fb($CLJS.pb,$CLJS.hl,0<b.length?new $CLJS.w(b.slice(0),0,null):null)}());
a2($CLJS.H([/$/i,"s",/s$/i,"s",/(ax|test)is$/i,"$1es",/(octop|vir)us$/i,"$1i",/(alias|status)$/i,"$1es",/(bu)s$/i,"$1ses",/(buffal|tomat)o$/i,"$1oes",/([ti])um$/i,"$1a",/sis$/i,"ses",/(?:([^f])fe|([lr])f)$/i,"$1$2ves",/(hive)$/i,"$1s",/([^aeiouy]|qu)y$/i,"$1ies",/(x|ch|ss|sh)$/i,"$1es",/(matr|vert|ind)(?:ix|ex)$/i,"$1ices",/([m|l])ouse$/i,"$1ice",/^(ox)$/i,"$1en",/(quiz)$/i,"$1zes"]));
c2($CLJS.H([/s$/i,"",/(ss)$/i,"$1",/(n)ews$/i,"$1ews",/([ti])a$/i,"$1um",/((a)naly|(b)a|(d)iagno|(p)arenthe|(p)rogno|(s)ynop|(t)he)(sis|ses)$/i,"$1$2sis",/(^analy)(sis|ses)$/i,"$1sis",/([^f])ves$/i,"$1fe",/(hive)s$/i,"$1",/(tive)s$/i,"$1",/([lr])ves$/i,"$1f",/([^aeiouy]|qu)ies$/i,"$1y",/(s)eries$/i,"$1eries",/(m)ovies$/i,"$1ovie",/(x|ch|ss|sh)es$/i,"$1",/([m|l])ice$/i,"$1ouse",/(bus)(es)?$/i,"$1",/(o)es$/i,"$1",/(shoe)s$/i,"$1",/(cris|ax|test)(is|es)$/i,"$1is",/(octop|vir)(us|i)$/i,"$1us",/(alias|status)(es)?$/i,
"$1",/^(ox)en/i,"$1",/(vert|ind)ices$/i,"$1ex",/(matr)ices$/i,"$1ix",/(quiz)zes$/i,"$1",/(database)s$/i,"$1"]));
$CLJS.mh($CLJS.hf.g(function(a){var b=$CLJS.A(a);a=$CLJS.hd(a);b=$CLJS.Y1(b).toLowerCase();a=$CLJS.Y1(a).toLowerCase();$CLJS.Oh.j($CLJS.d2,$CLJS.Ik,$CLJS.Y1(b).toLowerCase());$CLJS.Oh.j($CLJS.d2,$CLJS.Ik,$CLJS.Y1(a).toLowerCase());c2($CLJS.H([$CLJS.ph(["^",a,"$"].join("")),b]));a2($CLJS.H([$CLJS.ph(["^",b,"$"].join("")),a]));$CLJS.Oh.j(e2,$CLJS.be,b);return $CLJS.Oh.j(e2,$CLJS.be,a)},new $CLJS.P(null,14,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,["amenity","amenities"],null),new $CLJS.P(null,2,5,$CLJS.Q,
["child","children"],null),new $CLJS.P(null,2,5,$CLJS.Q,["cow","kine"],null),new $CLJS.P(null,2,5,$CLJS.Q,["foot","feet"],null),new $CLJS.P(null,2,5,$CLJS.Q,["louse","lice"],null),new $CLJS.P(null,2,5,$CLJS.Q,["mailman","mailmen"],null),new $CLJS.P(null,2,5,$CLJS.Q,["man","men"],null),new $CLJS.P(null,2,5,$CLJS.Q,["mouse","mice"],null),new $CLJS.P(null,2,5,$CLJS.Q,["move","moves"],null),new $CLJS.P(null,2,5,$CLJS.Q,["ox","oxen"],null),new $CLJS.P(null,2,5,$CLJS.Q,["person","people"],null),new $CLJS.P(null,
2,5,$CLJS.Q,["sex","sexes"],null),new $CLJS.P(null,2,5,$CLJS.Q,["tooth","teeth"],null),new $CLJS.P(null,2,5,$CLJS.Q,["woman","women"],null)],null)));