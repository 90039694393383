var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var aM,bM,cM,yha,zha,gM,Aha,jM,kM,lM,mM,Bha,nM,oM,qM,rM,dM,sM,Cha,tM,Dha,uM,vM,wM,Eha,xM,yM,Fha,zM,Gha,AM,BM,Hha,DM,Iha,Jha,Kha,FM,GM,HM,IM,JM,KM,LM,MM,Lha,NM,OM,PM,RM,SM,Mha,TM,UM,VM,WM,Nha,XM,YM,ZM,$M,Oha,aN,bN,cN,dN,Pha,eN,Qha,fN,gN,hN,Rha,iN,jN,kN,lN,nN,oN,Sha,pN,qN,Tha,rN,sN,tN,uN,vN,Uha,wN,Vha,xN,yN,AN,Wha,BN,CN,DN,Xha,GN,HN,Yha,Zha,IN,KN,LN,MN,$ha,PN,QN,aia,bia,cia,RN,SN,TN,UN,VN,WN,dia,XN,eia,YN,ZN,aO,cO,dO,eO,fO,fia,hO,gia,hia,iO,jO,kO,lO,mO,iia,nO,jia,kia,oO,pO,qO,rO,sO,lia,
mia,nia,tO,oia,uO,pia,wO,xO,qia,zO,AO,BO,CO,ria,FO,sia,tia,GO,HO,IO,uia,JO,KO,LO,MO,via,OO,PO,QO,RO,SO,wia,TO,UO,xia,VO,WO,XO,YO,ZO,$O,bP,yia,cP,zia,dP,eP,fP,Aia,Bia,Cia,hP,iP,jP,Dia,kP,lP,mP,nP,Eia,Fia,pP,Gia,Hia,qP,rP,sP,tP,uP,Iia,vP,wP,xP,yP,Jia,zP,AP,BP,CP,EP,Kia,FP,GP,Lia,HP,IP,JP,Mia,KP,Nia,MP,Oia,NP,OP,PP,QP,RP,SP,TP,Pia,Qia,Ria,UP,Sia,VP,WP,Tia,Uia,XP,YP,ZP,Via,$P,Wia,aQ,Xia,Yia,bQ,Zia,$ia,aja,eQ,fQ,bja,gQ,hQ,kQ,cja,lQ,dja,mQ,nQ,oQ,eja,fja,pQ,qQ,rQ,sQ,gja,uQ,vQ,hja,wQ,xQ,yQ,zQ,AQ,BQ,CQ,DQ,
ija,EQ,FQ,jja,GQ,kja,HQ,IQ,JQ,lja,KQ,LQ,mja,nja,MQ,NQ,OQ,oja,pja,PQ,qja,rja,sja,RQ,tja,TQ,uja,vja,UQ,VQ,wja,xja,yja,WQ,XQ,YQ,ZQ,$Q,aR,bR,zja,cR,dR,Aja,eR,Bja,Cja,Dja,fR,gR,hR,Eja,iR,jR,Fja,kR,Gja,lR,Hja,Ija,nR,oR,pR,qR,rR,Jja,sR,tR,Kja,uR,vR,wR,xR,yR,zR,AR,BR,Lja,CR,DR,FR,GR,HR,IR,JR,KR,LR,MR,NR,OR,Mja,PR,QR,RR,SR,TR,Nja,UR,VR,WR,YR,Oja,$R,Pja,Qja,bS,Rja,cS,Sja,dS,Tja,Uja,eS,Vja,fS,hS,iS,Wja,mS,nS,Xja,Yja,oS,pS,qS,rS,sS,tS,uS,wS,xS,Zja;
$CLJS.$L=function(a){var b=$CLJS.yo.g(a,null),c=$CLJS.yF(b,$CLJS.Ys,function(d){return $CLJS.xn(d,$CLJS.Cf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.ql,b,$CLJS.Dj,k,$CLJS.dt,l],null):null}function e(k){return f.j(k,$CLJS.Cf,$CLJS.Cf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
aM=function(a,b){return $CLJS.xd(a)?$CLJS.J.g(a,b):a};bM=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.An($CLJS.yo.g($CLJS.ql.h(a),null));if($CLJS.n(b)){var e=aM($CLJS.Et.h(b),c);$CLJS.n(e)?(e=$CLJS.Iq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:aM($CLJS.Ct.h(b),c)}return null};cM=function(a,b){return $CLJS.vd(a)||$CLJS.Jl(a)?$CLJS.J.g(a,b):$CLJS.wd(a)?$CLJS.J.g($CLJS.Df(a),b):null};
yha=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.ql),d=$CLJS.J.g(a,$CLJS.mj);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.dt,$CLJS.iea),f=$CLJS.J.j(b,$CLJS.dea,!0),k=$CLJS.J.g(b,$CLJS.oC),l=$CLJS.J.j(b,$CLJS.mC,$CLJS.kC),m=bM(a,$CLJS.Uq.h(c),k,b);if($CLJS.n(m))return m;m=bM(a,$CLJS.pt.h(c),k,b);if($CLJS.n(m))return m;m=bM(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=bM(a,function(){var t=$CLJS.fo.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=bM(a,$CLJS.Uq.h(c),
l,b);if($CLJS.n(m))return m;m=bM(a,$CLJS.pt.h(c),l,b);if($CLJS.n(m))return m;d=bM(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=bM(a,function(){var t=$CLJS.fo.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?bM(a,e.h?e.h($CLJS.iC):e.call(null,$CLJS.iC),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?bM(a,e.h?e.h($CLJS.iC):e.call(null,$CLJS.iC),l,b):f};
zha=function(a,b,c){a=$CLJS.Q;var d=$CLJS.eg.g;var e=$CLJS.ml.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.ql),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.oC);l=$CLJS.J.j(l,$CLJS.mC,$CLJS.kC);k=$CLJS.Uq.h(k);f=aM($CLJS.lC.h(k),f);f=$CLJS.n(f)?f:aM($CLJS.lC.h(k),l);d=d.call($CLJS.eg,e,f);return new $CLJS.P(null,2,5,a,[d,yha(b,c)],null)};
$CLJS.eM=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Dj);a=$CLJS.J.g(b,$CLJS.dt);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.eea,$CLJS.Qi),f=$CLJS.J.j(d,$CLJS.gea,zha);return $CLJS.n(a)?$CLJS.fb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Qi,m);l=e.h?e.h(l):e.call(null,l);return dM(k,c,t,l)},null,a):null};
gM=function(a,b,c){var d=$CLJS.LA($CLJS.q($CLJS.mE),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Oh.v($CLJS.mE,$CLJS.fM,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.hM=function(a){return gM($CLJS.xk,a,function(){return $CLJS.zF.h(a)})};Aha=function(){var a=iM;return gM($CLJS.Ys,a,function(){var b=$CLJS.zF.h(a),c=$CLJS.$L(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
jM=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,$CLJS.me(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};kM=function(a){if($CLJS.zd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.is,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,jM(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,jM(c)],null);default:return jM(a)}}else return jM(a)};
lM=function(a,b){var c=$CLJS.zd(b);return c?(c=$CLJS.A(b)instanceof $CLJS.M)?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c:c};
mM=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Ve(lM,a)],null),$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,a],null)],null)],null),function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,kM(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,kM(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.st(2,2,b))}())],null)};Bha=function(a){return $CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M?$CLJS.A(a):null};
nM=function(a){return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Is,new $CLJS.h(null,2,[$CLJS.Oi,Bha,$CLJS.Ct,["valid instance of one of these MBQL clauses: ",$CLJS.Nt(", ",$CLJS.hf.g($CLJS.A,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.me(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.me(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
oM=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZL],null)],null)};$CLJS.pM={};qM={};rM={};$CLJS.fM=function fM(a,b,c){var e=$CLJS.y(b);b=$CLJS.A(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=fM.j?fM.j(k,f,c):fM.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
dM=function dM(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.A(f);var k=$CLJS.B(f),l=cM(b,c);f=$CLJS.n(a)?a:$CLJS.wd(b)?$CLJS.Cf:$CLJS.yd(b)?$CLJS.N:$CLJS.jd(b);return $CLJS.n($CLJS.n(c)?$CLJS.jC.h($CLJS.pd(f)):c)?a:$CLJS.n(c)?(b=cM(f,c),d=dM.v?dM.v(b,l,k,d):dM.call(null,b,l,k,d),$CLJS.Ol(c)&&$CLJS.wd(f)&&c>$CLJS.D(f)&&(b=$CLJS.df(c-$CLJS.D(f),null),b=$CLJS.gf.g(f,b),f=null==f||$CLJS.Dd(f)?b:$CLJS.eg.g($CLJS.jd(f),b)),null==f||$CLJS.Jl(f)?$CLJS.R.j(f,c,d):$CLJS.vd(f)?$CLJS.be.g(f,d):$CLJS.Se.g($CLJS.U,$CLJS.R.j($CLJS.Df(f),
c,d))):$CLJS.xd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.fea],null),dM.v?dM.v(f,b,c,d):dM.call(null,f,b,c,d)):$CLJS.n($CLJS.jC.h($CLJS.pd(f)))?$CLJS.be.g(f,d):$CLJS.zd($CLJS.Fe(f))?f:$CLJS.od(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.jC,!0],null))};sM=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Cha=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
tM=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);Dha=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);uM=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);vM=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);wM=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
Eha=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);xM=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);yM=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Fha=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);zM=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
Gha=new $CLJS.M(null,"from","from",1815293044);AM=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);BM=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);Hha=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.CM=new $CLJS.M(null,"snippet-name","snippet-name",819240328);DM=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
Iha=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Jha=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.EM=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);Kha=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);FM=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
GM=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);HM=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);IM=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);JM=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);KM=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
LM=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);MM=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Lha=new $CLJS.M(null,"lon-max","lon-max",1590224717);NM=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);OM=new $CLJS.r(null,"stddev","stddev",775056588,null);PM=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.QM=new $CLJS.M(null,"snippet","snippet",953581994);RM=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);SM=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Mha=new $CLJS.M(null,"lat-field","lat-field",-830652957);TM=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);UM=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
VM=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);WM=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Nha=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);XM=new $CLJS.M("location","country","location/country",1666636202);YM=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
ZM=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);$M=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Oha=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);aN=new $CLJS.M(null,"unary","unary",-989314568);bN=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
cN=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);dN=new $CLJS.r(null,"ceil","ceil",-184398425,null);Pha=new $CLJS.M(null,"lon-min","lon-min",-787291357);eN=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Qha=new $CLJS.M(null,"match","match",1220059550);fN=new $CLJS.r(null,"count-where","count-where",2025939247,null);gN=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
hN=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Rha=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);iN=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);jN=new $CLJS.r(null,"sum","sum",1777518341,null);kN=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);lN=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.mN=new $CLJS.M("date","range","date/range",1647265776);nN=new $CLJS.r(null,"between","between",-1523336493,null);oN=new $CLJS.M(null,"clause-form","clause-form",1820463737);Sha=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);pN=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);qN=new $CLJS.r(null,"field","field",338095027,null);Tha=new $CLJS.M(null,"segment-id","segment-id",1810133590);
rN=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);sN=new $CLJS.r(null,"not-null","not-null",313812992,null);tN=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);uN=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);vN=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Uha=new $CLJS.M(null,"template-tag","template-tag",310841038);
wN=new $CLJS.M(null,"invalid","invalid",412869516);Vha=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);xN=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);yN=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.zN=new $CLJS.M(null,"context","context",-830191113);AN=new $CLJS.r(null,"get-year","get-year",704520253,null);
Wha=new $CLJS.M(null,"format-rows?","format-rows?",992129486);BN=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);CN=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);DN=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.EN=new $CLJS.M(null,"parameters","parameters",-1229919748);Xha=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.FN=new $CLJS.M(null,"card","card",-1430355152);GN=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);HN=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);Yha=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Zha=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);IN=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.JN=new $CLJS.M("date","month-year","date/month-year",1948031290);
KN=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);LN=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);MN=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.NN=new $CLJS.M(null,"joins","joins",1033962699);$ha=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.ON=new $CLJS.M(null,"source-field","source-field",933829534);PN=new $CLJS.r(null,"Field","Field",430385967,null);
QN=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);aia=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);bia=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);cia=new $CLJS.M(null,"items","items",1031954938);RN=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);SN=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
TN=new $CLJS.M(null,"more","more",-2058821800);UN=new $CLJS.M(null,"first-clause","first-clause",-20953491);VN=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);WN=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);dia=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);XN=new $CLJS.r(null,"contains","contains",-1977535957,null);
eia=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);YN=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);ZN=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.$N=new $CLJS.M(null,"widget-type","widget-type",1836256899);aO=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.bO=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
cO=new $CLJS.r(null,"is-null","is-null",-356519403,null);dO=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);eO=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);fO=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);fia=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.gO=new $CLJS.M(null,"required","required",1807647006);hO=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);
gia=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);hia=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);iO=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);jO=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);kO=new $CLJS.M("string","contains","string/contains",1602423827);lO=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);mO=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);
iia=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);nO=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);jia=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);kia=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);oO=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);
pO=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);qO=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);rO=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);sO=new $CLJS.r(null,"share","share",1051097594,null);lia=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);
mia=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);nia=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);tO=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);oia=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);uO=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);
pia=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.vO=new $CLJS.M(null,"collection","collection",-683361892);wO=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);xO=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);$CLJS.yO=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);
qia=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);zO=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);AO=new $CLJS.r(null,"metric","metric",2049329604,null);BO=new $CLJS.r(null,"concat","concat",-467652465,null);CO=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.DO=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);ria=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);
$CLJS.EO=new $CLJS.M("date","relative","date/relative",25987732);FO=new $CLJS.M("location","city","location/city",-1746973325);sia=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);tia=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);GO=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);HO=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);IO=new $CLJS.M("number","between","number/between",97700581);
uia=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);JO=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);KO=new $CLJS.r(null,"sqrt","sqrt",370479598,null);LO=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);MO=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);$CLJS.NO=new $CLJS.M(null,"semantic_type","semantic_type",272485089);
via=new $CLJS.M(null,"metric-id","metric-id",-686486942);OO=new $CLJS.r(null,"*","*",345799209,null);PO=new $CLJS.r(null,"+","+",-740910886,null);QO=new $CLJS.r(null,"-","-",-471816912,null);RO=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);SO=new $CLJS.M(null,"allowed-for","allowed-for",122724334);wia=new $CLJS.M(null,"question","question",-1411720117);TO=new $CLJS.r(null,"asc","asc",1997386096,null);
UO=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);xia=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);VO=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);WO=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);XO=new $CLJS.r(null,"\x3c","\x3c",993667236,null);YO=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);ZO=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
$O=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.aP=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);bP=new $CLJS.M("string","ends-with","string/ends-with",302681156);yia=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);cP=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);zia=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
dP=new $CLJS.r(null,"and","and",668631710,null);eP=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);fP=new $CLJS.r(null,"round","round",-645002441,null);Aia=new $CLJS.M(null,"to","to",192099007);$CLJS.gP=new $CLJS.M("date","single","date/single",1554682003);Bia=new $CLJS.M(null,"action-id","action-id",-1727958578);Cia=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);hP=new $CLJS.r(null,"exp","exp",1378825265,null);
iP=new $CLJS.r(null,"Filter","Filter",-424893332,null);jP=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);Dia=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);kP=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);lP=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);mP=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
nP=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.oP=new $CLJS.M(null,"source-table","source-table",-225307692);Eia=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);Fia=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);pP=new $CLJS.r(null,"floor","floor",-772394748,null);Gia=new $CLJS.M(null,"middleware","middleware",1462115504);
Hia=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);qP=new $CLJS.M(null,"requires-features","requires-features",-101116256);rP=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);sP=new $CLJS.M(null,"clause-name","clause-name",-996419059);tP=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);uP=new $CLJS.r(null,"now","now",-9994004,null);
Iia=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);vP=new $CLJS.r(null,"not","not",1044554643,null);wP=new $CLJS.r(null,"avg","avg",1837937727,null);xP=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);yP=new $CLJS.M(null,"max-results","max-results",-32858165);Jia=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);zP=new $CLJS.r(null,"case","case",-1510733573,null);
AP=new $CLJS.r(null,"distinct","distinct",-148347594,null);BP=new $CLJS.r(null,"get-second","get-second",-425414791,null);CP=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.DP=new $CLJS.M(null,"join-alias","join-alias",1454206794);EP=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Kia=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
FP=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);GP=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Lia=new $CLJS.M(null,"original","original",-445386197);HP=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);IP=new $CLJS.r(null,"abs","abs",1394505050,null);JP=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Mia=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
KP=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Nia=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.LP=new $CLJS.M(null,"date","date",-1463434462);MP=new $CLJS.M(null,"second-clause","second-clause",-461435645);Oia=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);NP=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
OP=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);PP=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);QP=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);RP=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);SP=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);TP=new $CLJS.r(null,"or","or",1876275696,null);
Pia=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Qia=new $CLJS.M(null,"constraints","constraints",422775616);Ria=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);UP=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Sia=new $CLJS.M(null,"csv-download","csv-download",2141432084);VP=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
WP=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Tia=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Uia=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);XP=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);YP=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
ZP=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Via=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);$P=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);Wia=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);aQ=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);Xia=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
Yia=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);bQ=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);Zia=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);$ia=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);aja=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.cQ=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.dQ=new $CLJS.M(null,"database","database",1849087575);eQ=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);fQ=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);bja=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);gQ=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
hQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.jQ=new $CLJS.M(null,"expressions","expressions",255689909);kQ=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);cja=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);lQ=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
dja=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);mQ=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);nQ=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);oQ=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);eja=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);fja=new $CLJS.M(null,"action","action",-811238024);
pQ=new $CLJS.r(null,"get-day","get-day",1768100384,null);qQ=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);rQ=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);sQ=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.tQ=new $CLJS.M(null,"native","native",-613060878);gja=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);uQ=new $CLJS.M(null,"page","page",849072397);
vQ=new $CLJS.r(null,"length","length",-2065447907,null);hja=new $CLJS.M(null,"dashboard","dashboard",-631747508);wQ=new $CLJS.r(null,"get-week","get-week",752472178,null);xQ=new $CLJS.r(null,"get-month","get-month",1271156796,null);yQ=new $CLJS.r(null,"dimension","dimension",-2111181571,null);zQ=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);AQ=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
BQ=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);CQ=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);DQ=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);ija=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);EQ=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);FQ=new $CLJS.r(null,"substring","substring",-1513569493,null);
jja=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);GQ=new $CLJS.M(null,"internal","internal",-854870097);kja=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);HQ=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);IQ=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
JQ=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);lja=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);KQ=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);LQ=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);mja=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
nja=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);MQ=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);NQ=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);OQ=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
oja=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);pja=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);PQ=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.QQ=new $CLJS.M(null,"template-tags","template-tags",1853115685);qja=new $CLJS.M(null,"public-question","public-question",629369976);rja=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
sja=new $CLJS.M(null,"binary","binary",-1802232288);RQ=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);tja=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.SQ=new $CLJS.M(null,"source-query","source-query",198004422);TQ=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);uja=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
vja=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);UQ=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);VQ=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);wja=new $CLJS.M(null,"executed-by","executed-by",-739811161);xja=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
yja=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);WQ=new $CLJS.M(null,"amount","amount",364489504);XQ=new $CLJS.r(null,"percentile","percentile",1039342775,null);YQ=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);ZQ=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);$Q=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
aR=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);bR=new $CLJS.r(null,"trim","trim",-1880116002,null);zja=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);cR=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);dR=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);Aja=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
eR=new $CLJS.M("string","\x3d","string/\x3d",983744235);Bja=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);Cja=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Dja=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);fR=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);gR=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
hR=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Eja=new $CLJS.M(null,"lat-min","lat-min",1630784161);iR=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);jR=new $CLJS.r(null,"inside","inside",-681932758,null);Fja=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);kR=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Gja=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);lR=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);Hja=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);Ija=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.mR=new $CLJS.M(null,"card-id","card-id",-1770060179);nR=new $CLJS.M(null,"variadic","variadic",882626057);
oR=new $CLJS.r(null,"upper","upper",1886775433,null);pR=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);qR=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);rR=new $CLJS.r(null,"optional","optional",-600484260,null);Jja=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);sR=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
tR=new $CLJS.M(null,"sugar","sugar",-73788488);Kja=new $CLJS.M(null,"lat-max","lat-max",841568226);uR=new $CLJS.r(null,"power","power",702679448,null);vR=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);wR=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);xR=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
yR=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);zR=new $CLJS.r(null,"median","median",-2084869638,null);AR=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);BR=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Lja=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);CR=new $CLJS.M(null,"y","y",-1757859776);DR=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.ER=new $CLJS.M(null,"binning","binning",1709835866);FR=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);GR=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);HR=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);IR=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);JR=new $CLJS.r(null,"Reference","Reference",2024574086,null);KR=new $CLJS.M(null,"b","b",1482224470);
LR=new $CLJS.M(null,"a","a",-2123407586);MR=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);NR=new $CLJS.r(null,"replace","replace",853943757,null);OR=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Mja=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);PR=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
QR=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);RR=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);SR=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);TR=new $CLJS.r(null,"segment","segment",675610331,null);Nja=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);UR=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
VR=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);WR=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);YR=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Oja=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.ZR=new $CLJS.M(null,"order-by","order-by",1527318070);$R=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Pja=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Qja=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.aS=new $CLJS.M(null,"condition","condition",1668437652);bS=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Rja=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);cS=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Sja=new $CLJS.M(null,"card-name","card-name",-2035606807);dS=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Tja=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Uja=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);eS=new $CLJS.r(null,"log","log",45015523,null);Vja=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
fS=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.gS=new $CLJS.M(null,"database_type","database_type",-54700895);hS=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);iS=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.jS=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.kS=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.lS=new $CLJS.M("date","all-options","date/all-options",549325958);
Wja=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);mS=new $CLJS.M("location","state","location/state",-114378652);nS=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);Xja=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);Yja=new $CLJS.M(null,"lon-field","lon-field",517872067);oS=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);
pS=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);qS=new $CLJS.M(null,"numeric","numeric",-1495594714);rS=new $CLJS.r(null,"variable","variable",1359185035,null);sS=new $CLJS.r(null,"lower","lower",-1534114948,null);tS=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);uS=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.vS=new $CLJS.M(null,"limit","limit",-1355822363);
wS=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);xS=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);Zja=new $CLJS.M(null,"pulse","pulse",-244494476);var yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null),zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NE],null),AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AE],null),BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wE],null),CS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IE],null),DS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qL],null),$ja=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gL],null),aka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aL],null),ES=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.QI],null),FS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UI],null),GS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LI],null),HS=new $CLJS.ah(null,new $CLJS.h(null,12,[$CLJS.pi,null,$CLJS.ju,null,$CLJS.oj,null,$CLJS.di,null,$CLJS.Gi,null,$CLJS.xj,null,$CLJS.mk,null,$CLJS.Gj,null,$CLJS.ki,null,$CLJS.Fj,null,$CLJS.qi,null,$CLJS.Ai,null],null),null),IS=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.qk,null,$CLJS.gH,null,$CLJS.di,null,$CLJS.wi,null,$CLJS.jH,null,$CLJS.ij,null,$CLJS.Ui,null],null),null),
JS=$CLJS.ku.g(HS,IS),bka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.Ct,"date bucketing unit"],null)],null),HS),cka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.Ct,"time bucketing unit"],null)],null),IS),KS=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.Ct,"datetime bucketing unit"],null)],null),JS),LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uJ],null),dka=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,
1,[$CLJS.Ct,"temporal extract unit"],null),$CLJS.kH,$CLJS.Ai,$CLJS.mk,$CLJS.fJ,$CLJS.AJ,$CLJS.BJ,$CLJS.Gj,$CLJS.Gi,$CLJS.wi,$CLJS.ij,$CLJS.hH],null),eka=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.Ct,"datetime-diff unit"],null),$CLJS.gH,$CLJS.Ui,$CLJS.qk,$CLJS.ju,$CLJS.oj,$CLJS.xj,$CLJS.pi,$CLJS.ki],null),MS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.Ct,"temporal-extract week extraction mode"],null),$CLJS.$I,$CLJS.iJ,$CLJS.nJ],null),NS=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.Ct,"relative-datetime unit"],null),$CLJS.di,$CLJS.Ui,$CLJS.qk,$CLJS.ju,$CLJS.oj,$CLJS.xj,$CLJS.pi,$CLJS.ki],null),US,gka,bT,cT,dT,eT,fT,gT,hT,TU,ika,UU,jka,kka,VU,lka,mka,nka;$CLJS.X(cN,mM($CLJS.jJ,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.dw],null),$CLJS.Os],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xs,NS],null)])));var OS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cN],null);
$CLJS.X(HP,mM($CLJS.hJ,$CLJS.H(["n",$CLJS.Os,"unit",NS])));var fka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HP],null);
$CLJS.X(xN,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Is,new $CLJS.h(null,2,[$CLJS.Ct,"valid :absolute-datetime clause",$CLJS.Oi,function(a){if($CLJS.Va(lM($CLJS.dJ,a)))a=wN;else{a=$CLJS.hd(a);var b=$CLJS.hM(ES);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.LP:$CLJS.kJ}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[wN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,"not an :absolute-datetime clause"],null),$CLJS.Ue(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LP,mM($CLJS.dJ,
$CLJS.H(["date",ES,"unit",bka]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kJ,mM($CLJS.dJ,$CLJS.H(["datetime",FS,"unit",KS]))],null)],null));var PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xN],null);$CLJS.X(MN,mM($CLJS.Qy,$CLJS.H(["time",GS,"unit",cka])));var QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MN],null),RS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Ct,"date or datetime literal"],null),PS,FS,ES],null);
$CLJS.X(xM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Ct,"time literal"],null),QS,GS],null));$CLJS.X(oS,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Ct,"temporal literal"],null),RS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xM],null)],null));var SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oS],null);
$CLJS.X(Aja,nM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof PS?new $CLJS.Gc(function(){return PS},$CLJS.od(aia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,GQ,$CLJS.nj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),oQ,"metabase/mbql/schema.cljc",69,$CLJS.dJ,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:oQ}(),PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof OS?new $CLJS.Gc(function(){return OS},$CLJS.od(Wja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,
$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FP,"metabase/mbql/schema.cljc",29,$CLJS.jJ,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jJ,$CLJS.rk,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.dw],null),$CLJS.Os],null)),$CLJS.si,$CLJS.U($CLJS.Qj,$CLJS.U(rR,cP))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(OS)?OS.H:null])):null));return $CLJS.n(a)?a:FP}(),OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof QS?new $CLJS.Gc(function(){return QS},$CLJS.od(Yia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,GQ,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),fS,"metabase/mbql/schema.cljc",27,$CLJS.Qy,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Qy,$CLJS.Qy,$CLJS.U($CLJS.Qj,zja),$CLJS.si,$CLJS.U($CLJS.Qj,ria)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(QS)?QS.H:null])):null));return $CLJS.n(a)?a:fS}(),QS],null)])));
$CLJS.X(dS,mM($CLJS.Dj,$CLJS.H(["value",$CLJS.Ts,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gS,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,yS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qD,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,zS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NO,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Bs,AS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.si,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,KS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,yS],null)],null)],null)],null)])));var TS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dS],null);$CLJS.X(MO,mM($CLJS.zB,$CLJS.H(["expression-name",yS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xs,$CLJS.ik],null)])));
US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MO],null);
gka=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.PE);c=$CLJS.J.g(c,$CLJS.xE);return $CLJS.E.g(b,$CLJS.xE)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.PE);c=$CLJS.J.g(c,$CLJS.OE);return $CLJS.E.g(b,$CLJS.OE)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ct,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PE,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.Ct,"binning strategy"],null),$CLJS.xE,$CLJS.OE,$CLJS.di],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xE,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),BS],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.OE,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,$CLJS.Bl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,"bin width must be \x3e\x3d 0."],null),$CLJS.Te($CLJS.Wl)],null)],null)],null)],null)));$CLJS.VS=function VS(a){switch(arguments.length){case 1:return VS.h(arguments[0]);case 2:return VS.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.VS.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.CB);b=$CLJS.J.g(b,$CLJS.AG);return $CLJS.VS.g(a,b)};$CLJS.VS.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.DC(a,$CLJS.dD)?HS:$CLJS.DC(a,$CLJS.bE)?IS:$CLJS.DC(a,$CLJS.CD)?JS:null:null;return $CLJS.n(a)?$CLJS.Hd(a,b):!0};$CLJS.VS.A=2;
$CLJS.X(sR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ct,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CB,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,zS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ON,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,DS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,new $CLJS.h(null,
1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,KS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DP,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,yS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ER,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,gka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.VS],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Te($CLJS.PE)],null)],null));
$CLJS.X(UR,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.CB);return"string"===typeof c?b:!0}],null)],null)}(mM($CLJS.uG,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,DS,yS],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sR],null)],null)]))));var WS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UR],null);
$CLJS.X(UP,nM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof US?new $CLJS.Gc(function(){return US},$CLJS.od(YN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IN,"metabase/mbql/schema.cljc",60,$CLJS.zB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zB,$CLJS.kS,$CLJS.U($CLJS.Qj,eN),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(rR,$CLJS.ik))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(US)?US.H:null])):null));return $CLJS.n(a)?a:IN}(),US],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof WS?new $CLJS.Gc(function(){return WS},
$CLJS.od(pO,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Ar,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],["0.39.0",$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qN,"metabase/mbql/schema.cljc",51,$CLJS.uG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:qN}(),WS],null)])));
$CLJS.XS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UP],null);$CLJS.X(sM,mM($CLJS.jG,$CLJS.H(["aggregation-clause-index",$CLJS.Os,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xs,$CLJS.ik],null)])));var YS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sM],null);
$CLJS.X(ZM,nM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof YS?new $CLJS.Gc(function(){return YS},$CLJS.od(xja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DR,"metabase/mbql/schema.cljc",23,$CLJS.jG,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jG,Xja,$CLJS.U($CLJS.Qj,$CLJS.Os),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(rR,$CLJS.ik))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n(YS)?YS.H:null])):null));return $CLJS.n(a)?a:DR}(),YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof US?new $CLJS.Gc(function(){return US},
$CLJS.od(YN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IN,"metabase/mbql/schema.cljc",60,$CLJS.zB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zB,$CLJS.kS,$CLJS.U($CLJS.Qj,eN),
$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(rR,$CLJS.ik))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(US)?US.H:null])):null));return $CLJS.n(a)?a:IN}(),US],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof WS?new $CLJS.Gc(function(){return WS},$CLJS.od(pO,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Ar,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,
$CLJS.ui,$CLJS.kk,$CLJS.ok],["0.39.0",$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qN,"metabase/mbql/schema.cljc",51,$CLJS.uG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:qN}(),WS],null)])));
var ZS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZM],null),$S=new $CLJS.ah(null,new $CLJS.h(null,11,[$CLJS.Pt,null,$CLJS.MG,null,$CLJS.NF,null,$CLJS.SG,null,$CLJS.UF,null,$CLJS.xG,null,$CLJS.dG,null,$CLJS.Vt,null,$CLJS.KG,null,$CLJS.wG,null,$CLJS.TG,null],null),null),aT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WM],null);
$CLJS.X(zM,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Is,new $CLJS.h(null,1,[$CLJS.Oi,function(a){return"string"===typeof a?$CLJS.Zj:$CLJS.n(lM($S,a))?SM:$CLJS.n(lM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.Rt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,$CLJS.Zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[SM,aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rt,$CLJS.XS],null)],null));bT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zM],null);
cT=new $CLJS.ah(null,new $CLJS.h(null,26,[$CLJS.QF,null,$CLJS.zJ,null,$CLJS.hs,null,$CLJS.vJ,null,$CLJS.sJ,null,$CLJS.hG,null,$CLJS.vt,null,$CLJS.MF,null,$CLJS.wJ,null,$CLJS.pJ,null,$CLJS.xG,null,$CLJS.PG,null,$CLJS.tJ,null,$CLJS.zG,null,$CLJS.SF,null,$CLJS.bJ,null,$CLJS.gs,null,$CLJS.VF,null,$CLJS.dG,null,$CLJS.WI,null,$CLJS.$x,null,$CLJS.cJ,null,$CLJS.oJ,null,$CLJS.gJ,null,$CLJS.RG,null,$CLJS.sG,null],null),null);
dT=new $CLJS.ah(null,new $CLJS.h(null,9,[$CLJS.ds,null,$CLJS.Yr,null,$CLJS.Cs,null,$CLJS.Es,null,$CLJS.$r,null,$CLJS.LG,null,$CLJS.Js,null,$CLJS.es,null,$CLJS.bs,null],null),null);eT=new $CLJS.ah(null,new $CLJS.h(null,17,[$CLJS.Zn,null,$CLJS.JG,null,$CLJS.DG,null,$CLJS.vG,null,$CLJS.OG,null,$CLJS.QG,null,$CLJS.$F,null,$CLJS.fG,null,$CLJS.EM,null,$CLJS.aG,null,$CLJS.IG,null,$CLJS.BG,null,$CLJS.jk,null,$CLJS.by,null,$CLJS.oG,null,$CLJS.pG,null,$CLJS.HG,null],null),null);
fT=new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.lJ,null,$CLJS.YI,null,$CLJS.xJ,null,$CLJS.gs,null,$CLJS.rJ,null],null),null);gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kR],null);hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KN],null);$CLJS.iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MQ],null);$CLJS.jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qR],null);
$CLJS.X(xR,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Is,new $CLJS.h(null,2,[$CLJS.Ct,"numeric expression argument",$CLJS.Oi,function(a){return"number"===typeof a?$CLJS.du:$CLJS.n(lM(cT,a))?nP:$CLJS.n(lM(eT,a))?$CLJS.jG:$CLJS.n(lM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.uG}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.du,$CLJS.Bl],null),new $CLJS.P(null,2,5,$CLJS.Q,[nP,gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jG,$CLJS.jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uG,
$CLJS.XS],null)],null));var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xR],null);
$CLJS.X(HQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Is,new $CLJS.h(null,2,[$CLJS.Ct,"datetime expression argument",$CLJS.Oi,function(a){return $CLJS.n(lM(eT,a))?$CLJS.jG:$CLJS.n(lM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.n(lM(fT,a))?RN:$CLJS.Rt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jG,$CLJS.jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[RN,$CLJS.iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,RS,$CLJS.XS],null)],null)],null));
var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HQ],null);
$CLJS.X(kQ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Is,new $CLJS.h(null,2,[$CLJS.Ct,"expression argument",$CLJS.Oi,function(a){return"number"===typeof a?$CLJS.du:$CLJS.Cd(a)?$CLJS.Ss:$CLJS.n(lM(dT,a))?zQ:$CLJS.n(lM(cT,a))?nP:$CLJS.n(lM(fT,a))?RN:"string"===typeof a?$CLJS.Zj:$CLJS.n(lM($S,a))?SM:$CLJS.n(lM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.Rt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.du,$CLJS.Bl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,$CLJS.Ss],null),new $CLJS.P(null,2,5,$CLJS.Q,[zQ,hT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[nP,gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[RN,$CLJS.iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,$CLJS.Zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[SM,aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rt,$CLJS.XS],null)],null));var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kQ],null);$CLJS.X(hQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Ct,"numeric expression arg or interval"],null),fka,kT],null));
var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hQ],null);$CLJS.X(KM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Is,new $CLJS.h(null,2,[$CLJS.Ct,"int greater than zero or numeric expression",$CLJS.Oi,function(a){return"number"===typeof a?$CLJS.du:$CLJS.Rt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.du,BS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rt,gT],null)],null));var hka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KM],null);
$CLJS.X(SN,mM($CLJS.xG,$CLJS.H(["a",mT,"b",mT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,mT],null)])));var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SN],null);$CLJS.X(wM,mM($CLJS.NF,$CLJS.H(["s",bT,"start",hka,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xs,kT],null)])));var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wM],null);$CLJS.X(AQ,mM($CLJS.$x,$CLJS.H(["s",bT])));var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AQ],null);$CLJS.X(YM,mM($CLJS.KG,$CLJS.H(["s",bT])));
var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YM],null);$CLJS.X(bN,mM($CLJS.TG,$CLJS.H(["s",bT])));var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bN],null);$CLJS.X(uN,mM($CLJS.MG,$CLJS.H(["s",bT])));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uN],null);$CLJS.X(fO,mM($CLJS.Pt,$CLJS.H(["s",bT])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fO],null);$CLJS.X(PM,mM($CLJS.Vt,$CLJS.H(["s",bT])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PM],null);
$CLJS.X(wR,mM($CLJS.SG,$CLJS.H(["s",bT,"match",$CLJS.Zj,"replacement",$CLJS.Zj])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wR],null);$CLJS.X(tS,mM($CLJS.UF,$CLJS.H(["a",bT,"b",bT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,bT],null)])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tS],null);$CLJS.X(VO,mM($CLJS.wG,$CLJS.H(["s",bT,"pattern",$CLJS.Zj])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VO],null);
$CLJS.X(SR,mM($CLJS.gs,$CLJS.H(["x",nT,"y",nT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,nT],null)])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SR],null);$CLJS.X(QR,mM($CLJS.vt,$CLJS.H(["x",kT,"y",nT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,nT],null)])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QR],null);$CLJS.X(PR,mM($CLJS.PG,$CLJS.H(["x",kT,"y",kT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,kT],null)])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PR],null);
$CLJS.X(RR,mM($CLJS.hs,$CLJS.H(["x",kT,"y",kT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,kT],null)])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RR],null);$CLJS.X(gQ,mM($CLJS.sG,$CLJS.H(["x",kT])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gQ],null);$CLJS.X($Q,mM($CLJS.QF,$CLJS.H(["x",kT])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$Q],null);$CLJS.X(wS,mM($CLJS.zG,$CLJS.H(["x",kT])));var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wS],null);$CLJS.X(lP,mM($CLJS.VF,$CLJS.H(["x",kT])));
var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lP],null);$CLJS.X(rP,mM($CLJS.SF,$CLJS.H(["x",kT,"y",kT])));var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rP],null);$CLJS.X(OP,mM($CLJS.hG,$CLJS.H(["x",kT])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OP],null);$CLJS.X(LM,mM($CLJS.MF,$CLJS.H(["x",kT])));var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LM],null);$CLJS.X(nQ,mM($CLJS.RG,$CLJS.H(["x",kT])));var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nQ],null);
$CLJS.X(kN,mM($CLJS.gJ,$CLJS.H(["datetime-x",lT,"datetime-y",lT,"unit",eka])));var LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kN],null);$CLJS.X(CN,mM($CLJS.vJ,$CLJS.H(["datetime",lT,"unit",dka,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xs,MS],null)])));var MT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CN],null);$CLJS.X(tP,mM($CLJS.cJ,$CLJS.H(["date",lT])));var NT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tP],null);$CLJS.X(VP,mM($CLJS.zJ,$CLJS.H(["date",lT])));
var OT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VP],null);$CLJS.X(yM,mM($CLJS.WI,$CLJS.H(["date",lT])));var PT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yM],null);$CLJS.X(uS,mM($CLJS.pJ,$CLJS.H(["date",lT,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xs,MS],null)])));var QT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uS],null);$CLJS.X(GO,mM($CLJS.oJ,$CLJS.H(["date",lT])));var RT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GO],null);$CLJS.X(PP,mM($CLJS.tJ,$CLJS.H(["date",lT])));
var ST=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PP],null);$CLJS.X(gN,mM($CLJS.wJ,$CLJS.H(["datetime",lT])));var TT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gN],null);$CLJS.X(LO,mM($CLJS.sJ,$CLJS.H(["datetime",lT])));var UT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LO],null);$CLJS.X($M,mM($CLJS.bJ,$CLJS.H(["datetime",lT])));var VT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$M],null);$CLJS.X(qO,mM($CLJS.YI,$CLJS.H(["datetime",lT,"to",LS,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xs,LS],null)])));
var WT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qO],null),XT=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.Ct,"datetime arithmetic unit"],null),$CLJS.jH,$CLJS.gH,$CLJS.Ui,$CLJS.qk,$CLJS.ju,$CLJS.oj,$CLJS.xj,$CLJS.pi,$CLJS.ki],null);$CLJS.X(vM,mM($CLJS.rJ,$CLJS.H(["datetime",lT,"amount",kT,"unit",XT])));var YT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vM],null);
$CLJS.X(TM,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return mM(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.lJ));var ZT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TM],null);$CLJS.X(uM,mM($CLJS.xJ,$CLJS.H(["datetime",lT,"amount",kT,"unit",XT])));var $T=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uM],null);
$CLJS.X(MQ,nM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof zT?new $CLJS.Gc(function(){return zT},$CLJS.od(aQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),PO,"metabase/mbql/schema.cljc",51,$CLJS.gs,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gs,$CLJS.ME,$CLJS.U($CLJS.Qj,eQ),CR,$CLJS.U($CLJS.Qj,eQ),TN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,eQ))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:PO}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof YT?new $CLJS.Gc(function(){return YT},
$CLJS.od(Iia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[jO,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WP,"metabase/mbql/schema.cljc",67,$CLJS.rJ,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rJ,$CLJS.kJ,$CLJS.U($CLJS.Qj,pS),WQ,$CLJS.U($CLJS.Qj,
aR),$CLJS.si,$CLJS.U($CLJS.Qj,iR)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n(YT)?YT.H:null])):null));return $CLJS.n(a)?a:WP}(),YT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof $T?new $CLJS.Gc(function(){return $T},$CLJS.od(Cha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,
$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[jO,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nS,"metabase/mbql/schema.cljc",72,$CLJS.xJ,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xJ,$CLJS.kJ,$CLJS.U($CLJS.Qj,pS),WQ,$CLJS.U($CLJS.Qj,aR),$CLJS.si,$CLJS.U($CLJS.Qj,iR)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n($T)?$T.H:null])):null));
return $CLJS.n(a)?a:nS}(),$T],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof WT?new $CLJS.Gc(function(){return WT},$CLJS.od(Gja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YI,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QN,"metabase/mbql/schema.cljc",71,$CLJS.YI,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.YI,$CLJS.kJ,$CLJS.U($CLJS.Qj,pS),Aia,$CLJS.U($CLJS.Qj,FR),Gha,$CLJS.U($CLJS.Qj,$CLJS.U(rR,FR))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(WT)?WT.H:null])):null));return $CLJS.n(a)?a:QN}(),WT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&
"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof ZT?new $CLJS.Gc(function(){return ZT},$CLJS.od(ija,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uP,"metabase/mbql/schema.cljc",
45,$CLJS.lJ,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lJ],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n(ZT)?ZT.H:null])):null));return $CLJS.n(a)?a:uP}(),ZT],null)])));$CLJS.aU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RP],null);$CLJS.X(AM,mM($CLJS.Js,$CLJS.H(["first-clause",$CLJS.aU,"second-clause",$CLJS.aU,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,$CLJS.aU],null)])));var bU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AM],null);
$CLJS.X(mO,mM($CLJS.Cs,$CLJS.H(["first-clause",$CLJS.aU,"second-clause",$CLJS.aU,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,$CLJS.aU],null)])));var cU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mO],null);$CLJS.X(VM,mM($CLJS.Es,$CLJS.H(["clause",$CLJS.aU])));
var dU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VM],null),eU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Is,new $CLJS.h(null,3,[$CLJS.Ct,":field or :expression reference or :relative-datetime",$CLJS.Et,$CLJS.Ue(":field or :expression reference or :relative-datetime"),$CLJS.Oi,function(a){return $CLJS.n(lM($CLJS.jJ,a))?$CLJS.jJ:$CLJS.Rt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jJ,OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rt,$CLJS.XS],null)],null);
$CLJS.X(JQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,1,[$CLJS.Ct,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Cs,$CLJS.Ss,$CLJS.Bl,$CLJS.Zj,SS,eU,mT,TS],null)],null));var fU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JQ],null);
$CLJS.X($O,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Is,new $CLJS.h(null,2,[$CLJS.Ct,"order comparable",$CLJS.Oi,function(a){return $CLJS.n(lM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.Rt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rt,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Cs,$CLJS.Bl,$CLJS.Zj,SS,mT,eU],null)],null)],null));var gU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$O],null);
$CLJS.X(IR,mM($CLJS.es,$CLJS.H(["field",fU,"value-or-field",fU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,fU],null)])));var hU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IR],null);$CLJS.X(MR,mM($CLJS.LG,$CLJS.H(["field",fU,"value-or-field",fU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,fU],null)])));var iU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MR],null);$CLJS.X(HR,mM($CLJS.bs,$CLJS.H(["field",gU,"value-or-field",gU])));
var jU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HR],null);$CLJS.X(GR,mM($CLJS.Yr,$CLJS.H(["field",gU,"value-or-field",gU])));var kU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GR],null);$CLJS.X(vN,mM($CLJS.ds,$CLJS.H(["field",gU,"value-or-field",gU])));var lU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vN],null);$CLJS.X(BN,mM($CLJS.$r,$CLJS.H(["field",gU,"value-or-field",gU])));var mU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BN],null);$CLJS.X(rQ,mM($CLJS.XF,$CLJS.H(["field",gU,"min",gU,"max",gU])));
var nU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rQ],null);$CLJS.X(TQ,mM($CLJS.cG,$CLJS.H(["lat-field",gU,"lon-field",gU,"lat-max",gU,"lon-min",gU,"lat-min",gU,"lon-max",gU])));var oU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TQ],null);$CLJS.X(eO,mM($CLJS.WF,$CLJS.H(["field",$CLJS.XS])));var pU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eO],null);$CLJS.X(UO,mM($CLJS.gG,$CLJS.H(["field",$CLJS.XS])));var qU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UO],null);$CLJS.X(wO,mM($CLJS.YF,$CLJS.H(["field",$CLJS.XS])));
var rU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wO],null);$CLJS.X(DM,mM($CLJS.eG,$CLJS.H(["field",$CLJS.XS])));var sU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DM],null),tU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CG,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.Ss],null)],null);$CLJS.X(JO,mM($CLJS.lG,$CLJS.H(["field",bT,"string-or-field",bT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xs,tU],null)])));var uU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JO],null);
$CLJS.X(CP,mM($CLJS.FG,$CLJS.H(["field",bT,"string-or-field",bT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xs,tU],null)])));var vU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CP],null);$CLJS.X(uO,mM($CLJS.tG,$CLJS.H(["field",bT,"string-or-field",bT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xs,tU],null)])));var wU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uO],null);$CLJS.X(lQ,mM($CLJS.mG,$CLJS.H(["field",bT,"string-or-field",bT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xs,tU],null)])));
var xU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lQ],null);$CLJS.X(MM,mM($CLJS.GG,$CLJS.H(["field",$CLJS.XS,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.Os,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,$CLJS.dw,$CLJS.fy,$CLJS.bG],null)],null),"unit",NS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.Ss],null)],null)],null)])));var yU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MM],null);
$CLJS.X(CQ,mM($CLJS.TF,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZK],null),yS],null)])));var zU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CQ],null);
$CLJS.X(KN,nM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof bU?new $CLJS.Gc(function(){return bU},$CLJS.od(vR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dP,"metabase/mbql/schema.cljc",15,$CLJS.Js,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Js,UN,$CLJS.U($CLJS.Qj,iP),MP,$CLJS.U($CLJS.Qj,iP),dO,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,iP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(bU)?bU.H:null])):null));return $CLJS.n(a)?a:dP}(),bU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},
$CLJS.od(QP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TP,"metabase/mbql/schema.cljc",14,$CLJS.Cs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Cs,UN,$CLJS.U($CLJS.Qj,iP),MP,$CLJS.U($CLJS.Qj,iP),dO,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,iP))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n(cU)?cU.H:null])):null));return $CLJS.n(a)?a:TP}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.od(DQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vP,"metabase/mbql/schema.cljc",15,$CLJS.Es,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,$CLJS.jS,$CLJS.U($CLJS.Qj,iP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(dU)?dU.H:null])):null));return $CLJS.n(a)?a:vP}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==
typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.od(ZP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XO,"metabase/mbql/schema.cljc",13,$CLJS.bs,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.bs,$CLJS.uG,$CLJS.U($CLJS.Qj,WN),gR,$CLJS.U($CLJS.Qj,WN)],
null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(jU)?jU.H:null])):null));return $CLJS.n(a)?a:XO}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof lU?new $CLJS.Gc(function(){return lU},$CLJS.od(cS,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WO,"metabase/mbql/schema.cljc",14,$CLJS.ds,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ds,$CLJS.uG,$CLJS.U($CLJS.Qj,WN),gR,$CLJS.U($CLJS.Qj,WN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(lU)?lU.H:null])):null));return $CLJS.n(a)?a:WO}(),lU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&
"undefined"!==typeof rM&&"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},$CLJS.od(XP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YO,"metabase/mbql/schema.cljc",13,$CLJS.Yr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Yr,$CLJS.uG,$CLJS.U($CLJS.Qj,
WN),gR,$CLJS.U($CLJS.Qj,WN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:YO}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof mU?new $CLJS.Gc(function(){return mU},$CLJS.od(VR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],
[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eP,"metabase/mbql/schema.cljc",14,$CLJS.$r,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$r,$CLJS.uG,$CLJS.U($CLJS.Qj,WN),gR,$CLJS.U($CLJS.Qj,WN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(mU)?mU.H:null])):null));return $CLJS.n(a)?a:eP}(),mU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&
"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof hU?new $CLJS.Gc(function(){return hU},$CLJS.od(YP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZO,"metabase/mbql/schema.cljc",13,$CLJS.es,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.es,$CLJS.uG,$CLJS.U($CLJS.Qj,hS),gR,$CLJS.U($CLJS.Qj,hS),KQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,hS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(hU)?hU.H:null])):null));return $CLJS.n(a)?a:ZO}(),hU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.od(lN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,
$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HN,"metabase/mbql/schema.cljc",14,$CLJS.LG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.LG,$CLJS.uG,$CLJS.U($CLJS.Qj,hS),gR,$CLJS.U($CLJS.Qj,hS),KQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,hS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(iU)?iU.H:null])):null));
return $CLJS.n(a)?a:HN}(),iU],null)])));
$CLJS.X(RP,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Is,new $CLJS.h(null,2,[$CLJS.Ct,"valid filter expression",$CLJS.Oi,function(a){return $CLJS.n(lM(fT,a))?$CLJS.kJ:$CLJS.n(lM(cT,a))?qS:$CLJS.n(lM($S,a))?$CLJS.Zj:$CLJS.n(lM(dT,a))?$CLJS.Ss:$CLJS.Rt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kJ,$CLJS.iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[qS,gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rt,nM($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof bU?new $CLJS.Gc(function(){return bU},$CLJS.od(vR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dP,"metabase/mbql/schema.cljc",
15,$CLJS.Js,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Js,UN,$CLJS.U($CLJS.Qj,iP),MP,$CLJS.U($CLJS.Qj,iP),dO,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,iP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(bU)?bU.H:null])):null));return $CLJS.n(a)?a:dP}(),bU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},$CLJS.od(QP,new $CLJS.h(null,1,[$CLJS.bk,
!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TP,"metabase/mbql/schema.cljc",14,$CLJS.Cs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Cs,UN,$CLJS.U($CLJS.Qj,iP),MP,$CLJS.U($CLJS.Qj,iP),dO,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,iP))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n(cU)?
cU.H:null])):null));return $CLJS.n(a)?a:TP}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.od(DQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),vP,"metabase/mbql/schema.cljc",15,$CLJS.Es,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,$CLJS.jS,$CLJS.U($CLJS.Qj,iP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(dU)?dU.H:null])):null));return $CLJS.n(a)?a:vP}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof hU?new $CLJS.Gc(function(){return hU},$CLJS.od(YP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,
$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZO,"metabase/mbql/schema.cljc",13,$CLJS.es,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.es,$CLJS.uG,$CLJS.U($CLJS.Qj,hS),gR,$CLJS.U($CLJS.Qj,hS),KQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,hS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(hU)?hU.H:null])):
null));return $CLJS.n(a)?a:ZO}(),hU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.od(lN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),HN,"metabase/mbql/schema.cljc",14,$CLJS.LG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.LG,$CLJS.uG,$CLJS.U($CLJS.Qj,hS),gR,$CLJS.U($CLJS.Qj,hS),KQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,hS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(iU)?iU.H:null])):null));return $CLJS.n(a)?a:HN}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},
$CLJS.od(ZP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XO,"metabase/mbql/schema.cljc",13,$CLJS.bs,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.bs,$CLJS.uG,$CLJS.U($CLJS.Qj,WN),gR,$CLJS.U($CLJS.Qj,WN)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(jU)?jU.H:null])):null));return $CLJS.n(a)?a:XO}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},$CLJS.od(XP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YO,"metabase/mbql/schema.cljc",13,$CLJS.Yr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Yr,$CLJS.uG,$CLJS.U($CLJS.Qj,WN),gR,$CLJS.U($CLJS.Qj,WN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:YO}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof lU?new $CLJS.Gc(function(){return lU},$CLJS.od(cS,new $CLJS.h(null,
1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WO,"metabase/mbql/schema.cljc",14,$CLJS.ds,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ds,$CLJS.uG,$CLJS.U($CLJS.Qj,WN),gR,$CLJS.U($CLJS.Qj,WN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(lU)?lU.H:null])):
null));return $CLJS.n(a)?a:WO}(),lU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof mU?new $CLJS.Gc(function(){return mU},$CLJS.od(VR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),eP,"metabase/mbql/schema.cljc",14,$CLJS.$r,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$r,$CLJS.uG,$CLJS.U($CLJS.Qj,WN),gR,$CLJS.U($CLJS.Qj,WN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(mU)?mU.H:null])):null));return $CLJS.n(a)?a:eP}(),mU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof nU?new $CLJS.Gc(function(){return nU},$CLJS.od($ia,new $CLJS.h(null,
1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nN,"metabase/mbql/schema.cljc",19,$CLJS.XF,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.XF,$CLJS.uG,$CLJS.U($CLJS.Qj,WN),$CLJS.Zn,$CLJS.U($CLJS.Qj,WN),$CLJS.jk,$CLJS.U($CLJS.Qj,WN)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(nU)?nU.H:null])):null));return $CLJS.n(a)?a:nN}(),nU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof uU?new $CLJS.Gc(function(){return uU},$CLJS.od(bja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),lR,"metabase/mbql/schema.cljc",23,$CLJS.lG,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lG,$CLJS.uG,$CLJS.U($CLJS.Qj,fR),GN,$CLJS.U($CLJS.Qj,fR),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(rR,BQ))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(uU)?uU.H:null])):null));return $CLJS.n(a)?a:lR}(),uU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof vU?new $CLJS.Gc(function(){return vU},
$CLJS.od(dia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LN,"metabase/mbql/schema.cljc",21,$CLJS.FG,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.FG,$CLJS.uG,$CLJS.U($CLJS.Qj,fR),GN,$CLJS.U($CLJS.Qj,fR),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(rR,BQ))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n(vU)?vU.H:null])):null));return $CLJS.n(a)?a:LN}(),vU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof wU?new $CLJS.Gc(function(){return wU},$CLJS.od(pia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XN,"metabase/mbql/schema.cljc",20,$CLJS.tG,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.tG,$CLJS.uG,$CLJS.U($CLJS.Qj,fR),GN,$CLJS.U($CLJS.Qj,fR),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(rR,BQ))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n(wU)?wU.H:null])):null));return $CLJS.n(a)?a:XN}(),wU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==
typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof xU?new $CLJS.Gc(function(){return xU},$CLJS.od(Uja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BM,"metabase/mbql/schema.cljc",36,$CLJS.mG,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.mG,$CLJS.uG,$CLJS.U($CLJS.Qj,fR),GN,$CLJS.U($CLJS.Qj,fR),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(rR,BQ))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(xU)?xU.H:null])):null));return $CLJS.n(a)?a:BM}(),xU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof oU?new $CLJS.Gc(function(){return oU},$CLJS.od(Dja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),
$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jR,"metabase/mbql/schema.cljc",26,$CLJS.cG,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.cG,Mha,$CLJS.U($CLJS.Qj,WN),Yja,$CLJS.U($CLJS.Qj,WN),Kja,$CLJS.U($CLJS.Qj,WN),Pha,$CLJS.U($CLJS.Qj,WN),Eja,$CLJS.U($CLJS.Qj,WN),Lha,$CLJS.U($CLJS.Qj,
WN)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(oU)?oU.H:null])):null));return $CLJS.n(a)?a:jR}(),oU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof rU?new $CLJS.Gc(function(){return rU},$CLJS.od(qia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EP,"metabase/mbql/schema.cljc",28,$CLJS.YF,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YF,$CLJS.uG,$CLJS.U($CLJS.Qj,PN)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(rU)?rU.H:null])):null));return $CLJS.n(a)?a:EP}(),rU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&
"undefined"!==typeof sU?new $CLJS.Gc(function(){return sU},$CLJS.od(oja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sQ,"metabase/mbql/schema.cljc",29,$CLJS.eG,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,$CLJS.uG,$CLJS.U($CLJS.Qj,PN)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(sU)?sU.H:null])):null));return $CLJS.n(a)?a:sQ}(),sU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof pU?new $CLJS.Gc(function(){return pU},$CLJS.od(Pja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cO,"metabase/mbql/schema.cljc",27,$CLJS.WF,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WF,$CLJS.uG,$CLJS.U($CLJS.Qj,PN)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(pU)?pU.H:null])):null));return $CLJS.n(a)?a:cO}(),pU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&
"undefined"!==typeof qU?new $CLJS.Gc(function(){return qU},$CLJS.od(Cia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sN,"metabase/mbql/schema.cljc",28,$CLJS.gG,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,$CLJS.uG,$CLJS.U($CLJS.Qj,PN)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(qU)?qU.H:null])):null));return $CLJS.n(a)?a:sN}(),qU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof yU?new $CLJS.Gc(function(){return yU},$CLJS.od(Dha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RQ,"metabase/mbql/schema.cljc",33,$CLJS.GG,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.GG,$CLJS.uG,$CLJS.U($CLJS.Qj,PN),$CLJS.rk,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.Os,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,$CLJS.dw,$CLJS.fy,$CLJS.bG],null)],null)),$CLJS.si,$CLJS.U($CLJS.Qj,cP),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(rR,eja))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(yU)?yU.H:null])):null));return $CLJS.n(a)?a:RQ}(),yU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof zU?new $CLJS.Gc(function(){return zU},$CLJS.od(Kia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TR,"metabase/mbql/schema.cljc",27,$CLJS.TF,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TF,Tha,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,uja,eN],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n(zU)?zU.H:null])):null));return $CLJS.n(a)?a:TR}(),zU],null)]))],null)],null));
$CLJS.X(EQ,mM($CLJS.dG,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,1,[$CLJS.Ct,":case subclause"],null),$CLJS.aU,mT],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ct,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),mT],null)],null)],null)])));
var AU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EQ],null);
$CLJS.X(kR,nM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof zT?new $CLJS.Gc(function(){return zT},$CLJS.od(aQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),PO,"metabase/mbql/schema.cljc",51,$CLJS.gs,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gs,$CLJS.ME,$CLJS.U($CLJS.Qj,eQ),CR,$CLJS.U($CLJS.Qj,eQ),TN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,eQ))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:PO}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof AT?new $CLJS.Gc(function(){return AT},
$CLJS.od(Wia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QO,"metabase/mbql/schema.cljc",51,$CLJS.vt,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.vt,$CLJS.ME,$CLJS.U($CLJS.Qj,aR),
CR,$CLJS.U($CLJS.Qj,eQ),TN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,eQ))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:QO}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM?new $CLJS.Gc(function(){return BT},$CLJS.od(Via,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,sP,$CLJS.Ti,$CLJS.nj,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],
[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.oA,"metabase/mbql/schema.cljc",$CLJS.PG,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.PG,$CLJS.ME,$CLJS.U($CLJS.Qj,aR),CR,$CLJS.U($CLJS.Qj,aR),TN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,aR))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?
a:$CLJS.oA}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof CT?new $CLJS.Gc(function(){return CT},$CLJS.od(Xia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),OO,"metabase/mbql/schema.cljc",51,$CLJS.hs,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.hs,$CLJS.ME,$CLJS.U($CLJS.Qj,aR),CR,$CLJS.U($CLJS.Qj,aR),TN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,aR))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:OO}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof oT?new $CLJS.Gc(function(){return oT},
$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cR,"metabase/mbql/schema.cljc",58,$CLJS.xG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xG,LR,$CLJS.U($CLJS.Qj,GM),KR,$CLJS.U($CLJS.Qj,
GM),TN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,GM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:cR}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof qT?new $CLJS.Gc(function(){return qT},$CLJS.od(nja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,
$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vQ,"metabase/mbql/schema.cljc",56,$CLJS.$x,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$x,$CLJS.wy,$CLJS.U($CLJS.Qj,fR)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:vQ}(),qT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof DT?new $CLJS.Gc(function(){return DT},$CLJS.od(eia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),pP,"metabase/mbql/schema.cljc",55,$CLJS.sG,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sG,$CLJS.ME,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:pP}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof ET?new $CLJS.Gc(function(){return ET},$CLJS.od(Zha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),
$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dN,"metabase/mbql/schema.cljc",54,$CLJS.QF,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QF,$CLJS.ME,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(ET)?
ET.H:null])):null));return $CLJS.n(a)?a:dN}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof FT?new $CLJS.Gc(function(){return FT},$CLJS.od(mia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fP,"metabase/mbql/schema.cljc",55,$CLJS.zG,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zG,$CLJS.ME,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:fP}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&
"undefined"!==typeof GT?new $CLJS.Gc(function(){return GT},$CLJS.od(Vja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IP,"metabase/mbql/schema.cljc",53,$CLJS.VF,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.VF,$CLJS.ME,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:IP}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof HT?new $CLJS.Gc(function(){return HT},$CLJS.od(Ija,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,
oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$R,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uR,"metabase/mbql/schema.cljc",69,$CLJS.SF,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.SF,$CLJS.ME,$CLJS.U($CLJS.Qj,aR),CR,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:uR}(),HT],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof IT?new $CLJS.Gc(function(){return IT},$CLJS.od(Fia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$R,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KO,"metabase/mbql/schema.cljc",68,$CLJS.hG,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,$CLJS.ME,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:KO}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof JT?new $CLJS.Gc(function(){return JT},$CLJS.od(Jja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),
$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$R,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hP,"metabase/mbql/schema.cljc",67,$CLJS.MF,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MF,$CLJS.ME,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(JT)?
JT.H:null])):null));return $CLJS.n(a)?a:hP}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof KT?new $CLJS.Gc(function(){return KT},$CLJS.od(Sha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$R,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eS,"metabase/mbql/schema.cljc",67,$CLJS.RG,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RG,$CLJS.ME,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:eS}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&
"undefined"!==typeof AU?new $CLJS.Gc(function(){return AU},$CLJS.od(RM,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.PF,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zP,"metabase/mbql/schema.cljc",61,$CLJS.dG,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.dG,$CLJS.mx,$CLJS.U($CLJS.Qj,iO),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(rR,SP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(AU)?AU.H:null])):null));return $CLJS.n(a)?a:zP}(),AU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof LT?new $CLJS.Gc(function(){return LT},$CLJS.od(Vha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,
$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gJ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JP,"metabase/mbql/schema.cljc",65,$CLJS.gJ,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gJ,gia,$CLJS.U($CLJS.Qj,pS),hia,$CLJS.U($CLJS.Qj,pS),$CLJS.si,$CLJS.U($CLJS.Qj,Nia)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(LT)?LT.H:null])):null));return $CLJS.n(a)?a:JP}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof MT?new $CLJS.Gc(function(){return MT},$CLJS.od(rja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.vJ,"null"],null),null),$CLJS.od(ZQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bQ,"metabase/mbql/schema.cljc",71,$CLJS.vJ,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.vJ,$CLJS.kJ,$CLJS.U($CLJS.Qj,pS),$CLJS.si,$CLJS.U($CLJS.Qj,Oja),$CLJS.yi,$CLJS.U($CLJS.Qj,$CLJS.U(rR,xS))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(MT)?MT.H:null])):null));return $CLJS.n(a)?a:bQ}(),MT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},$CLJS.od(yia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.vJ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AN,"metabase/mbql/schema.cljc",71,$CLJS.cJ,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cJ,$CLJS.LP,$CLJS.U($CLJS.Qj,pS)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:AN}(),NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof OT?new $CLJS.Gc(function(){return OT},$CLJS.od(nia,new $CLJS.h(null,1,[$CLJS.bk,!0],
null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.vJ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$P,"metabase/mbql/schema.cljc",74,$CLJS.zJ,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zJ,$CLJS.LP,$CLJS.U($CLJS.Qj,pS)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(OT)?OT.H:null])):null));return $CLJS.n(a)?a:$P}(),OT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof PT?new $CLJS.Gc(function(){return PT},$CLJS.od(Uia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.vJ,"null"],null),null),
$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xQ,"metabase/mbql/schema.cljc",72,$CLJS.WI,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WI,$CLJS.LP,$CLJS.U($CLJS.Qj,pS)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(PT)?PT.H:null])):null));return $CLJS.n(a)?a:xQ}(),PT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==
typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof QT?new $CLJS.Gc(function(){return QT},$CLJS.od(Nja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.vJ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wQ,"metabase/mbql/schema.cljc",
71,$CLJS.pJ,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.pJ,$CLJS.LP,$CLJS.U($CLJS.Qj,pS),$CLJS.yi,$CLJS.U($CLJS.Qj,$CLJS.U(rR,xS))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(QT)?QT.H:null])):null));return $CLJS.n(a)?a:wQ}(),QT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof RT?new $CLJS.Gc(function(){return RT},$CLJS.od(Jha,new $CLJS.h(null,1,[$CLJS.bk,
!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.vJ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pQ,"metabase/mbql/schema.cljc",70,$CLJS.oJ,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oJ,$CLJS.LP,$CLJS.U($CLJS.Qj,pS)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(RT)?RT.H:null])):null));return $CLJS.n(a)?a:pQ}(),RT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof ST?new $CLJS.Gc(function(){return ST},$CLJS.od(Oia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.vJ,"null"],null),null),
$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fQ,"metabase/mbql/schema.cljc",78,$CLJS.tJ,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tJ,$CLJS.LP,$CLJS.U($CLJS.Qj,pS)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(ST)?ST.H:null])):null));return $CLJS.n(a)?a:fQ}(),ST],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==
typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof TT?new $CLJS.Gc(function(){return TT},$CLJS.od(Mia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.vJ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HM,"metabase/mbql/schema.cljc",
71,$CLJS.wJ,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wJ,$CLJS.kJ,$CLJS.U($CLJS.Qj,pS)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n(TT)?TT.H:null])):null));return $CLJS.n(a)?a:HM}(),TT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof UT?new $CLJS.Gc(function(){return UT},$CLJS.od(vja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,
$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.vJ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iS,"metabase/mbql/schema.cljc",73,$CLJS.sJ,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sJ,$CLJS.kJ,$CLJS.U($CLJS.Qj,pS)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(UT)?UT.H:null])):null));
return $CLJS.n(a)?a:iS}(),UT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof VT?new $CLJS.Gc(function(){return VT},$CLJS.od(Jia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.vJ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,
[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BP,"metabase/mbql/schema.cljc",73,$CLJS.bJ,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bJ,$CLJS.kJ,$CLJS.U($CLJS.Qj,pS)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(VT)?VT.H:null])):null));return $CLJS.n(a)?a:BP}(),VT],null)])));
$CLJS.X(WM,nM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof pT?new $CLJS.Gc(function(){return pT},$CLJS.od(lja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FQ,"metabase/mbql/schema.cljc",59,$CLJS.NF,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.NF,$CLJS.wy,$CLJS.U($CLJS.Qj,fR),$CLJS.Jv,$CLJS.U($CLJS.Qj,jia),$CLJS.$x,$CLJS.U($CLJS.Qj,$CLJS.U(rR,aR))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:FQ}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof rT?new $CLJS.Gc(function(){return rT},
$CLJS.od(Lja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bR,"metabase/mbql/schema.cljc",54,$CLJS.KG,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KG,$CLJS.wy,$CLJS.U($CLJS.Qj,fR)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:bR}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof tT?new $CLJS.Gc(function(){return tT},$CLJS.od(Pia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,
1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LQ,"metabase/mbql/schema.cljc",55,$CLJS.MG,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MG,$CLJS.wy,$CLJS.U($CLJS.Qj,fR)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:LQ}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&
"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof sT?new $CLJS.Gc(function(){return sT},$CLJS.od(mja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NP,"metabase/mbql/schema.cljc",
55,$CLJS.TG,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TG,$CLJS.wy,$CLJS.U($CLJS.Qj,fR)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:NP}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof wT?new $CLJS.Gc(function(){return wT},$CLJS.od(Eha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,
$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NR,"metabase/mbql/schema.cljc",57,$CLJS.SG,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.SG,$CLJS.wy,$CLJS.U($CLJS.Qj,fR),Qha,$CLJS.U($CLJS.Qj,$CLJS.Zj),$CLJS.vA,$CLJS.U($CLJS.Qj,$CLJS.Zj)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:NR}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},$CLJS.od(pja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sS,"metabase/mbql/schema.cljc",55,$CLJS.Vt,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vt,$CLJS.wy,$CLJS.U($CLJS.Qj,fR)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:sS}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==
typeof rM&&"undefined"!==typeof uT?new $CLJS.Gc(function(){return uT},$CLJS.od(Mja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oR,"metabase/mbql/schema.cljc",55,$CLJS.Pt,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pt,$CLJS.wy,$CLJS.U($CLJS.Qj,fR)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:oR}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof xT?new $CLJS.Gc(function(){return xT},$CLJS.od(dja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,
$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BO,"metabase/mbql/schema.cljc",56,$CLJS.UF,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.UF,LR,$CLJS.U($CLJS.Qj,fR),KR,$CLJS.U($CLJS.Qj,fR),TN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,fR))],null),$CLJS.Kc,"Schema for a valid concat clause.",
$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:BO}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof yT?new $CLJS.Gc(function(){return yT},$CLJS.od(kja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.jQ,"null",$CLJS.Kda,"null"],
null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yN,"metabase/mbql/schema.cljc",74,$CLJS.wG,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.wG,$CLJS.wy,$CLJS.U($CLJS.Qj,fR),$CLJS.uA,$CLJS.U($CLJS.Qj,$CLJS.Zj)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:yN}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==
typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof oT?new $CLJS.Gc(function(){return oT},$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jQ,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
cR,"metabase/mbql/schema.cljc",58,$CLJS.xG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xG,LR,$CLJS.U($CLJS.Qj,GM),KR,$CLJS.U($CLJS.Qj,GM),TN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,GM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:cR}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof AU?new $CLJS.Gc(function(){return AU},
$CLJS.od(RM,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.PF,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zP,"metabase/mbql/schema.cljc",61,$CLJS.dG,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.dG,$CLJS.mx,$CLJS.U($CLJS.Qj,iO),
$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(rR,SP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(AU)?AU.H:null])):null));return $CLJS.n(a)?a:zP}(),AU],null)])));
$CLJS.BU=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Is,new $CLJS.h(null,2,[$CLJS.Ct,":field or :expression reference or expression",$CLJS.Oi,function(a){return $CLJS.n(lM(cT,a))?qS:$CLJS.n(lM($S,a))?$CLJS.Zj:$CLJS.n(lM(dT,a))?$CLJS.Ss:$CLJS.n(lM(fT,a))?$CLJS.kJ:$CLJS.n(lM($CLJS.dG,a))?$CLJS.dG:$CLJS.Rt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[qS,gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kJ,$CLJS.iT],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dG,AU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rt,$CLJS.XS],null)],null);$CLJS.X(WR,mM($CLJS.by,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xs,$CLJS.XS],null)])));var CU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WR],null);$CLJS.X(KP,mM($CLJS.vG,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xs,$CLJS.XS],null)])));var DU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KP],null);$CLJS.X(UM,mM($CLJS.oG,$CLJS.H(["field-or-expression",$CLJS.BU])));
var EU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UM],null);$CLJS.X(OR,mM($CLJS.QG,$CLJS.H(["field-or-expression",$CLJS.BU])));var FU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OR],null);$CLJS.X(tO,mM($CLJS.$F,$CLJS.H(["field-or-expression",$CLJS.BU])));var GU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tO],null);$CLJS.X(dR,mM($CLJS.HG,$CLJS.H(["field-or-expression",$CLJS.BU])));var HU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dR],null);$CLJS.X(oO,mM($CLJS.Zn,$CLJS.H(["field-or-expression",$CLJS.BU])));
var IU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oO],null);$CLJS.X(aO,mM($CLJS.jk,$CLJS.H(["field-or-expression",$CLJS.BU])));var JU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aO],null);$CLJS.X(VN,mM($CLJS.OG,$CLJS.H(["field-or-expression",$CLJS.BU,"pred",$CLJS.aU])));var KU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VN],null);$CLJS.X(lO,mM($CLJS.DG,$CLJS.H(["pred",$CLJS.aU])));var LU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lO],null);$CLJS.X(VQ,mM($CLJS.BG,$CLJS.H(["pred",$CLJS.aU])));
var MU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VQ],null);$CLJS.X(rN,mM($CLJS.JG,$CLJS.H(["field-or-expression",$CLJS.BU])));var NU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rN],null);$CLJS.X(tM,mM($CLJS.aG,$CLJS.H(["field-or-expression",$CLJS.BU])));var OU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tM],null);$CLJS.X(xO,mM($CLJS.IG,$CLJS.H(["field-or-expression",$CLJS.BU])));var PU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xO],null);$CLJS.X(kP,mM($CLJS.fG,$CLJS.H(["field-or-expression",$CLJS.BU,"percentile",kT])));
var QU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kP],null);$CLJS.X(YQ,mM($CLJS.pG,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XK],null),yS],null)])));var RU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YQ],null);
$CLJS.X(UQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Is,new $CLJS.h(null,2,[$CLJS.Ct,"unnamed aggregation clause or numeric expression",$CLJS.Oi,function(a){return $CLJS.n(lM(cT,a))?nP:$CLJS.Rt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[nP,gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rt,nM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof EU?new $CLJS.Gc(function(){return EU},$CLJS.od(jja,
new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.PF,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wP,"metabase/mbql/schema.cljc",60,$CLJS.oG,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oG,pR,$CLJS.U($CLJS.Qj,hR)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(EU)?EU.H:null])):null));return $CLJS.n(a)?a:wP}(),EU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof FU?new $CLJS.Gc(function(){return FU},$CLJS.od(uia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.PF,"null"],null),null),$CLJS.od(ZQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BR,"metabase/mbql/schema.cljc",64,$CLJS.QG,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QG,pR,$CLJS.U($CLJS.Qj,hR)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(FU)?FU.H:null])):null));return $CLJS.n(a)?a:BR}(),FU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==
typeof rM&&"undefined"!==typeof GU?new $CLJS.Gc(function(){return GU},$CLJS.od(lia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.PF,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",65,$CLJS.$F,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$F,pR,$CLJS.U($CLJS.Qj,hR)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:AP}(),GU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof NU?new $CLJS.Gc(function(){return NU},$CLJS.od(Fja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,
$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.EG,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OM,"metabase/mbql/schema.cljc",76,$CLJS.JG,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JG,pR,$CLJS.U($CLJS.Qj,hR)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n(NU)?NU.H:null])):null));return $CLJS.n(a)?a:OM}(),NU],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof HU?new $CLJS.Gc(function(){return HU},$CLJS.od(Zia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.PF,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),jN,"metabase/mbql/schema.cljc",60,$CLJS.HG,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HG,pR,$CLJS.U($CLJS.Qj,hR)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:jN}(),HU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof IU?new $CLJS.Gc(function(){return IU},$CLJS.od(Nha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,
$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.PF,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.it,"metabase/mbql/schema.cljc",60,$CLJS.Zn,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zn,pR,$CLJS.U($CLJS.Qj,hR)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(IU)?IU.H:
null])):null));return $CLJS.n(a)?a:$CLJS.it}(),IU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof JU?new $CLJS.Gc(function(){return JU},$CLJS.od(Iha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.PF,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.mt,"metabase/mbql/schema.cljc",60,$CLJS.jk,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jk,pR,$CLJS.U($CLJS.Qj,hR)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(JU)?JU.H:null])):null));return $CLJS.n(a)?a:$CLJS.mt}(),JU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&
"undefined"!==typeof RU?new $CLJS.Gc(function(){return RU},$CLJS.od(iia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AO,"metabase/mbql/schema.cljc",18,$CLJS.pG,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,via,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.Cs,zia,eN],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(RU)?RU.H:null])):null));return $CLJS.n(a)?a:AO}(),RU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof MU?new $CLJS.Gc(function(){return MU},$CLJS.od(Hia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,
$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.PF,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sO,"metabase/mbql/schema.cljc",62,$CLJS.BG,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BG,$CLJS.ro,$CLJS.U($CLJS.Qj,iP)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(MU)?MU.H:null])):null));return $CLJS.n(a)?a:sO}(),MU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof LU?new $CLJS.Gc(function(){return LU},$CLJS.od(Oha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.PF,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),fN,"metabase/mbql/schema.cljc",68,$CLJS.DG,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DG,$CLJS.ro,$CLJS.U($CLJS.Qj,iP)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(LU)?LU.H:null])):null));return $CLJS.n(a)?a:fN}(),LU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof KU?new $CLJS.Gc(function(){return KU},$CLJS.od(Tja,new $CLJS.h(null,1,[$CLJS.bk,!0],
null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.PF,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xP,"metabase/mbql/schema.cljc",66,$CLJS.OG,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.OG,pR,$CLJS.U($CLJS.Qj,hR),$CLJS.ro,$CLJS.U($CLJS.Qj,iP)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(KU)?KU.H:null])):null));return $CLJS.n(a)?a:xP}(),KU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof AU?new $CLJS.Gc(function(){return AU},$CLJS.od(RM,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.PF,"null"],null),null),$CLJS.od(ZQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zP,"metabase/mbql/schema.cljc",61,$CLJS.dG,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.dG,$CLJS.mx,$CLJS.U($CLJS.Qj,iO),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(rR,SP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(AU)?AU.H:null])):null));return $CLJS.n(a)?a:zP}(),AU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&
"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof PU?new $CLJS.Gc(function(){return PU},$CLJS.od(yja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.qG,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zR,"metabase/mbql/schema.cljc",
68,$CLJS.IG,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IG,pR,$CLJS.U($CLJS.Qj,hR)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(PU)?PU.H:null])):null));return $CLJS.n(a)?a:zR}(),PU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof QU?new $CLJS.Gc(function(){return QU},$CLJS.od(Tia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,
sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.qG,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XQ,"metabase/mbql/schema.cljc",72,$CLJS.fG,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fG,pR,$CLJS.U($CLJS.Qj,hR),$CLJS.fG,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(QU)?QU.H:null])):
null));return $CLJS.n(a)?a:XQ}(),QU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof OU?new $CLJS.Gc(function(){return OU},$CLJS.od(Yha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JM,"metabase/mbql/schema.cljc",77,$CLJS.aG,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aG,pR,$CLJS.U($CLJS.Qj,hR)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(OU)?OU.H:null])):null));return $CLJS.n(a)?a:JM}(),OU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof DU?new $CLJS.Gc(function(){return DU},$CLJS.od(Kha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,
$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.PF,"null"],null),null),$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jP,"metabase/mbql/schema.cljc",74,$CLJS.vG,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,$CLJS.uG,$CLJS.U($CLJS.Qj,$CLJS.U(rR,PN))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n(DU)?DU.H:null])):null));return $CLJS.n(a)?a:jP}(),DU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof CU?new $CLJS.Gc(function(){return CU},$CLJS.od(kia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([qP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,tR,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.PF,"null"],null),null),
$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.xi,"metabase/mbql/schema.cljc",70,$CLJS.by,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.by,$CLJS.uG,$CLJS.U($CLJS.Qj,$CLJS.U(rR,PN))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(CU)?CU.H:null])):null));return $CLJS.n(a)?a:$CLJS.xi}(),CU],null)]))],null)],null));
$CLJS.X(mQ,mM($CLJS.EM,$CLJS.H(["aggregation",UQ,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ct,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),yS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),yS],null)],null)])));
$CLJS.X(qR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Is,new $CLJS.h(null,2,[$CLJS.Ct,"aggregation clause or numeric expression",$CLJS.Oi,function(a){return $CLJS.n(lM($CLJS.EM,a))?$CLJS.EM:hO}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[hO,UQ],null)],null));$CLJS.X(NM,mM($CLJS.rG,$CLJS.H(["field",ZS])));var SU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NM],null);$CLJS.X(mP,mM($CLJS.kG,$CLJS.H(["field",ZS])));
TU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mP],null);
ika=nM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof SU?new $CLJS.Gc(function(){return SU},$CLJS.od(Cja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
TO,"metabase/mbql/schema.cljc",15,$CLJS.rG,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,$CLJS.uG,$CLJS.U($CLJS.Qj,JR)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(SU)?SU.H:null])):null));return $CLJS.n(a)?a:TO}(),SU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof TU?new $CLJS.Gc(function(){return TU},$CLJS.od(tia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,
$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PQ,"metabase/mbql/schema.cljc",16,$CLJS.kG,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kG,$CLJS.uG,$CLJS.U($CLJS.Qj,JR)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n(TU)?TU.H:null])):null));return $CLJS.n(a)?a:PQ}(),TU],null)]));
UU=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zs,$CLJS.QM,$CLJS.FN,$CLJS.OF,$CLJS.du,$CLJS.GB,$CLJS.LP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,yS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zE,yS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),yS],null)],null);
jka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bt,UU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.QM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CM,yS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cQ,BS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dQ,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),BS],null)],null)],null);
kka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bt,UU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.FN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mR,BS],null)],null)],null);
VU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bt,UU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.Ts],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gO,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.Ss],null)],null)],null);lka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AR],null);
mka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bt,VU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.OF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OF,WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$N,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iN],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fj,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.Xi,$CLJS.Ts],null)],null)],null)],null);
$CLJS.WU=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.LP,null,$CLJS.du,null,$CLJS.Ss,null,$CLJS.GB,null],null),null);nka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.zs],null),$CLJS.WU);
$CLJS.X(IQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Is,new $CLJS.h(null,1,[$CLJS.Oi,$CLJS.mj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OF,mka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QM,jka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FN,kka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$o,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bt,VU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,nka],null)],null)],null)],null)],null));
var XU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QQ,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,yS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vO,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,yS],null)],null)],null),oka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bt,XU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RF,$CLJS.Ts],null)],null)],null),YU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qQ],null),ZU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Is,new $CLJS.h(null,1,[$CLJS.Oi,function(a){return $CLJS.n($CLJS.sE($CLJS.xd,
$CLJS.tQ)(a))?$CLJS.tQ:$CLJS.yE}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bt,XU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tQ,$CLJS.Ts],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yE,YU],null)],null),$U=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,yS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qD,zS],null),new $CLJS.P(null,2,5,$CLJS.Q,[aja,yS],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.NO,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,AS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bO,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.ik],null)],null)],null),aV=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,aka,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.Ct,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),pka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.zs],null),
new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.tF,null,$CLJS.cF,null,$CLJS.mF,null,$CLJS.qF,null],null),null)),bV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yR],null);
$CLJS.X(rO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oP,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),aV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SQ,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),ZU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aS,$CLJS.aU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PE,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),pka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dF,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Ct,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.Hy,$CLJS.pA],null),bV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),yS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jL,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,DS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aP,new $CLJS.h(null,
1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$U],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.sE($CLJS.KA($CLJS.oP,$CLJS.SQ),$CLJS.Te($CLJS.sE($CLJS.oP,$CLJS.SQ)))],null)],null));var qka=$CLJS.Q,cV;var dV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rO],null)],null);
if($CLJS.wd(dV)&&$CLJS.E.g($CLJS.A(dV),$CLJS.zj)){var eV=$CLJS.y(dV);$CLJS.A(eV);var fV=$CLJS.B(eV),rka=$CLJS.xd($CLJS.A(fV))?fV:$CLJS.ee(null,fV),gV=$CLJS.y(rka),ska=$CLJS.A(gV),tka=$CLJS.B(gV);cV=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.R.j(ska,$CLJS.Zn,1)],null),tka)}else cV=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,dV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.X(tN,new $CLJS.P(null,3,5,qka,[$CLJS.Js,cV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,"All join aliases must be unique."],null),function(a){return $CLJS.YL($CLJS.lf($CLJS.Wa,$CLJS.hf.g($CLJS.hF,a)))}],null)],null));var uka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tN],null);
$CLJS.X(yR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ql,new $CLJS.h(null,1,[$CLJS.Ct,"Distinct, non-empty sequence of Field clauses"],null),oM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),$CLJS.XS],null))],null));
$CLJS.X(qQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Js,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SQ,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),ZU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oP,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),aV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),$CLJS.jT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,new $CLJS.h(null,1,
[$CLJS.Xs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),$CLJS.XS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jQ,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,yS,$CLJS.BU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dF,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),bV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZF,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.aU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vS,new $CLJS.h(null,
1,[$CLJS.Xs,!0],null),CS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZR,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),oM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),ika],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[uQ,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[uQ,BS],null),new $CLJS.P(null,2,5,$CLJS.Q,[cia,BS],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NN,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),uka],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aP,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$U],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.em(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SQ,$CLJS.oP],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,
new $CLJS.h(null,1,[$CLJS.Ct,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.iG);b=$CLJS.J.g(b,$CLJS.dF);return $CLJS.td($CLJS.ru.g($CLJS.fh(a),$CLJS.fh(b)))}],null)],null));
$CLJS.hV=$CLJS.Ig([$CLJS.NG,$CLJS.LP,nO,ZN,bP,mS,IO,$CLJS.lS,$CLJS.du,HO,XM,eR,bS,$CLJS.mN,YR,kO,$CLJS.gP,FO,$CLJS.Zi,$CLJS.EO,FM,$CLJS.JN,$CLJS.DO,GP,$CLJS.Ss,$CLJS.GB,zO],[new $CLJS.h(null,1,[SO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.NG,null,$CLJS.LP,null,$CLJS.du,null,$CLJS.Ss,null,$CLJS.GB,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.LP,SO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.NG,null,$CLJS.LP,null,$CLJS.lS,null,$CLJS.gP,null,$CLJS.Zi,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.mj,$CLJS.Zj,$CLJS.HE,nR,SO,new $CLJS.ah(null,new $CLJS.h(null,1,[nO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,qS,$CLJS.HE,nR,SO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.NG,null,ZN,null,$CLJS.du,null,$CLJS.Zi,null,FM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,$CLJS.Zj,$CLJS.HE,aN,SO,new $CLJS.ah(null,new $CLJS.h(null,1,[bP,null],null),null)],null),new $CLJS.h(null,1,[SO,new $CLJS.ah(null,new $CLJS.h(null,1,[mS,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,
qS,$CLJS.HE,sja,SO,new $CLJS.ah(null,new $CLJS.h(null,1,[IO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.LP,SO,new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,qS,SO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.NG,null,ZN,null,$CLJS.du,null,$CLJS.Zi,null,FM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,qS,$CLJS.HE,aN,SO,new $CLJS.ah(null,new $CLJS.h(null,1,[HO,null],null),null)],null),new $CLJS.h(null,1,[SO,new $CLJS.ah(null,
new $CLJS.h(null,1,[XM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,$CLJS.Zj,$CLJS.HE,nR,SO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.NG,null,mS,null,XM,null,eR,null,FO,null,$CLJS.Zi,null,FM,null,$CLJS.GB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,$CLJS.Zj,$CLJS.HE,aN,SO,new $CLJS.ah(null,new $CLJS.h(null,1,[bS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.LP,SO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.lS,null,$CLJS.mN,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.mj,$CLJS.Zj,$CLJS.HE,aN,SO,new $CLJS.ah(null,new $CLJS.h(null,1,[YR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,$CLJS.Zj,$CLJS.HE,aN,SO,new $CLJS.ah(null,new $CLJS.h(null,1,[kO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.LP,SO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.NG,null,$CLJS.LP,null,$CLJS.lS,null,$CLJS.gP,null,$CLJS.Zi,null],null),null)],null),new $CLJS.h(null,1,[SO,new $CLJS.ah(null,new $CLJS.h(null,1,[FO,null],null),null)],null),new $CLJS.h(null,1,[SO,
new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.Zi,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.LP,SO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.lS,null,$CLJS.EO,null],null),null)],null),new $CLJS.h(null,1,[SO,new $CLJS.ah(null,new $CLJS.h(null,1,[FM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.LP,SO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.lS,null,$CLJS.JN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.LP,SO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.lS,
null,$CLJS.DO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,qS,$CLJS.HE,nR,SO,new $CLJS.ah(null,new $CLJS.h(null,1,[GP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.Ss,SO,new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.NG,null,$CLJS.Zi,null,$CLJS.Ss,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.Zj,SO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.NG,null,mS,null,XM,null,eR,null,FO,null,$CLJS.Zi,null,FM,null,$CLJS.GB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,
qS,$CLJS.HE,aN,SO,new $CLJS.ah(null,new $CLJS.h(null,1,[zO,null],null),null)],null)]);$CLJS.X(AR,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.Ct,"valid parameter type"],null)],null),$CLJS.Xg($CLJS.hV)));$CLJS.X(iN,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.Ct,"valid template tag widget type"],null),$CLJS.pA],null),$CLJS.Xg($CLJS.hV)));
$CLJS.X(NQ,mM(Uha,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,yS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,yS],null)],null)],null)])));var iV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NQ],null);$CLJS.X(IM,mM($CLJS.OF,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.XS,iV],null)])));var jV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IM],null);$CLJS.X(DN,mM(CO,$CLJS.H(["target",iV])));
var kV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DN],null),vka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.XS,nM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof jV?new $CLJS.Gc(function(){return jV},$CLJS.od(Ria,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yQ,"metabase/mbql/schema.cljc",21,$CLJS.OF,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OF,$CLJS.CJ,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,PN,RO],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(jV)?jV.H:null])):null));return $CLJS.n(a)?a:yQ}(),jV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=sP.h($CLJS.pd("undefined"!==typeof $CLJS.pM&&
"undefined"!==typeof qM&&"undefined"!==typeof rM&&"undefined"!==typeof kV?new $CLJS.Gc(function(){return kV},$CLJS.od(gja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,sP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(ZQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rS,"metabase/mbql/schema.cljc",20,CO,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[CO,
$CLJS.CJ,$CLJS.U($CLJS.Qj,RO)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n(kV)?kV.H:null])):null));return $CLJS.n(a)?a:rS}(),kV],null)]))],null),iM=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pN],null);
$CLJS.X(pN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Js,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.sE($CLJS.KA($CLJS.tQ,$CLJS.RF),$CLJS.Te($CLJS.sE($CLJS.tQ,$CLJS.RF)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.tQ);var d=$CLJS.J.g(c,$CLJS.RF);c=$CLJS.J.g(c,$CLJS.mj);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Ct,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nL],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.hL],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.RF,$CLJS.tQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tQ,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),oka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RF,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),YU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EN,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,lka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),yS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CJ,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),vka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.Ts],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),yS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$ha,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),
yS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.Ts],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lL,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[Rja,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),LS],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Qia,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[yP,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),CS],null),new $CLJS.P(null,3,5,$CLJS.Q,[hN,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),CS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,yP);b=$CLJS.J.g(b,hN);return $CLJS.Va($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Gia,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[Bja,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.Ss],null),new $CLJS.P(null,3,5,$CLJS.Q,[Wha,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.Ss],null),new $CLJS.P(null,3,5,$CLJS.Q,[Fha,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.Ss],null),new $CLJS.P(null,3,5,$CLJS.Q,[bia,new $CLJS.h(null,1,[$CLJS.Xs,
!0],null),$CLJS.Ss],null),new $CLJS.P(null,3,5,$CLJS.Q,[tja,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.Ss],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Hha,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.Ss],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Hja,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.Ss],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ry,new $CLJS.h(null,
1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zN,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.zs,fja,cja,$CLJS.vO,Sia,hja,Eia,Rha,Xha,fia,sia,qja,Zja,wia,oia],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wja,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,BS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Bia,
new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,BS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mR,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$ja],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Sja,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,yS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Dia,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,BS],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[Qja,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.Ts,$CLJS.Ts],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[xia,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,BS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yO,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.Ts,
$CLJS.Ts],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VK,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,$CLJS.Ts],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Te($CLJS.aP)],null)],null));var lV=$CLJS.hM(iM);
(function(){var a=Aha();return function(b){if($CLJS.n(lV.h?lV.h(b):lV.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.eM(b);throw $CLJS.ii($CLJS.uE("Invalid query: {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.Ny,c,Lia,b],null));}})();