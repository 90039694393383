var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var KL,ML,OL,RL,WL;$CLJS.HL=function(a){return $CLJS.Wh($CLJS.q($CLJS.rC),a,$CLJS.yG)};$CLJS.IL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.JL=new $CLJS.M(null,"object-id","object-id",-754527291);KL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.LL=new $CLJS.M(null,"operators","operators",-2064102509);
ML=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.NL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);OL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.PL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.QL=new $CLJS.M(null,"dimensions","dimensions",-254818097);
RL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.SL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.TL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.UL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.VL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
WL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.XL=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.X(ML,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bt,$CLJS.JE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.oH],null)],null)],null));$CLJS.X(WL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bt,ML,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CB,$CLJS.NE],null)],null)],null));$CLJS.X(OL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.uG],null),WL,$CLJS.EE],null));
$CLJS.X(RL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.uG],null),ML,$CLJS.qL],null));
$CLJS.UG.g($CLJS.uG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.uG],null),ML,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.qL,$CLJS.EE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Is,new $CLJS.h(null,2,[$CLJS.Oi,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.pC(a)):null},$CLJS.Ct,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.uB,RL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tB,OL],null)],null)],null));$CLJS.CF($CLJS.uG,$CLJS.yG);$CLJS.YE.m(null,$CLJS.uG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.KA($CLJS.Fi,$CLJS.CB)(b);return $CLJS.n(a)?a:$CLJS.kF});$CLJS.JF($CLJS.zB,$CLJS.H([$CLJS.EE]));$CLJS.YE.m(null,$CLJS.zB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.KA($CLJS.Fi,$CLJS.CB)(b);return $CLJS.n(a)?a:$CLJS.kF});
$CLJS.CF($CLJS.zB,$CLJS.yG);$CLJS.X(KL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bt,$CLJS.JE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.EE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,new $CLJS.h(null,1,[$CLJS.Xs,!0],null),$CLJS.EE],null)],null)],null));$CLJS.UG.g($CLJS.jG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.jG],null),KL,$CLJS.Zj],null));
$CLJS.YE.m(null,$CLJS.jG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.KA($CLJS.Fi,$CLJS.CB)(b);return $CLJS.n(a)?a:$CLJS.kF});$CLJS.CF($CLJS.jG,$CLJS.yG);$CLJS.JF($CLJS.TF,$CLJS.H([$CLJS.vt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZK],null)],null)]));$CLJS.CF($CLJS.TF,$CLJS.yG);
$CLJS.JF($CLJS.pG,$CLJS.H([$CLJS.vt,$CLJS.kF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XK],null)],null)]));$CLJS.CF($CLJS.pG,$CLJS.yG);
$CLJS.X($CLJS.yG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,$CLJS.$E,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Et,function(){return["Valid reference, must be one of these clauses: ",$CLJS.Nt(", ",$CLJS.fH($CLJS.Jd,$CLJS.kl.g($CLJS.q($CLJS.rC),$CLJS.yG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.HL(a)}],null)],null));